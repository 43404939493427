import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import type { Method } from 'axios';
import createError, { HttpError } from 'http-errors';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { useContext } from 'react';
import { AuthenticationContext } from '../../context/Authentication/AuthenticationContext';
import BaseApi from '../BaseApi';

/* eslint-disable import/prefer-default-export */
export const mapErrorToHttpError = (err: unknown): HttpError => {
  return createError(get(err, 'status', get(err, 'response.status', 500)));
};

interface WebAPIHttpResponse {
  data: unknown;
}

type MakeRequestOptions = Pick<AxiosRequestConfig, 'withCredentials'>;

const makeRequest = async <Payload, Response extends WebAPIHttpResponse>(
  method: Method,
  url: string,
  data?: Payload,
  opts?: MakeRequestOptions,
): Promise<Response['data']> => {
  try {
    const resp: AxiosResponse<Response> = await axios.request({
      url,
      method,
      data,
      withCredentials: get(opts, 'withCredentials', true),
      xsrfCookieName: 'XSRF-TOKEN',
      xsrfHeaderName: 'X-XSRF-TOKEN',
    });
    return get(resp, 'data.data');
  } catch (error) {
    throw createError(get(error, 'response.status', 500), get(error, 'message', 'No message provided'));
  }
};

export enum HttpMethod {
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
}

export const doGet = async <Response extends WebAPIHttpResponse>(
  url: string,
  opts: MakeRequestOptions = {
    withCredentials: true,
  },
): Promise<Response['data'] | undefined> => {
  const responseData = await makeRequest<undefined, Response>(HttpMethod.GET, url, undefined, opts);
  return isNull(responseData) ? undefined : responseData;
};

export const doPatch = async <Payload, Response extends WebAPIHttpResponse>(
  url: string,
  data?: Payload,
): Promise<Response['data']> => {
  return makeRequest<Payload, Response>(HttpMethod.PATCH, url, data);
};

export const doPost = async <Payload, Response extends WebAPIHttpResponse>(
  url: string,
  data?: Payload,
): Promise<Response['data']> => {
  return makeRequest<Payload, Response>(HttpMethod.POST, url, data);
};

export const apiMutation = (apiHook: () => BaseApi) => <Payload, ResponseData>(
  mutationKey: string | string[],
  doApiAction: (payload: Payload) => Promise<ResponseData>,
  mutationOpts: UseMutationOptions<ResponseData, Error, Payload, ResponseData> = {},
): UseMutationResult<ResponseData, Error, Payload, ResponseData> => {
  const { isAuthenticated } = useContext(AuthenticationContext);
  const api = apiHook();

  return useMutation(
    async (payload: Payload) => {
      if (!isAuthenticated()) {
        throw createError(403);
      }
      if (!api.isConfigurationValid()) {
        throw createError(400, 'Configuration invalid');
      }

      return doApiAction(payload);
    },
    {
      ...mutationOpts,
      mutationKey,
    },
  );
};
