import React from 'react';
import { firstNameFieldName, useGetUserInfo } from '../../services/UserApi';

const WelcomeMessage: () => JSX.Element = () => {
  const { isIdle: isGetUserInfoIdle, isLoading: isGetUserInfoLoading, data: getUserInfoData } = useGetUserInfo();
  const BASE_WELCOME_MESSAGE = 'Welcome to myE-Verify';

  const buildWelcomeMsg = (): string => {
    if (!isGetUserInfoIdle && !isGetUserInfoLoading && getUserInfoData?.[firstNameFieldName]) {
      return `${BASE_WELCOME_MESSAGE}, ${getUserInfoData?.[firstNameFieldName]}!`;
    }
    return `${BASE_WELCOME_MESSAGE}!`;
  };

  return <h1 className="margin-bottom-4">{buildWelcomeMsg()}</h1>;
};

export default WelcomeMessage;
