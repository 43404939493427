import React, { useContext } from 'react';
import { AuthenticationContext } from '../../../../context/Authentication/AuthenticationContext';
import { UserInfoResponseData } from '../../../../services/UserApi';
import FailedAttemptMarkup from './FailedAttemptMarkup';

export interface FailedAttemptProps {
  userInfo?: UserInfoResponseData;
  onSubmit: () => void;
}

const FailedAttempt = ({ userInfo = undefined, onSubmit }: FailedAttemptProps): JSX.Element => {
  const authentication = useContext(AuthenticationContext);
  return (
    <>
      <FailedAttemptMarkup onSubmit={onSubmit} onCancel={authentication.logout} userInfo={userInfo} />
    </>
  );
};

export default FailedAttempt;
