import React, { useState } from 'react';
import styled from 'styled-components';
import { SkipNav } from 'uswds-react';
import { Helmet } from 'react-helmet-async';
import { Button } from '@ver-uds/react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { useLoadingState } from '../../context/Loading/LoadingContext';
import PaperworkReductionActModal from '../../components/PaperworkReductionActModal/PaperworkReductionActModal';

const Wrapper = styled.div`
  position: relative;
`;

export type BasicLayoutProps = {
  title: string;
  children?: React.ReactNode;
};

const BasicLayout = ({ children = undefined, title }: BasicLayoutProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = (): void => {
    setIsModalOpen(true);
  };

  const handleModalClose = (): void => {
    setIsModalOpen(false);
  };
  const { isQueryLoading, isMutationLoading } = useLoadingState();

  return (
    <Wrapper>
      <Helmet>
        <title>myE-Verify | {title}</title>
      </Helmet>
      <PaperworkReductionActModal isOpen={isModalOpen} handleClose={handleModalClose} />
      {/* REACT_APP_BUILD_VERSION is set during the Jenkins build */}
      {/* Using dangerouslySetInnerHTML in order to add an HTML comment to outputted code. */}
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: `<!-- BUILD_VERSION: ${process.env.REACT_APP_BUILD_VERSION} -->` }} />
      {(isQueryLoading || isMutationLoading) && <LoadingSpinner />}
      <SkipNav>
        <SkipNav.Anchor />
        <div className="usa-overlay" />
        <div className="grid-row flex-column minh-viewport">
          <Header />
          <SkipNav.MainContent className="grid-col flex-fill">{children}</SkipNav.MainContent>
          <span className="grid-container padding-bottom-1">
            OMB Control No.: 1615-0117 • Expiration Date: 11/30/2026 •{' '}
            <Button
              style={{ fontWeight: 'normal', textDecoration: 'underline' }}
              variant="tertiary"
              onClick={handleButtonClick}
            >
              Paperwork Reduction Act
            </Button>
          </span>
        </div>
        <Footer />
      </SkipNav>
    </Wrapper>
  );
};

export default BasicLayout;
