import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';

export interface SelfCheckCaseDetailUnconfirmedContentProps {
  className?: ClassValue;
}

const baseClassName = 'case-detail-content-self-check--unconfirmed';
const CLASS_NAMES = {
  base: baseClassName,
};

const SelfCheckCaseDetailUnconfirmedContent = ({
  className = undefined,
}: SelfCheckCaseDetailUnconfirmedContentProps): JSX.Element => (
  <div className={(classNames(CLASS_NAMES.base, className), 'grid-col-9')}>
    <p>
      We apologize for the inconvenience, but something went wrong when processing this Self Check case. Please start a
      new Self Check case in order to continue.
    </p>
  </div>
);

export default SelfCheckCaseDetailUnconfirmedContent;
