import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { Alert } from 'uswds-react';
import { COLUMN_9_CLASS_NAME } from '../../../../../../../../../constants';
import TelephoneLink from '../../../../../../../../Actions/TelephoneLink';

export interface CaseUploadSelfLockAlertProps {
  className?: ClassValue;
}

const baseClassName = 'case-upload-self-lock-alert';
const CLASS_NAMES = {
  base: baseClassName,
  text: classNames(`${baseClassName}__text`, COLUMN_9_CLASS_NAME),
};

const CaseUploadSelfLockAlert = ({ className = undefined }: CaseUploadSelfLockAlertProps): JSX.Element => (
  <div className={classNames(CLASS_NAMES.base, className)} role="alert" aria-atomic="true">
    <Alert status="warning">
      <Alert.Heading>Please unlock your Social Security number (SSN)</Alert.Heading>
      <Alert.Text className={CLASS_NAMES.text}>
        <p>
          Self Lock Tentative Nonconfirmation (Mismatch) detected. To learn more about Self Lock and unlock your Social
          Security number, click on “Self Lock” in the navigation bar above.
        </p>
        <p>
          After you’ve unlocked your SSN, you are required to call The U.S. Department of Homeland Security (DHS) at{' '}
          <TelephoneLink phoneNumber="888-464-4218" /> or (TTY: <TelephoneLink phoneNumber="887-875-6028" />) to
          continue your case resolution.
        </p>
      </Alert.Text>
    </Alert>
  </div>
);

export default CaseUploadSelfLockAlert;
