// See https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill
// for instrutions for how to control the included polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
// import Cookies from 'js-cookie';
import { Modal } from '@ver-uds/react';
import App from './App';
// import type { Worker } from './mocks/browser';
import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
// const worker = (require('./mocks/browser') as { default: Worker }).default;

// If you want your app to mock server calls in the browser, you can
// uncomment the following lines.
// eslint-disable-next-line @typescript-eslint/no-floating-promises
// worker.start();
// Cookies.set('AUTH-SUCCESS', 'true');

Modal.setAppElement('#root');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
