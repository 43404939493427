import React from 'react';
import SelfLockLock from '../components/SelfLock/SelfLockLock/SelfLockLock';
import PageContentLayout from '../layouts/PageContentLayout/PageContentLayout';

const SelfLockLockPage = (): JSX.Element => (
  <PageContentLayout title="Lock My SSN">
    <SelfLockLock />
  </PageContentLayout>
);

export default SelfLockLockPage;
