import React from 'react';
import classNames from 'classnames';
import { Accordion } from 'uswds-react';
import ConfirmIdentityForm from './Form/ConfirmIdentityForm';
import { COLUMN_9_CLASS_NAME } from '../../constants';
import { SelfCheckSsnHelpSection } from '../HelpSections';
import { UserInfoUpdateRequest } from '../../services/UserApi';
import ConfirmIdentityAlert from './ConfirmIdentityAlert';
import type { ConfirmIdentityFormData } from './ConfirmIdentity';

export interface ConfirmIdentityMarkupProps {
  onSubmit: (data: UserInfoUpdateRequest) => void;
  error?: Error;
  prevEnteredData?: ConfirmIdentityFormData;
}

const baseClassName = 'confirm-information-markup';
export const CLASS_NAMES = {
  base: baseClassName,
  header: classNames(`${baseClassName}__header`, 'margin-0'),
  bodyText: classNames(`${baseClassName}__body-text`, COLUMN_9_CLASS_NAME),
  helpSection: classNames(`${baseClassName}__help-section`, 'margin-top-6'),
  errorAlert: classNames(`${baseClassName}__error-alert`, 'margin-top-2'),
  errorAlertText: `${baseClassName}__error-alert-text`,
};

const ConfirmIdentityMarkup = ({
  error = undefined,
  onSubmit,
  prevEnteredData = undefined,
}: ConfirmIdentityMarkupProps): JSX.Element => {
  let errorAlert;
  if (error) {
    errorAlert = <ConfirmIdentityAlert />;
  }

  return (
    <>
      <h1 className={CLASS_NAMES.header}>Welcome back to myE-Verify</h1>
      <p className={CLASS_NAMES.bodyText}>
        Since this is your first time logging into myE-Verify in a while, we need you to re-confirm some additional
        information about you to ensure that you have the best experience.
      </p>
      <div aria-atomic="true">{errorAlert}</div>
      <ConfirmIdentityForm onSubmit={onSubmit} prevEnteredData={prevEnteredData} />
      <Accordion className={CLASS_NAMES.helpSection} bordered>
        <SelfCheckSsnHelpSection />
      </Accordion>
    </>
  );
};

export default ConfirmIdentityMarkup;
