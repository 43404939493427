/* eslint-disable import/prefer-default-export */

const SSN_MASK_CHAR = '●';
const SSN_DELIM_CHAR = '-';

const buildStringWithChar = (len: number, char: string = SSN_MASK_CHAR): string => {
  return Array(len + 1).join(char);
};

export const maskSsn = (val?: string): string | undefined => {
  if (typeof val !== 'string' || val.length < 4) {
    return undefined;
  }
  return [buildStringWithChar(3), buildStringWithChar(2), val.slice(-4)].join(SSN_DELIM_CHAR);
};

export const formatSsn = (inputSsn: string): string => {
  if (!inputSsn) {
    return inputSsn;
  }
  const digitRegex = new RegExp(/\d/);
  // allow user to manually input one and only one '-'
  const ssnWithDashes = inputSsn.replace(/[^\d-]/g, '');

  if (ssnWithDashes.length === 4 && ssnWithDashes[3] === '-') {
    return ssnWithDashes;
  }
  if (ssnWithDashes.length === 5 && !digitRegex.test(ssnWithDashes[4])) {
    return ssnWithDashes.slice(0, -1);
  }
  if (ssnWithDashes.length === 7 && ssnWithDashes[6] === '-') {
    return ssnWithDashes;
  }
  if (ssnWithDashes.length === 8 && !digitRegex.test(ssnWithDashes[7])) {
    return ssnWithDashes.slice(0, -1);
  }
  // add dashes as necessary
  const ssnWithoutDashes = inputSsn.replace(/[^\d]/g, '');
  const ssnNumDigits = ssnWithoutDashes.length;
  if (ssnNumDigits < 4) {
    return ssnWithoutDashes;
  }
  if (ssnNumDigits < 6) {
    return `${ssnWithoutDashes.slice(0, 3)}-${ssnWithoutDashes.slice(3)}`;
  }
  return `${ssnWithoutDashes.slice(0, 3)}-${ssnWithoutDashes.slice(3, 5)}-${ssnWithoutDashes.slice(5, 9)}`;
};
export const formatPhoneNumber = (inputDob: string): string => {
  if (!inputDob) {
    return inputDob;
  }
  const digitRegex = new RegExp(/\d/);
  // allow user to manually input one and only one '-'
  const dobWithDashes = inputDob.replace(/[^\d-]/g, '');

  if (dobWithDashes.length === 4 && dobWithDashes[3] === '-') {
    return dobWithDashes;
  }
  if (dobWithDashes.length === 5 && !digitRegex.test(dobWithDashes[4])) {
    return dobWithDashes.slice(0, -1);
  }
  if (dobWithDashes.length === 8 && dobWithDashes[7] === '-') {
    return dobWithDashes;
  }
  if (dobWithDashes.length === 9 && !digitRegex.test(dobWithDashes[8])) {
    return dobWithDashes.slice(0, -1);
  }
  // add dashes as necessary
  const dobWithoutDashes = inputDob.replace(/[^\d]/g, '');
  const dobNumDigits = dobWithoutDashes.length;
  if (dobNumDigits < 4) {
    return dobWithoutDashes;
  }
  if (dobNumDigits < 7) {
    return `${dobWithoutDashes.slice(0, 3)}-${dobWithoutDashes.slice(3)}`;
  }
  return `${dobWithoutDashes.slice(0, 3)}-${dobWithoutDashes.slice(3, 6)}-${dobWithoutDashes.slice(6, 10)}`;
};
export const initialPhoneNumberFormat = (value?: number): string => {
  if (!value) {
    return '';
  }
  const phoneNumberNoDash = `${value}`;
  return `${phoneNumberNoDash.slice(0, 3)}-${phoneNumberNoDash.slice(3, 6)}-${phoneNumberNoDash.slice(6)}`;
};
