import React from 'react';
import { Accordion } from 'uswds-react';
import ImageWrapper from '../../ImageWrapper';
import HelpSectionContent from '../HelpSectionContent';
import foreignPassport from '../../../assets/img/documents/help/i551stamp/foreign-passport.jpg';
import i551Stamp from '../../../assets/img/documents/help/i551stamp/i-551-stamp.jpg';
import i551Visa from '../../../assets/img/documents/help/visa/visa.jpg';

const baseClassName = 'foreign-passport-i551-help-section';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

const ForeignPassportI551HelpSection = (): JSX.Element => (
  <>
    <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
      Help with Foreign Passport with I-551 Stamp or MRIV
    </Accordion.Heading>
    <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
      <p>
        USCIS uses either an I-551 stamp or a temporary I-551 printed notation on a machine-readable immigrant visa
        (MRIV) to indicate temporary evidence of lawful permanent residence. Sometimes, if no foreign passport is
        available, USCIS will place the I-551 stamp on a Form I-94 and affix a photograph of the bearer to the form.
        This document is considered a receipt.
      </p>
      <p>
        <span className="text-bold">Unexpired Foreign Passport with I-551 Stamp</span>
        <br />
        The temporary Form I-551 MRIV is evidence of permanent resident status for one year from the date of admission.
      </p>
      <ImageWrapper images={[{ src: foreignPassport, alt: 'Example a foreign passport' }]} />
      <ImageWrapper images={[{ src: i551Stamp, alt: 'Example of an I-551 stamp' }]} />
      <p>Temporary I-551 printed notation on a machine-readable immigrant visa (MRIV)</p>
      <ImageWrapper
        images={[
          {
            src: i551Visa,
            alt: 'Example of a machine-readable immigrant visa (MRIV) with temporary I-551 printed notation',
          },
        ]}
      />
    </HelpSectionContent>
  </>
);

export default ForeignPassportI551HelpSection;
