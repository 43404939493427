import React from 'react';
import { Link } from 'react-router-dom';
import PageContentLayout from '../layouts/PageContentLayout/PageContentLayout';
import paths from '../routing/paths';

const NotFoundPage = (): JSX.Element => (
  <PageContentLayout title="404 - Page Not Found" variant="error">
    <div className="grid-col-9">
      <h2>The requested page cannot be found</h2>
      <p>
        Please check the address, try again, or <Link to={paths.home}>go to the homepage</Link>.
      </p>
    </div>
  </PageContentLayout>
);

export default NotFoundPage;
