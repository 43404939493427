import React from 'react';
import { useCountriesQuery } from '../../../../../services/ReferenceApi';
import DocumentDetailsSectionMarkup, { DocumentDetailsSectionMarkupProps } from './DocumentDetailsSectionMarkup';

export type DocumentDetailsSectionProps = Omit<DocumentDetailsSectionMarkupProps, 'countries'>;

const DocumentDetailsSection = (props: DocumentDetailsSectionProps): JSX.Element => {
  const { data } = useCountriesQuery();

  return (
    <DocumentDetailsSectionMarkup
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      countries={data || []}
    />
  );
};

export default DocumentDetailsSection;
