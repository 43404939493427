import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import paths from '../../routing/paths';
import { useCountriesQuery } from '../../services/ReferenceApi';
import { SetValueFn } from '../../utils/forms';
import { DEFAULT_NAME } from './constants';
import CountrySelectMarkup, { CountrySelectMarkupProps } from './CountrySelectMarkup';

// defaultValue and setValue must be provided together
export type CountrySelectProps = Omit<CountrySelectMarkupProps, 'options'> & {
  setValue: SetValueFn<Partial<{ [name: string]: string }>>;
};

const CountrySelect = ({ defaultValue, setValue, name, ...props }: CountrySelectProps): JSX.Element => {
  const { error, data } = useCountriesQuery();

  const [hasSetDefaultValue, setHasSetDefaultValue] = useState<boolean>(false);
  useEffect(() => {
    if (!isEmpty(data) && !hasSetDefaultValue) {
      if (!isUndefined(defaultValue) && typeof setValue === 'function') {
        setValue(name || DEFAULT_NAME, defaultValue);
      }
      setHasSetDefaultValue(true);
    }
  }, [data, hasSetDefaultValue, defaultValue, name, setValue]);

  if (error) {
    return <Redirect to={paths.error} />;
  }

  return (
    <CountrySelectMarkup
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      options={data || []}
      defaultValue={defaultValue}
    />
  );
};

export default CountrySelect;
