import React, { useContext, useState } from 'react';
import { Button, GlobalHeader } from '@ver-uds/react';
import { Banner, BannerExtension } from 'uswds-react';
import { useLocation, Link } from 'react-router-dom';
import { AuthenticationContext } from '../../context/Authentication/AuthenticationContext';
import logo from '../../assets/img/logo/my-E-Verify_logo.svg';
import paths from '../../routing/paths';
import HeaderUserControls from './HeaderUserControls/HeaderUserControls';
import CreateAccountButton from '../Actions/CreateAccountButton/CreateAccountButton';
import HelpWithSignInModal from '../HelpWithSignInModal/HelpWithSignInModal';

enum IsVisibleOnAuth {
  AUTHENTICATED_ONLY,
  UNAUTHENTICATED_ONLY,
  BOTH,
}

const isLinkVisible = (isVisibleOnAuth: IsVisibleOnAuth, isAuthenticated: boolean): boolean => {
  return (
    isVisibleOnAuth === IsVisibleOnAuth.BOTH ||
    isVisibleOnAuth === (isAuthenticated ? IsVisibleOnAuth.AUTHENTICATED_ONLY : IsVisibleOnAuth.UNAUTHENTICATED_ONLY)
  );
};

const links: { label: string; href: string; isVisibleOnAuth: IsVisibleOnAuth }[] = [
  {
    label: 'Home',
    href: paths.home,
    isVisibleOnAuth: IsVisibleOnAuth.AUTHENTICATED_ONLY,
  },
  {
    label: 'Self Check',
    href: paths.selfCheck,
    isVisibleOnAuth: IsVisibleOnAuth.AUTHENTICATED_ONLY,
  },
  {
    label: 'Self Lock',
    href: paths.selfLock,
    isVisibleOnAuth: IsVisibleOnAuth.AUTHENTICATED_ONLY,
  },
];

const GovBanner = (): JSX.Element => <Banner extensionType={BannerExtension.GOV} />;

const NavHeader = (): JSX.Element => {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const location = useLocation();
  const { isAuthenticated } = useContext(AuthenticationContext);
  const visibleLinks = links.filter((link) => isLinkVisible(link.isVisibleOnAuth, isAuthenticated()));
  const hideNavLinks = location.pathname === paths.createAccount || location.pathname === paths.confirmIdentity;

  const handleHelpButtonClick = (): void => {
    setIsHelpModalOpen(true);
  };

  const handleHelpModalClose = (): void => {
    setIsHelpModalOpen(false);
  };

  return (
    <>
      {!hideNavLinks && (
        <GlobalHeader
          logo={
            <>
              <Link to="/">
                <img src={logo} alt="myE-Verify Logo" style={{ width: '150px', height: '30px' }} />
              </Link>
            </>
          }
          navItems={
            <GlobalHeader.NavItems>
              {visibleLinks.map((link) => (
                <Link
                  key={link.href}
                  to={link.href}
                  className={location.pathname === link.href ? GlobalHeader.activeClass : ''}
                >
                  {link.label}
                </Link>
              ))}
            </GlobalHeader.NavItems>
          }
          rightNavItems={
            <GlobalHeader.NavItems>
              {!isAuthenticated() && (
                <>
                  <HelpWithSignInModal isOpen={isHelpModalOpen} handleClose={handleHelpModalClose} />
                  <Button
                    style={{ fontWeight: 'lighter', textDecoration: 'underline', padding: '13px' }}
                    variant="tertiary"
                    onClick={handleHelpButtonClick}
                  >
                    Help with Sign In
                  </Button>
                </>
              )}

              {!isAuthenticated() && <CreateAccountButton />}
              <HeaderUserControls />
            </GlobalHeader.NavItems>
          }
        />
      )}
      {hideNavLinks && (
        <GlobalHeader
          logo={
            <>
              <Link to="/">
                <img src={logo} alt="myE-Verify Logo" style={{ width: '150px', height: '30px' }} />
              </Link>
            </>
          }
        />
      )}
    </>
  );
};

const Header = (): JSX.Element => (
  <>
    <GovBanner />
    <NavHeader />
  </>
);

export default Header;
