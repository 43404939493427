import { AlertProps } from 'uswds-react/dist/Alert/Alert';

class NotificationAlert {
  public status: AlertProps['status'];

  public message: string;

  private hasRendered = false;

  constructor(status: AlertProps['status'], message: string) {
    this.status = status;
    this.message = message;
  }

  markRendered(): void {
    this.hasRendered = true;
  }

  canRender(): boolean {
    return !this.hasRendered;
  }
}

export default NotificationAlert;
