import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import styled from 'styled-components';
import { FieldGroup } from '@ver-uds/react';
import FormFields, { FieldType, FormFieldsProps, ReadOnlyFieldData } from '../FormFields';

const StyledFieldGroup = styled(FieldGroup)`
  > .ver-field-group__field:last-child {
    margin-bottom: 1rem;
  }
`;

export type FieldProps = Omit<ReadOnlyFieldData, 'fieldType'>;

export interface ReadOnlyFieldsProps extends Omit<FormFieldsProps, 'data'> {
  className?: ClassValue;
  data?: FieldProps[];
}

const baseClassName = 'read-only-fields';
const CLASS_NAMES = {
  base: baseClassName,
  field: `${baseClassName}__field`,
};

const ReadOnlyFields = ({ className = undefined, data = undefined, sortOrder }: ReadOnlyFieldsProps): JSX.Element => {
  return (
    <StyledFieldGroup className={classNames(CLASS_NAMES.base, className)}>
      <FormFields
        data={data?.map((item) => ({
          ...item,
          fieldType: FieldType.READ_ONLY,
        }))}
        sortOrder={sortOrder}
      />
    </StyledFieldGroup>
  );
};
export default ReadOnlyFields;
