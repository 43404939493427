import React, { useContext } from 'react';
import { Footer as USWDSFooter } from 'uswds-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styled from 'styled-components';
import dhsLogoImage from './assets/dhs-seal-logo.svg';
import ssaLogoImage from './assets/US-SocialSecurityAdmin-Seal.svg';
import twitterIcon from './assets/social_icons/icon-twitter.svg';
import facebookIcon from './assets/social_icons/icon-facebook.svg';
import youtubeIcon from './assets/social_icons/icon-youtube.svg';
import myeverifyLogo from '../../assets/img/logo/my-E-Verify_logo.svg';
import paths from '../../routing/paths';
import { AuthenticationContext } from '../../context/Authentication/AuthenticationContext';
import { UserContext } from '../../context/User/UserContext';

const baseClassName = 'myeve-footer';
export const CLASS_NAMES = {
  base: baseClassName,
  container: classNames(`${baseClassName}__container`),
  footerHead: classNames(`${baseClassName}__footer-head`),
  secondaryFooter: classNames(`${baseClassName}__secondary-footer`, 'grid-container', 'grid-row', 'padding-top-205'),
  thirdFooter: classNames(`${baseClassName}__third-footer`),
  navLinks: classNames(`${baseClassName}__footer-nav-links`, 'padding-top-1'),
  returnToTop: classNames(
    `${baseClassName}__return-to-home`,
    'grid-container',
    'grid-row',
    'text-underline',
    'text-light',
    'padding-bottom-105',
    'padding-top-105',
  ),
  footerRedirectLinks: classNames(`${baseClassName}__footer-redirects`),
  dhsAndSsaSeals: classNames(`${baseClassName}__footer-dhs-ssa-seals`, 'display-inline-block', 'padding-right-205'),
  dhsAndSsaText: classNames(`${baseClassName}__footer-dhs-ssa-text`, 'text-white', 'display-inline-block'),
};

const TextAlignedDiv = styled.div`
   {
    text-align: left;
    padding-bottom: 20px;
    @media (min-width: 30rem) {
      text-align: right;
    }
    @media (max-width: 30rem) {
      padding-top: 10px;
    }
  }
`;
const NTASDiv = styled.div`
   {
    text-align: left;
    padding-bottom: 20px;
    @media (min-width: 30rem) {
      text-align: right;
    }
    @media (max-width: 30rem) {
      text-align: center;
    }
  }
`;

const NavLinksUL = styled.ul`
   {
    column-width: 10rem;
    @media (min-width: 65rem) {
      column-width: 15rem;
    }
    @media (min-width: 50rem) {
      column-width: 14rem;
    }
  }
`;

const SealTextDiv = styled.div`
   {
    position: absolute;
    @media (max-width: 50rem) {
      position: relative;
    }
  }
`;

const RedirectLinksDiv = styled.div`
   {
    @media (min-width: 30rem) {
      & ul {
        flex-wrap: nowrap;
      }
    }
  }
`;

const Footer: () => JSX.Element = () => {
  const { isAuthenticated } = useContext(AuthenticationContext);
  const { userInfoResponseData } = useContext(UserContext);

  return (
    <>
      <div className={CLASS_NAMES.container}>
        <div className="grid-col-auto">
          <USWDSFooter className="bg-white">
            <span className={CLASS_NAMES.returnToTop}>
              <a href="#root">Return to top</a>
            </span>
            <RedirectLinksDiv>
              <USWDSFooter.Primary size="medium">
                {isAuthenticated() && userInfoResponseData.hasCompletedIdProofing ? (
                  <USWDSFooter.Nav size="medium">
                    <USWDSFooter.PrimaryLink
                      href={paths.selfLock}
                      className={CLASS_NAMES.footerRedirectLinks}
                      size="medium"
                    >
                      <span className="text-black padding-right-9"> Self Lock</span>
                    </USWDSFooter.PrimaryLink>
                    <USWDSFooter.PrimaryLink
                      href={paths.home}
                      className={CLASS_NAMES.footerRedirectLinks}
                      size="medium"
                    >
                      <span className="text-black padding-right-9"> My Cases</span>
                    </USWDSFooter.PrimaryLink>
                    <USWDSFooter.PrimaryLink
                      href={paths.selfCheck}
                      className={CLASS_NAMES.footerRedirectLinks}
                      size="medium"
                    >
                      <span className="text-black padding-right-9"> Self Check</span>
                    </USWDSFooter.PrimaryLink>
                  </USWDSFooter.Nav>
                ) : (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </USWDSFooter.Primary>
            </RedirectLinksDiv>
            <div style={{ backgroundColor: '#DEDFE0' }}>
              <div className={CLASS_NAMES.secondaryFooter}>
                <div className="grid-col-8">
                  <Link to={paths.home}>
                    <img src={myeverifyLogo} alt="myE-Verify Logo" style={{ width: '400px', height: '100px' }} />
                  </Link>
                </div>
                <TextAlignedDiv className="tablet:grid-col">
                  <a
                    href="https://www.facebook.com/uscis"
                    className="padding-x-05"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={facebookIcon} alt="facebook icon" height="48px" width="48px" />
                  </a>
                  <a
                    href="https://twitter.com/EVerify"
                    className="padding-x-05"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={twitterIcon} alt="twitter icon" height="48px" width="48px" />
                  </a>
                  <a
                    href="https://www.youtube.com/user/uscis"
                    className="padding-left-05 padding-right-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={youtubeIcon} alt="youtube icon" height="48px" width="48px" />
                  </a>
                  <TextAlignedDiv>
                    <a href="mailto:myeverify@uscis.dhs.gov" target="_blank" rel="noopener noreferrer">
                      <span style={{ color: '#005288' }} className="font-ui-lg text-bold">
                        Contact myE-Verify
                      </span>
                    </a>
                  </TextAlignedDiv>
                </TextAlignedDiv>
              </div>
            </div>
          </USWDSFooter>
        </div>
        <div className={CLASS_NAMES.thirdFooter} style={{ backgroundColor: '#005288', minHeight: '280px' }}>
          <div className="grid-container">
            <div className="grid-row grid-gap">
              <div className="grid-col-9">
                <section className="padding-top-2">
                  <div className={CLASS_NAMES.dhsAndSsaSeals}>
                    <a
                      className="padding-right-1"
                      href="https://www.dhs.gov/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={dhsLogoImage} alt="Department of Homeland Security Seal" height="48px" width="48px" />
                    </a>
                    <a href="https://www.ssa.gov/" target="_blank" rel="noopener noreferrer">
                      <img
                        src={ssaLogoImage}
                        alt="U.S. Social Security Administration Seal"
                        height="48px"
                        width="48px"
                      />
                    </a>
                  </div>
                  <SealTextDiv className={CLASS_NAMES.dhsAndSsaText}>
                    <p className="font-sans-2xs text-white">
                      myE-Verify.gov
                      <br />
                      <b>
                        An official website of the{' '}
                        <a
                          className="display-inline-block"
                          href="https://www.dhs.gov/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="text-white text-underline">U.S. Department of Homeland Security</span>
                        </a>{' '}
                        and the{' '}
                        <a href="https://www.ssa.gov/" target="_blank" rel="noopener noreferrer">
                          <span className="text-white text-underline">Social Security Administration</span>
                        </a>
                      </b>
                    </p>
                  </SealTextDiv>
                </section>
                <div className={CLASS_NAMES.navLinks}>
                  <nav>
                    <div>
                      <div className="padding-top-2">
                        <NavLinksUL className="add-list-reset">
                          <li>
                            <a
                              className="text-light"
                              style={{ color: '#BFC2C4' }}
                              href="https://www.e-verify.gov/mye-verify"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              About myE-Verify
                            </a>
                          </li>
                          <li>
                            <a
                              style={{ color: '#BFC2C4' }}
                              href="https://www.uscis.gov/website-policies/accessibility"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Accessibility
                            </a>
                          </li>
                          <li>
                            <a
                              style={{ color: '#BFC2C4' }}
                              href="https://www.uscis.gov/website-policies/dhs-component-websites"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              DHS Components
                            </a>
                          </li>
                          <li>
                            <a
                              style={{ color: '#BFC2C4' }}
                              href="https://www.uscis.gov/records/request-records-through-the-freedom-of-information-act-or-privacy-act"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Freedom of Information Act
                            </a>
                          </li>
                          <li>
                            <a
                              style={{ color: '#BFC2C4' }}
                              href="https://www.uscis.gov/about-us/equal-employment-opportunity/equal-employment-opportunity-data-posted-pursuant-to-the-no-fear-act"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              No FEAR Act Data
                            </a>
                          </li>
                          <li>
                            <a
                              style={{ color: '#BFC2C4' }}
                              href="https://www.uscis.gov/website-policies/privacy-and-legal-disclaimers"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Privacy Policy
                            </a>
                          </li>
                          <li>
                            <a
                              style={{ color: '#BFC2C4' }}
                              href="https://www.oig.dhs.gov/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Office of the Inspector General
                            </a>
                          </li>
                          <li>
                            <a
                              style={{ color: '#BFC2C4' }}
                              href="https://www.whitehouse.gov/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              The White House
                            </a>
                          </li>
                          <li>
                            <a
                              style={{ color: '#BFC2C4' }}
                              href="https://www.usa.gov/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              USA.gov
                            </a>
                          </li>
                        </NavLinksUL>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <NTASDiv className="padding-top-2 tablet:grid-col-3">
                <span>
                  <iframe
                    src="https://www.dhs.gov/ntas/"
                    name="National Terrorism Advisory System"
                    title="National Terrorism Advisory System"
                    width="170"
                    height="180"
                    scrolling="no"
                    frameBorder={0}
                    seamless
                  />
                </span>
              </NTASDiv>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
