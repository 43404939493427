import get from 'lodash/get';
import { CaseStatus, SelfCheckCaseResponseData } from '../../../../services/SelfCheckApi';
import { CaseEligibilityStatus } from '../constants';
import { SelfCheckCaseDetailVariant } from './constants';

const getSelfCheckCaseDetailVariantForSelfCheckCaseStatus = (
  selfCheckCaseStatus?: CaseStatus,
): SelfCheckCaseDetailVariant | undefined => {
  switch (selfCheckCaseStatus) {
    case CaseStatus.REFERRED:
      return SelfCheckCaseDetailVariant.REFERRED;
    case CaseStatus.UNCONFIRMED_DATA:
      return SelfCheckCaseDetailVariant.UNCONFIRMED_DATA;
    case CaseStatus.PENDING_REFERRAL:
      return SelfCheckCaseDetailVariant.PENDING_REFERRAL;
    default:
      return undefined;
  }
};

const getSelfCheckCaseDetailVariantForCaseEligibilityStatus = (
  caseEligibilityStatus: CaseEligibilityStatus,
): SelfCheckCaseDetailVariant | undefined => {
  switch (caseEligibilityStatus) {
    case CaseEligibilityStatus.FNC:
    case CaseEligibilityStatus.NO_ACTION_FNC:
      return SelfCheckCaseDetailVariant.FNC;
    case CaseEligibilityStatus.EMPLOYMENT_AUTHORIZED:
      return SelfCheckCaseDetailVariant.EMPLOYMENT_AUTHORIZED;
    default:
      return undefined;
  }
};

/* eslint-disable import/prefer-default-export */
export const getSelfCheckCaseDetailVariant = (
  selfCheckCaseData?: SelfCheckCaseResponseData,
): SelfCheckCaseDetailVariant | undefined => {
  const caseStatus = get(selfCheckCaseData, 'caseStatus') as CaseStatus;
  const caseStatusVariant = getSelfCheckCaseDetailVariantForSelfCheckCaseStatus(caseStatus);
  if (typeof caseStatusVariant !== 'undefined') {
    return caseStatusVariant;
  }
  const caseEligibilityStatus = get(selfCheckCaseData, 'caseEligibilityStatement') as CaseEligibilityStatus;
  return getSelfCheckCaseDetailVariantForCaseEligibilityStatus(caseEligibilityStatus);
};
