import React from 'react';
import classNames from 'classnames';
import type { ClassValue } from 'classnames/types';
import styled from 'styled-components';
import { PageLoader } from '@ver-uds/react';

const Wrapper = styled.div`
  .ver-page-loader-overlay {
    background-color: white;
  }
`;

export interface LoadingSpinnerProps {
  className?: ClassValue;
}

const baseClassName = 'loading-spinner';
export const CLASS_NAMES = {
  base: baseClassName,
};

const LoadingSpinner: (props: LoadingSpinnerProps) => JSX.Element = ({
  className = undefined,
}: LoadingSpinnerProps) => (
  <Wrapper className={classNames(CLASS_NAMES.base, className)}>
    <PageLoader label="Loading" />
  </Wrapper>
);

export default LoadingSpinner;
