import classNames from 'classnames';
import type { ClassValue } from 'classnames/types';
import React from 'react';
import { Alert } from 'uswds-react';
import { COLUMN_9_CLASS_NAME } from '../../constants';
import { UploadDocumentType } from '../../services/CaseApi';
import DocumentUploadForm from './DocumentUploadForm';
import UploadDocumentInfoSection from './UploadDocumentInfoSection';

const baseClassName = 'document-upload';
export const CLASS_NAMES = {
  base: baseClassName,
  caseNumber: classNames(`${baseClassName}__main-header`, 'margin-y-105'),
  bodyText: classNames(`${baseClassName}__body-text`, COLUMN_9_CLASS_NAME, 'margin-top-105', 'margin-bottom-2'),
  documentInfo: classNames(`${baseClassName}__document-info`, 'margin-bottom-5'),
};

export interface DocumentUploadMarkupProps {
  className?: ClassValue;
  caseNumber: string;
  uploadDocumentType: UploadDocumentType;
}

const DocumentUploadMarkup = ({
  className = undefined,
  caseNumber,
  uploadDocumentType,
}: DocumentUploadMarkupProps): JSX.Element => {
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <h1>Select and Upload Documents</h1>
      <h4 className={CLASS_NAMES.caseNumber}>E-Verify Case Number {caseNumber}</h4>
      <p className={CLASS_NAMES.bodyText}>
        Use the file upload below to submit your document(s). The U.S. Department of Homeland Security (DHS) will review
        your submitted case documents and update E-Verify with an Employment Authorized to work status when the data
        mismatch issue has been resolved.
      </p>
      <UploadDocumentInfoSection className={CLASS_NAMES.documentInfo} uploadDocumentType={uploadDocumentType} />
      <Alert status="info" slim>
        <Alert.Heading>Document: File Size and Type</Alert.Heading>
        <Alert.Text>
          Maximum two (2) case files total per upload, 4MB per file. Acceptable file formats: PNG, JPEG, JPG or PDF.
        </Alert.Text>
      </Alert>
      <DocumentUploadForm caseNumber={caseNumber} uploadDocumentType={uploadDocumentType} />
    </div>
  );
};

export default DocumentUploadMarkup;
