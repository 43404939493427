import { Button, ButtonGroup, Modal } from '@ver-uds/react';
import noop from 'lodash/noop';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export interface NavigationPromptProps {
  enabled: boolean;
  header: string;
  content: string;
  proceedText: string;
}

const CLASS_NAMES = {
  base: 'navigation-prompt',
};

const NavigationPrompt = ({ enabled, header, content, proceedText }: NavigationPromptProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [destination, setDestination] = useState('');

  const history = useHistory();
  useEffect(() => {
    let unblock: () => void;

    if (enabled) {
      unblock = history.block((location) => {
        const { pathname } = (location as unknown) as Location;

        // The API is incorrectly typed. It passes a Location object at runtime.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setDestination(pathname);
        setIsOpen(true);
        // History API expects a string to be returned
        return '';
      });
    } else {
      unblock = history.block(noop);
    }
    // Cleanup function
    return (): void => {
      unblock();
    };
  }, [enabled, history]);

  const cancelAction = (): void => {
    setIsOpen(false);
  };

  const proceedAction = (): void => {
    history.block(noop);
    history.push(destination);
  };

  return (
    <Modal id={CLASS_NAMES.base} header={header} open={isOpen} close={cancelAction}>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Footer>
        <ButtonGroup>
          <Button type="button" variant="secondary" onClick={cancelAction}>
            Cancel
          </Button>
          <Button type="button" variant="destructive" onClick={proceedAction}>
            {proceedText}
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default NavigationPrompt;
