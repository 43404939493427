import React from 'react';
import classNames from 'classnames';
import { Form } from 'uswds-react';
import ActionFooter from '../../../../ActionFooter/ActionFooter';
import { SSNFormData } from '../../constants';
import { DocumentMarkupComponentProps } from '../DocumentDetailsFormTypes';
import ReadOnlyFields from '../../../../Form/FormFields/ReadOnlyFields/ReadOnlyFields';
import { maskSsn } from '../../../../../utils/formatting';

export const SSN_LABEL = 'Social Security number';

export interface SSNMarkupComponentProps extends DocumentMarkupComponentProps<SSNFormData> {
  ssn?: string;
}

const baseClassName = 'self-check-document-details';
const CLASS_NAMES = {
  base: baseClassName,
  form: `${baseClassName}__form`,
  readOnlyFields: classNames(`${baseClassName}__read-only-fields`, 'margin-top-2'),
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
};

const SSNDocumentMarkup = ({ onSubmit, onCancel, ssn = '' }: SSNMarkupComponentProps): JSX.Element => {
  const submitHandler = (): void => {
    onSubmit({});
  };

  return (
    <>
      <div className={classNames(CLASS_NAMES.base, classNames)}>
        <Form className={CLASS_NAMES.form} onSubmit={submitHandler} variant="large">
          <ReadOnlyFields
            className={CLASS_NAMES.readOnlyFields}
            data={[
              {
                name: SSN_LABEL,
                value: maskSsn(ssn),
              },
            ]}
          />
        </Form>
        <ActionFooter
          className={CLASS_NAMES.actionFooter}
          onCancel={onCancel}
          cancelButtonText="Back"
          onSubmit={submitHandler}
          submitButtonText="Next"
        />
      </div>
    </>
  );
};

export default SSNDocumentMarkup;
