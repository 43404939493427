import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { StartNewCaseActionFooter } from '../shared';

export interface SelfCheckCaseDetailUnconfirmedActionSectionProps {
  className?: ClassValue;
  caseNumber: string;
}

const baseClassName = 'case-detail-self-check-action-section--unconfirmed';
const CLASS_NAMES = {
  base: baseClassName,
};

const SelfCheckCaseDetailUnconfirmedActionSection = ({
  className = undefined,
  caseNumber,
}: SelfCheckCaseDetailUnconfirmedActionSectionProps): JSX.Element => (
  <StartNewCaseActionFooter
    className={classNames(CLASS_NAMES.base, className)}
    caseNumber={caseNumber}
    shouldCloseCaseOnSubmit
  />
);

export default SelfCheckCaseDetailUnconfirmedActionSection;
