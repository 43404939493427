import React from 'react';
import { DeepMap, FieldElement, FieldError, Ref, RegisterOptions } from 'react-hook-form';
import classNames from 'classnames';
import type { ClassValue } from 'classnames/types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { ErrorMessage, FormGroup, Input, Label, Select } from 'uswds-react';
import { SelfLockLockFormData } from './selfLockLockFormUtil';

export const QUESTION_REQUIRED_ERR_MSG = 'Please select a question.';
export const ANSWER_REQUIRED_ERR_MSG = 'Please enter an answer.';
export const ANSWER_TOO_LONG_ERR_MSG = 'Please enter an answer of at most 256 characters.';

export interface SelfLockLockFormSectionProps {
  className?: ClassValue;
  index: number;
  register: (rules?: RegisterOptions) => (ref: (FieldElement<SelfLockLockFormData> & Ref) | null) => void;
  errors: DeepMap<SelfLockLockFormData, FieldError>;
  availableQuestionOptions: string[];
}

const baseClassName = 'self-lock-lock-form-section';
const CLASS_NAMES = {
  base: baseClassName,
  formGroup: `${baseClassName}__form-group`,
  formGroupLabel: `${baseClassName}__form-group-label`,
  formGroupLabelRequiredText: classNames(
    `${baseClassName}__form-group-labe-required-text`,
    'margin-left-05',
    'text-base text-normal',
  ),
};

const SelfLockLockFormSection = ({
  className = undefined,
  index,
  register,
  errors,
  availableQuestionOptions,
}: SelfLockLockFormSectionProps): JSX.Element => {
  const sectionErrors = get(errors, index);
  const questionErrors = get(sectionErrors, 'question');
  const answerErrors = get(sectionErrors, 'answer');

  let questionErrorMsg;
  if (questionErrors) {
    if (questionErrors.type === 'required') {
      questionErrorMsg = QUESTION_REQUIRED_ERR_MSG;
    }
  }

  let answerErrorMsg;
  if (answerErrors) {
    if (answerErrors.type === 'required') {
      answerErrorMsg = ANSWER_REQUIRED_ERR_MSG;
    }
    if (answerErrors.type === 'maxLength') {
      answerErrorMsg = ANSWER_TOO_LONG_ERR_MSG;
    }
  }

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <h3>Challenge {index + 1}</h3>
      <FormGroup className={CLASS_NAMES.formGroup} error={!isEmpty(questionErrors)}>
        <Label htmlFor={`question__${index}`} className={CLASS_NAMES.formGroupLabel} error={!isEmpty(questionErrors)}>
          <span>Question</span>
          <span className={CLASS_NAMES.formGroupLabelRequiredText} aria-hidden="true">
            (required)
          </span>
        </Label>
        {questionErrorMsg && <ErrorMessage id={`question__${index}-error-message`}>{questionErrorMsg}</ErrorMessage>}
        <Select
          id={`question__${index}`}
          name={`[${index}][question]`}
          aria-required="true"
          aria-label={`Question ${index + 1}`}
          aria-describedby={questionErrorMsg && `question__${index}-error-message`}
          ref={register({
            required: true,
          })}
          errorId={questionErrors && `question__${index}-error`}
        >
          <option value="">- Select -</option>
          {availableQuestionOptions.map((question) => (
            <option value={question} key={question}>
              {question}
            </option>
          ))}
        </Select>
      </FormGroup>
      <FormGroup className={CLASS_NAMES.formGroup} error={!isEmpty(answerErrors)}>
        <Label htmlFor={`answer__${index}`} className={CLASS_NAMES.formGroupLabel} error={!isEmpty(answerErrors)}>
          <span>Answer</span>
          <span className={CLASS_NAMES.formGroupLabelRequiredText} aria-hidden="true">
            (required)
          </span>
        </Label>
        {answerErrorMsg && <ErrorMessage id={`answer__${index}-error-message`}>{answerErrorMsg}</ErrorMessage>}
        <Input
          id={`answer__${index}`}
          name={`[${index}][answer]`}
          aria-required="true"
          aria-label={`Answer ${index + 1}`}
          aria-describedby={answerErrorMsg && `answer__${index}-error-message`}
          ref={register({
            required: true,
            maxLength: 256,
          })}
          errorId={answerErrors && `answer__${index}-error`}
        />
      </FormGroup>
    </div>
  );
};

export default SelfLockLockFormSection;
