import { UploadDocumentType } from '../../../../services/CaseApi';
import { UploadSuccessModalVariant } from './constants';

/* eslint-disable import/prefer-default-export */
export const getUploadSuccessModalVariant = (
  uploadDocumentType: UploadDocumentType,
  isSelfCheckCase?: boolean,
): UploadSuccessModalVariant => {
  if (isSelfCheckCase) {
    return UploadSuccessModalVariant.SELF_CHECK;
  }
  if (uploadDocumentType === UploadDocumentType.NATZ) {
    return UploadSuccessModalVariant.NATZ;
  }
  return UploadSuccessModalVariant.GENERIC;
};
