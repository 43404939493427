import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { MutationCache, Query, QueryCache, useQueryClient } from 'react-query';
import { Mutation } from 'react-query/types/core/mutation';
import get from 'lodash/get';

export interface LoadingStatus {
  isQueryLoading: boolean;
  isMutationLoading: boolean;
}

const INITIAL_LOADING_STATE: LoadingStatus = {
  isQueryLoading: false,
  isMutationLoading: false,
};

const LoadingContext = createContext<LoadingStatus>(INITIAL_LOADING_STATE);

export const useLoadingState = (): LoadingStatus => {
  return useContext(LoadingContext);
};

export const useQueryCache = (): QueryCache => {
  const queryClient = useQueryClient();
  return useMemo(() => {
    return queryClient.getQueryCache();
  }, [queryClient]);
};

export const useMutationCache = (): MutationCache => {
  const queryClient = useQueryClient();
  return useMemo(() => {
    return queryClient.getMutationCache();
  }, [queryClient]);
};

const isCacheLoading = (cache: QueryCache | MutationCache): boolean =>
  cache.getAll().some((query: Query | Mutation) => get(query, 'state.status') === 'loading');

const useQueryCacheLoading = (): boolean => {
  const [queryLoadingState, setQueryLoadingState] = useState<boolean>(false);
  const queryCache = useQueryCache();
  useEffect(() => {
    setQueryLoadingState(isCacheLoading(queryCache));
    return queryCache.subscribe(() => {
      if (queryLoadingState !== isCacheLoading(queryCache)) {
        setQueryLoadingState(isCacheLoading(queryCache));
      }
    });
  }, [queryCache, queryLoadingState]);
  return queryLoadingState;
};

const useMutationCacheLoading = (): boolean => {
  const [mutationLoadingState, setMutationLoadingState] = useState<boolean>(false);
  const mutationCache = useMutationCache();
  useEffect(() => {
    setMutationLoadingState(isCacheLoading(mutationCache));
    return mutationCache.subscribe(() => {
      setMutationLoadingState(isCacheLoading(mutationCache));
    });
  }, [mutationCache]);
  return mutationLoadingState;
};

export type LoadingProviderProps = {
  children: ReactNode;
};

export const LoadingProvider = ({ children }: LoadingProviderProps): JSX.Element => {
  const [loadingState, setLoadingState] = useState<LoadingStatus>(INITIAL_LOADING_STATE);

  const isQueryCacheLoading = useQueryCacheLoading();
  const isMutationCacheLoading = useMutationCacheLoading();
  useEffect(() => {
    setLoadingState({ isQueryLoading: isQueryCacheLoading, isMutationLoading: isMutationCacheLoading });
  }, [isQueryCacheLoading, isMutationCacheLoading]);

  return <LoadingContext.Provider value={loadingState}>{children}</LoadingContext.Provider>;
};
