import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

type RouterLinkProps = React.ComponentPropsWithoutRef<typeof RouterLink>;

interface LinkPropsInterface extends Omit<RouterLinkProps, 'to'> {
  to?: RouterLinkProps['to'];
  href?: string;
}

export type LinkProps = LinkPropsInterface &
  (
    | {
        to: string;
        href?: undefined;
      }
    | {
        to?: undefined;
        href: string;
      }
  );

const isExternalLink = (href?: string): boolean => typeof href === 'string' && href.startsWith('http');

const Link = ({ href = undefined, to = undefined, children, className }: LinkProps): JSX.Element => {
  return isExternalLink(href) ? (
    <a className={className} href={href}>
      {children}
    </a>
  ) : (
    <RouterLink className={className} to={href || to}>
      {children}
    </RouterLink>
  );
};

export default Link;
