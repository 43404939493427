import React from 'react';
import { Redirect } from 'react-router-dom';
import paths from '../../../routing/paths';
import { UploadDocumentType, useUploadCaseDocumentsMutation } from '../../../services/CaseApi';
import DocumentUploadFormMarkup from './DocumentUploadFormMarkup';
import DocumentUploadSuccessModal from './DocumentUploadSuccessModal';
import { getRequiredFileCount } from './util';

export interface DocumentUploadFormProps {
  caseNumber: string;
  uploadDocumentType: UploadDocumentType;
}

const baseClassName = 'DocumentUploadForm';
const CLASS_NAMES = {
  base: baseClassName,
};

const DocumentUploadForm = ({ caseNumber, uploadDocumentType }: DocumentUploadFormProps): JSX.Element => {
  const { mutate, isError, isSuccess } = useUploadCaseDocumentsMutation(caseNumber);

  if (isError) {
    /**
     * TODO: better error handling by remaining on page and using Alert component
     * https://maestro.dhs.gov/jira/browse/UVME-2892
     */
    return <Redirect to={paths.error} />;
  }

  return (
    <div className={CLASS_NAMES.base}>
      <DocumentUploadSuccessModal caseNumber={caseNumber} isOpen={isSuccess} uploadDocumentType={uploadDocumentType} />
      <DocumentUploadFormMarkup
        caseNumber={caseNumber}
        requiredFileCount={getRequiredFileCount(uploadDocumentType)}
        onSubmit={({ files, phoneNumber, emailAddress }): void => mutate({ files, phoneNumber, emailAddress })}
      />
    </div>
  );
};

export default DocumentUploadForm;
