/**
 * Defined checks SVG as a string in order to load it simultaneously with the react app.
 * When loading as an image this file will only get fetched after the user is already on
 * the home page, leaving an empty gradient as the hero image.
 */
export default `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<svg width="80" height="80" preserveAspectRatio="none" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g stroke="rgb(38,54,96)" stroke-width="3.5px">
    <g>
      <path d="M 0 0 L 60 60" />
      <path d="M 0 60 L 60 0" />

      <path d="M -2 22 L 10 10" />
      <path d="M -2 42 L 20 20" />

      <path d="M 10 50 L 22 62" />
      <path d="M 20 40 L 42 62" />

      <path d="M 40 40 L 62 18" />
      <path d="M 50 50 L 62 38" />

      <path d="M 18 -2 L 40 20" />
      <path d="M 38 -2 L 50 10" />
    </g>
    <g>
      <path d="M 60 0 L 120 60" />
      <path d="M 60 60 L 120 0" />

      <path d="M 58 22 L 70 10" />
      <path d="M 58 42 L 80 20" />

      <path d="M 70 50 L 82 62" />
      <path d="M 80 40 L 102 62" />

      <path d="M 100 40 L 122 18" />
      <path d="M 110 50 L 122 38" />

      <path d="M 78 -2 L 100 20" />
      <path d="M 98 -2 L 110 10" />
    </g>
    <g>
      <path d="M 0 60 L 60 120" />
      <path d="M 0 120 L 60 60" />

      <path d="M -2 82 L 10 70" />
      <path d="M -2 102 L 20 80" />

      <path d="M 10 110 L 22 122" />
      <path d="M 20 100 L 42 122" />

      <path d="M 40 100 L 62 78" />
      <path d="M 50 110 L 62 98" />

      <path d="M 18 58 L 40 80" />
      <path d="M 38 58 L 50 70" />
    </g>
    <g>
      <path d="M 60 60 L 120 120" />
      <path d="M 60 120 L 120 60" />

      <path d="M 58 82 L 70 70" />
      <path d="M 58 102 L 80 80" />

      <path d="M 70 110 L 82 122" />
      <path d="M 80 100 L 102 122" />

      <path d="M 100 100 L 122 78" />
      <path d="M 110 110 L 122 98" />

      <path d="M 78 58 L 100 80" />
      <path d="M 98 58 L 110 70" />
    </g>
  </g>
</svg>`;
