import { Button } from '@ver-uds/react';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Alert } from 'uswds-react';
import { COLUMN_9_CLASS_NAME } from '../../../constants';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import TermsAndConditionsForm, { TermsAndConditionsFormFields } from './TermsAndConditionsForm';
import TermsAndConditionsText from './TermsAndConditionsText';

export interface TermsAndConditionsMarkupProps {
  onSubmit: (data: TermsAndConditionsFormFields) => void;
  onCancel: () => void;
}

const baseClassName = 'terms-and-conditions-markup';
export const CLASS_NAMES = {
  base: baseClassName,
  header: classNames(`${baseClassName}__header`, 'margin-0'),
  bodyText: classNames(`${baseClassName}__body-text`, COLUMN_9_CLASS_NAME),
};

const TermsAndConditionsMarkup = ({ onSubmit, onCancel }: TermsAndConditionsMarkupProps): JSX.Element => {
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);

  return (
    <div className={baseClassName}>
      <PrivacyPolicyModal isOpen={isPrivacyPolicyOpen} close={(): void => setIsPrivacyPolicyOpen(false)} />
      <h1 className={CLASS_NAMES.header}>Our Policy</h1>
      <p className={CLASS_NAMES.bodyText}>
        You’re almost done creating your myE-Verify account. To continue with creating your account, you must read and
        accept the myE-Verify Terms of Service.
      </p>
      <TermsAndConditionsText />
      <Alert status="info">
        <Alert.Heading>What Happens Next</Alert.Heading>
        <Alert.Text className={CLASS_NAMES.bodyText}>
          USCIS is commited to protecting your privacy and that’s why we require you to complete an identity proofing
          quiz. We use a third party identity assurance provider to generate questions that only you should be able to
          answer. To learn more about how USCIS protects your privacy, please read our{' '}
          <Button variant="tertiary" onClick={(): void => setIsPrivacyPolicyOpen(true)}>
            Privacy Policy
          </Button>
          .
        </Alert.Text>
      </Alert>
      <TermsAndConditionsForm onSubmit={onSubmit} onCancel={onCancel} />
    </div>
  );
};

export default TermsAndConditionsMarkup;
