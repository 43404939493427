import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { Alert } from 'uswds-react';
import FANDownloadButton from './FANDownloadButton';

export interface SelfCheckCaseDetailPendingRefContentProps {
  className?: ClassValue;
}

const baseClassName = 'case-detail-content-self-check--pending-referral';
const CLASS_NAMES = {
  base: baseClassName,
  textBody: classNames(`${baseClassName}__text-body`, 'margin-top-3', 'grid-col-9'),
  stepOneText: classNames(`${baseClassName}__step-one-text`, 'margin-top-1'),
  downloadFANButton: classNames(`${baseClassName}__download-fan`, 'margin-top-2'),
  alert: classNames(`${baseClassName}__alert`),
};

const SelfCheckCaseDetailPendingRefContent = ({
  className = undefined,
}: SelfCheckCaseDetailPendingRefContentProps): JSX.Element => (
  <div className={classNames(CLASS_NAMES.base, className)}>
    <div className={CLASS_NAMES.alert} role="alert" aria-atomic="true">
      <Alert status="warning">
        <Alert.Heading>Tentative Nonconfirmation (Mismatch)</Alert.Heading>
        <Alert.Text>
          <div className="grid-col-9">
            It’s OK! Self Check is temporarily unable to confirm your employment eligibility. Please review your next
            steps towards case resolution.
          </div>
        </Alert.Text>
      </Alert>
    </div>
    <div className={CLASS_NAMES.textBody}>
      <h3>Next Steps</h3>
      <div>
        <h4>Step 1: Download, print and review your Further Action Notice.</h4>
        <p className={CLASS_NAMES.stepOneText}>
          Carefully review the Further Action Notice (FAN). This document will explain why you received a Tentative
          Nonconfirmation result and what to do next.
        </p>
      </div>
      <FANDownloadButton className={CLASS_NAMES.downloadFANButton} />
    </div>
  </div>
);

export default SelfCheckCaseDetailPendingRefContent;
