import { ReadableCaseStatus } from '../../../../../services/CaseApi';
import { SelfCheckCaseDetailVariant } from '../constants';

const referredCaseStatuses = [
  ReadableCaseStatus.EMPLOYEE_REFERRED_DHS,
  ReadableCaseStatus.EMPLOYEE_REFERRED_SSA,
  ReadableCaseStatus.EMPLOYEE_REFERRED_DHS_AND_SSA,
  ReadableCaseStatus.EMPLOYEE_REFERRED_DHS_OR_SSA,
  ReadableCaseStatus.EMPLOYEE_REFERRED_CONTINUANCE,
];

const pendingReferralCaseStatuses: ReadableCaseStatus[] = [
  ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS,
  ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_SSA,
  ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_AND_SSA,
  ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_OR_SSA,
];

const isSelfCheckCase = (selfCheckVariant?: SelfCheckCaseDetailVariant): boolean =>
  typeof selfCheckVariant !== 'undefined';

const isGenericCaseUploadAccessAllowed = (caseStatus: ReadableCaseStatus): boolean =>
  pendingReferralCaseStatuses.includes(caseStatus) || caseStatus === ReadableCaseStatus.CONTINUANCE;

/* eslint-disable import/prefer-default-export */
export const isCaseUploadAccessAllowed = (
  caseStatus: ReadableCaseStatus,
  selfCheckVariant?: SelfCheckCaseDetailVariant,
): boolean =>
  referredCaseStatuses.includes(caseStatus) ||
  (!isSelfCheckCase(selfCheckVariant) && isGenericCaseUploadAccessAllowed(caseStatus));
