import React, { useContext } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import map from 'lodash/map';
import noop from 'lodash/noop';
import routes from './routes';
import { AuthenticationContext } from '../context/Authentication/AuthenticationContext';
import PrivateRoute from './PrivateRoute';
import LogoutWarningModal from '../components/LogoutWarningModal';
import { UserContext } from '../context/User/UserContext';

const Router = (): JSX.Element => {
  const { isAuthenticated, isConfigValid } = useContext(AuthenticationContext);
  const { hasCompletedIdProofing } = useContext(UserContext).userInfoResponseData;

  return (
    <>
      <LogoutWarningModal />
      {/* getUserConfirmation is overridden to prevent the default window.confirm history block behavior. */}
      <BrowserRouter getUserConfirmation={noop}>
        <Switch>
          {map(routes, ({ children, path, exact, isPublic }, key) => (
            <PrivateRoute
              key={key}
              path={path}
              exact={exact}
              isAuthenticated={isAuthenticated()}
              isLoading={!isConfigValid()}
              isPublic={isPublic}
              hasCompletedIdProofing={hasCompletedIdProofing}
            >
              {children}
            </PrivateRoute>
          ))}
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default Router;
