const paths: { [key: string]: string } = {
  caseDetail: '/cases/:caseNumber',
  createAccount: '/create-account',
  documentUpload: '/cases/:caseNumber/upload',
  selfCheck: '/self-check',
  selfLock: '/self-lock',
  selfLockLock: '/self-lock/lock-ssn',
  confirmIdentity: '/confirm-identity',
  error: '/error',
  home: '/',
  catchAll: '*',
};

export default paths;
