import React, { ComponentPropsWithoutRef, useContext } from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { Button } from '@ver-uds/react';
import paths from '../../../routing/paths';
import { AuthenticationContext } from '../../../context/Authentication/AuthenticationContext';

export const RETURN_HOME_BUTTON_TEXT = 'Return Home';
export const UNAUTHENTICATED_RETURN_HOME_BUTTON_TEXT = 'Return to myE-Verify homepage';

export interface ReturnHomeButtonProps {
  className?: ClassValue;
  variant?: ComponentPropsWithoutRef<typeof Button>['variant'];
}

const baseClassName = 'return-home-button';
const CLASS_NAMES = {
  base: baseClassName,
};

const ReturnHomeButton = ({ className = undefined, variant = 'secondary' }: ReturnHomeButtonProps): JSX.Element => {
  const { isAuthenticated } = useContext(AuthenticationContext);

  return (
    <Button className={classNames(CLASS_NAMES.base, className)} as="a" href={paths.home} variant={variant}>
      {!isAuthenticated() ? UNAUTHENTICATED_RETURN_HOME_BUTTON_TEXT : RETURN_HOME_BUTTON_TEXT}
    </Button>
  );
};

export default ReturnHomeButton;
