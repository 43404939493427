import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';

export interface TelephoneLinkProps {
  className?: ClassValue;
  phoneNumber: string;
}

const baseClassName = 'telephone-link';
const CLASS_NAMES = {
  base: baseClassName,
};

const TelephoneLink = ({ className = undefined, phoneNumber }: TelephoneLinkProps): JSX.Element => (
  <a className={classNames(CLASS_NAMES.base, className)} href={`tel:${phoneNumber}`}>
    {phoneNumber}
  </a>
);

export default TelephoneLink;
