import classNames from 'classnames';
import React from 'react';
import { Accordion, Alert } from 'uswds-react';
import { COLUMN_9_CLASS_NAME } from '../../../constants';
import { CreateUserQuizRequest } from '../../../services/UserApi';
import { CreateAccountSsnHelpSection } from '../../HelpSections';
import AddressHelpSection from '../../HelpSections/AddressHelpSection';
import LastNameHelpSection from '../../HelpSections/LastNameHelpSection';
import PersonalInformationForm from './PersonalInformationForm';

export interface PersonalInformationMarkupProps {
  onSubmit: (data: CreateUserQuizRequest) => void;
  onCancel: () => void;
  data?: CreateUserQuizRequest;
}

const baseClassName = 'personal-information-markup';
export const CLASS_NAMES = {
  base: baseClassName,
  header: classNames(`${baseClassName}__header`, 'margin-0'),
  alert: classNames(`${baseClassName}__alert`, 'margin-bottom-3'),
  bodyText: classNames(`${baseClassName}__body-text`, COLUMN_9_CLASS_NAME),
  helpSection: classNames(`${baseClassName}__help-section`, 'margin-top-6'),
};

const PersonalInformationMarkup = ({
  onSubmit,
  onCancel,
  data = undefined,
}: PersonalInformationMarkupProps): JSX.Element => {
  return (
    <div className={baseClassName}>
      <h1 className={CLASS_NAMES.header}>Enter Personal Information</h1>
      <Alert status="info" className={CLASS_NAMES.alert}>
        <Alert.Text className={CLASS_NAMES.bodyText}>
          The information that you enter below will be used by a third party identity assurance service to generate
          questions. Every user is required to answer these questions to ensure that an individual is only allowed to
          perform an employment eligibility check on their own records. The name, date of birth, and Social Security
          number entered below will be saved for use in future employment eligibility checks.
        </Alert.Text>
      </Alert>
      <PersonalInformationForm initialData={data} onSubmit={onSubmit} onCancel={onCancel} />
      <Accordion className={CLASS_NAMES.helpSection} bordered>
        <LastNameHelpSection />
        <CreateAccountSsnHelpSection />
        <AddressHelpSection />
      </Accordion>
    </div>
  );
};

export default PersonalInformationMarkup;
