import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { Alert } from 'uswds-react';
import { COLUMN_9_CLASS_NAME } from '../../../../constants';
import { idProofingLastAttempt, UserInfoResponseData } from '../../../../services/UserApi';

export interface AttemptsExceededAlertProps {
  className?: ClassValue;
  userInfo?: UserInfoResponseData;
}

export const GENERATE_QUIZ_LOCKOUT_DAYS = 4;

const baseClassName = 'attempts-exceeded-alert';
const CLASS_NAMES = {
  base: baseClassName,
  text: classNames(`${baseClassName}__text`, COLUMN_9_CLASS_NAME),
};

const AttemptsExceededAlert = ({
  className = undefined,
  userInfo = undefined,
}: AttemptsExceededAlertProps): JSX.Element => {
  const bodyContent =
    'We were not able to confirm your identity at this time based on the information you provided. ' +
    'If you think the information you entered is incorrect, you can attempt the quiz again on ';

  const getReattemptDate = (): string | undefined => {
    if (userInfo?.[idProofingLastAttempt]) {
      const lastAttemptDateVal = userInfo[idProofingLastAttempt];
      const reattemptDate = moment(lastAttemptDateVal).add(GENERATE_QUIZ_LOCKOUT_DAYS, 'days');
      return reattemptDate.format('MMMM D, YYYY');
    }
    return undefined;
  };

  const attemptsExceededAlertStyling = {
    marginTop: '16px',
  };

  return (
    <div
      className={classNames(CLASS_NAMES.base, className)}
      role="alert"
      aria-atomic="true"
      style={attemptsExceededAlertStyling}
    >
      <Alert status="error">
        <Alert.Heading>Unable to Confirm Identity - What Happens Next</Alert.Heading>
        <Alert.Text className={CLASS_NAMES.text}>
          {bodyContent}
          <strong>{getReattemptDate()}.</strong>
        </Alert.Text>
      </Alert>
    </div>
  );
};

export default AttemptsExceededAlert;
