import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';

export interface DocumentToUploadLabelProps {
  className?: ClassValue;
}

const baseClassName = 'document-to-upload-label';
const CLASS_NAMES = {
  base: baseClassName,
};

const DocumentToUploadLabel = ({ className = undefined }: DocumentToUploadLabelProps): JSX.Element => (
  <strong className={classNames(CLASS_NAMES.base, className)}>Document to Upload: </strong>
);

export default DocumentToUploadLabel;
