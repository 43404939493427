import { PartialRecord } from '../../utils/types';
import { UserInfoResponseData } from '../UserApi';

export enum CaseStatus {
  DRAFT = 'DRAFT',
  PHOTO_MATCH = 'PHOTO_MATCH',
  SCAN_AND_UPLOAD = 'SCAN_AND_UPLOAD',
  UNCONFIRMED_DATA = 'UNCONFIRMED_DATA',
  PENDING_REFERRAL = 'PENDING_REFERRAL',
  REFERRED = 'REFERRED',
  EMPLOYMENT_AUTHORIZED = 'EMPLOYMENT_AUTHORIZED',
  CLOSED = 'CLOSED',
  FINAL_NONCONFIRMATION = 'FINAL_NONCONFIRMATION',
  CLOSE_CASE_AND_RESUBMIT = 'CLOSE_CASE_AND_RESUBMIT',
  MANUAL_REVIEW = 'MANUAL_REVIEW',
  QUEUED = 'QUEUED',
}

export interface SelfCheckCaseResponse {
  data: SelfCheckCaseResponseData;
}

export interface SelfCheckCaseResponseData {
  caseNumber: string;
  caseStatus: CaseStatus;
  caseStatusDisplay: string;
  caseEligibilityStatement: string | null;
  dhsReferralStatus: string | null;
  ssaReferralStatus: string | null;
}

export enum CitizenshipStatus {
  US_CITIZEN = 'US_CITIZEN',
  US_NON_CITIZEN = 'US_NON_CITIZEN',
  PERMANENT_RESIDENT = 'PERMANENT_RESIDENT',
  NONCITIZEN_AUTHORIZED_TO_WORK = 'NONCITIZEN_AUTHORIZED_TO_WORK',
}

export enum DocumentType {
  LIST_B_C_DOCUMENTS = 'LIST_B_C_DOCUMENTS',
  US_PASSPORT = 'US_PASSPORT',
  I_94 = 'I_94',
  FOREIGN_PASSPORT_I_551 = 'FOREIGN_PASSPORT_I_551',
  FOREIGN_PASSPORT_I_94 = 'FOREIGN_PASSPORT_I_94',
  I_766 = 'I_766',
  I_551 = 'I_551',
}

export interface SubmitSelfCheckCaseRequest {
  firstName: string;
  middleName?: string;
  lastName: string;
  uscisANumber?: string;
  citizenshipStatus: CitizenshipStatus;
  countryOfIssuance?: string;
  documentExpirationDate?: string; // expect ISO date string format: yyyy-MM-d
  documentType: DocumentType;
  i551Number?: string;
  i766Number?: string;
  i94Number?: string;
  usPassportNumber?: string;
  foreignPassportNumber?: string;
  visaNumber?: string;
}

export type SelfCheckReverificationFieldNameUserInputRequired = keyof Pick<
  SubmitSelfCheckCaseRequest,
  | 'firstName'
  | 'lastName'
  | 'uscisANumber'
  | 'countryOfIssuance'
  | 'documentExpirationDate'
  | 'i551Number'
  | 'i766Number'
  | 'i94Number'
  | 'usPassportNumber'
  | 'foreignPassportNumber'
>;

export type SelfCheckCaseReverificationFieldName =
  | SelfCheckReverificationFieldNameUserInputRequired
  | keyof Pick<UserInfoResponseData, 'firstName' | 'lastName' | 'dob' | 'ssn'>;

export type SelfCheckCaseReverificationFieldsResponseData = SelfCheckCaseReverificationFieldName[];

export type SelfCheckCaseReverificationFieldsResponse = {
  data: SelfCheckCaseReverificationFieldsResponseData;
};

export type SelfCheckCaseReverificationRequest = PartialRecord<SelfCheckCaseReverificationFieldName, string>;

export enum SelfCheckCaseClosureReason {
  INCORRECT_DATA = 'INCORRECT_DATA',
  EMPLOYER_INSTRUCTED_TO_CREATE_NEW_CASE = 'EMPLOYER_INSTRUCTED_TO_CREATE_NEW_CASE',
  EMPLOYMENT_AUTHORIZED = 'EMPLOYMENT_AUTHORIZED',
  DUPLICATE_CASE_DATA_EXISTS = 'DUPLICATE_CASE_DATA_EXISTS',
}

export type SelfCheckCaseClosureRequest = {
  closureReason: SelfCheckCaseClosureReason;
};

export enum CaseElligibilityStatement {
  NO_ACTION_FNC = 'NO_ACTION_FNC',
}
