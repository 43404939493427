import React from 'react';
import { ClassValue } from 'classnames/types';
import get from 'lodash/get';
import { UploadDocumentType, useGetOpenSelfCheckCaseQuery } from '../../../../services/CaseApi';
import DocumentUploadSuccessModalMarkup from './DocumentUploadSuccessModalMarkup';

export interface DocumentUploadSuccessModalProps {
  className?: ClassValue;
  caseNumber: string;
  isOpen: boolean;
  uploadDocumentType: UploadDocumentType;
}

const DocumentUploadSuccessModal = ({
  className = undefined,
  caseNumber,
  isOpen,
  uploadDocumentType,
}: DocumentUploadSuccessModalProps): JSX.Element => {
  const { data: openSelfCheckCaseData, isIdle, isLoading } = useGetOpenSelfCheckCaseQuery();

  if (isIdle || isLoading) {
    return <></>;
  }

  const isSelfCheckCase = get(openSelfCheckCaseData, 'caseNumber') === caseNumber;
  return (
    <DocumentUploadSuccessModalMarkup
      className={className}
      isOpen={isOpen}
      isSelfCheckCase={isSelfCheckCase}
      uploadDocumentType={uploadDocumentType}
    />
  );
};

export default DocumentUploadSuccessModal;
