import React from 'react';
import { UploadDocumentType } from '../../../services/CaseApi';
import DocumentToUploadLabel from './DocumentToUploadLabel';

/* eslint-disable import/prefer-default-export */
export const mapUploadDocumentTypeToInfoCopy = (
  uploadDocumentType: UploadDocumentType | null,
): React.ReactNode | undefined => {
  switch (uploadDocumentType) {
    case UploadDocumentType.US_PASSPORT:
      return (
        <>
          <DocumentToUploadLabel />
          Upload a clear image of the data page inside your U.S. Passport (ID page and the Passport Barcode page) that
          contains your photo, or the front and back of your U.S. Passport Card.
        </>
      );
    case UploadDocumentType.DRIVERS_LICENSE:
      return (
        <>
          <DocumentToUploadLabel />
          Upload a clear image of the front and back of your driver's license or identification card issued by a state
          or outlying territory of the U.S.
        </>
      );
    case UploadDocumentType.UNKNOWN:
      return (
        <>
          <DocumentToUploadLabel />
          Upload a clear image of the document(s) you choose to prove your current work authorization. This might
          include: the front and back of your Form I-766, Employment Authorization Document Card; data page of your
          foreign passport as well as the Form I-551 stamp or Form I-551 printed notation on an immigrant visa; or other
          applicable documents.
        </>
      );
    case UploadDocumentType.NATZ:
      return (
        <>
          <DocumentToUploadLabel />
          Upload a clear image of your Naturalization Certificate; the front and back image of your Permanent Resident
          Card; and/or the data page inside your U.S. Passport that contains your photo, or the front and back of your
          U.S. Passport Card.
        </>
      );
    default:
      return undefined;
  }
};
