import React from 'react';
import classNames from 'classnames';
import { Accordion } from 'uswds-react';
import ActionFooter from '../../../ActionFooter/ActionFooter';
import GenerateQuizErrorAlert from './GenerateQuizErrorAlert';
import QuizGenerationHelpSection from '../../../HelpSections/QuizGenerationHelpSection/QuizGenerationHelpSection';
import ConfirmCreditInformationHelpSection from '../../../HelpSections/ConfirmCreditInformationHelpSection/ConfirmCreditInformationHelpSection';
import ConfirmGovernmentRecordsHelpSection from '../../../HelpSections/ConfirmGovernmentRecordsHelpSection/ConfirmGovernmentRecordsHelpSection';
import { UserInfoResponseData } from '../../../../services/UserApi';

export interface GenerateQuizErrorMarkupProps {
  onSubmit: () => void;
  onCancel: () => void;
  _userInfo?: UserInfoResponseData; // TODO: Research original need of this variable
}

const baseClassName = 'generate-quiz-error-markup';
export const CLASS_NAMES = {
  base: baseClassName,
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
  helpSection: classNames(`${baseClassName}__help-section`, 'margin-top-6'),
};

const SUBMIT_BUTTON_TEXT = 'Edit Personal Information';

const GenerateQuizErrorMarkup = ({ _userInfo, onSubmit, onCancel }: GenerateQuizErrorMarkupProps): JSX.Element => {
  const headerContent = 'Failed to Generate Quiz';

  return (
    <div className={baseClassName}>
      <h2>{headerContent}</h2>
      <GenerateQuizErrorAlert />
      <ActionFooter
        className={CLASS_NAMES.actionFooter}
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitButtonText={SUBMIT_BUTTON_TEXT}
      />
      <Accordion className={CLASS_NAMES.helpSection} bordered>
        <QuizGenerationHelpSection />
        <ConfirmCreditInformationHelpSection />
        <ConfirmGovernmentRecordsHelpSection />
      </Accordion>
    </div>
  );
};

export default GenerateQuizErrorMarkup;
