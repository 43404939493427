import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { StartNewCaseActionFooter } from '../shared';

export interface SelfCheckCaseDetailFNCActionSectionProps {
  className?: ClassValue;
  caseNumber: string;
}

const baseClassName = 'case-detail-self-check-action-section--fnc';
const CLASS_NAMES = {
  base: baseClassName,
};

const SelfCheckCaseDetailFNCActionSection = ({
  className = undefined,
  caseNumber,
}: SelfCheckCaseDetailFNCActionSectionProps): JSX.Element => (
  <StartNewCaseActionFooter className={classNames(CLASS_NAMES.base, className)} caseNumber={caseNumber} />
);

export default SelfCheckCaseDetailFNCActionSection;
