import React from 'react';
import { Accordion } from 'uswds-react';
import HelpSectionContent from '../../../HelpSectionContent';

const baseClassName = 'ssn-help-section--create-account';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

const CreateAccountSsnHelpSection = (): JSX.Element => (
  <>
    <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
      Help with Social Security number (SSN)
    </Accordion.Heading>
    <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
      <p>
        The identity authentication process required by myE-Verify is used to authenticate your identity. This identity
        assessment is used to prevent someone else from checking your status.
      </p>
    </HelpSectionContent>
  </>
);

export default CreateAccountSsnHelpSection;
