import React from 'react';
import PageContentLayout from '../layouts/PageContentLayout/PageContentLayout';
import SelfLock from '../components/SelfLock/SelfLock';

const SelfLockPage = (): JSX.Element => (
  <PageContentLayout title="Self Lock">
    <SelfLock />
  </PageContentLayout>
);

export default SelfLockPage;
