import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import paths from '../../../../routing/paths';
import {
  SelfCheckCaseResponseData,
  SubmitSelfCheckCaseRequest,
  useSubmitSelfCheckCaseMutation,
} from '../../../../services/SelfCheckApi';
import { injectURLParams } from '../../../../utils/routing';
import SelfCheckFormReviewMarkup, {
  REVERIFY_CASE_STATUS,
  SelfCheckFormReviewMarkupProps,
} from './SelfCheckFormReviewMarkup';

export interface SelfCheckFormReviewProps
  extends Omit<SelfCheckFormReviewMarkupProps, 'onSubmit' | 'onReverifySuccess'> {
  onCaseSubmitSuccess: (caseData?: SelfCheckCaseResponseData) => void;
}

const SelfCheckFormReview = ({ onCaseSubmitSuccess, ...props }: SelfCheckFormReviewProps): JSX.Element => {
  const {
    mutate,
    data: submitCaseResponseData,
    error: submissionError,
    isLoading: isSubmissionLoading,
    isSuccess: isSubmissionSuccess,
  } = useSubmitSelfCheckCaseMutation();
  const history = useHistory();

  useEffect(() => {
    // useEffect is required as long as onCaseSubmitSuccess causes a state transition which results in a re-render in this (child) component
    if (isSubmissionSuccess) {
      if (submitCaseResponseData?.caseStatus !== REVERIFY_CASE_STATUS) {
        history.push(injectURLParams(paths.caseDetail, { caseNumber: submitCaseResponseData?.caseNumber as string }));
        onCaseSubmitSuccess();
      } else {
        onCaseSubmitSuccess(submitCaseResponseData);
      }
    }
  }, [isSubmissionSuccess, onCaseSubmitSuccess, submitCaseResponseData, history]);

  if (submissionError) {
    /**
     * TODO: https://maestro.dhs.gov/jira/browse/UVME-2892
     * show modal alert and remain on page
     */
    console.log(`submission error:\n${JSON.stringify(submissionError)}`); // eslint-disable-line no-console
  }

  const handleSubmit = (payload: SubmitSelfCheckCaseRequest): void => mutate(payload);

  return (
    <>
      {!isSubmissionLoading && (
        <SelfCheckFormReviewMarkup
          {...props} // eslint-disable-line react/jsx-props-no-spreading
          onReverifySuccess={onCaseSubmitSuccess}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default SelfCheckFormReview;
