import { useEffect } from 'react';
import { DeepMap, FieldElement, FieldError, Ref as ReactHookFormRef, RegisterOptions, Validate } from 'react-hook-form';
import curry from 'lodash/curry';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import noop from 'lodash/noop';
import { PartialRecord } from '../types';

/* eslint-disable import/prefer-default-export */
export type Errors<F> = DeepMap<F, FieldError>;

export type ErrorMessages = PartialRecord<keyof RegisterOptions | string, string>;

type Ref = (FieldElement & ReactHookFormRef) | null;

export type RegisterFn = (rules?: RegisterOptions) => (ref: Ref) => void;

export const registerNoop: RegisterFn = curry(noop);

export type SetValueFn<F> = (
  name: keyof F,
  value: unknown,
  config?:
    | Partial<{
        shouldValidate: boolean;
        shouldDirty: boolean;
      }>
    | undefined,
) => void;

export type ValidateRules = Validate | Record<string, Validate> | undefined;
export type ValidationRules = Partial<RegisterOptions>;

export const REQUIRED_LABEL_HINT = '(required)';

export const getFieldError = (error?: FieldError, errorMessages?: ErrorMessages): string | undefined => {
  const errorType = get(error, 'type');
  if (isUndefined(errorType)) {
    return undefined;
  }

  return get(errorMessages, errorType as keyof RegisterOptions);
};

// This will prompt the user before leaving the page as long as it is enabled.
export const useOnBeforeUnload = (enabled: boolean): void => {
  useEffect(() => {
    const showConfirmation = (event: BeforeUnloadEvent): void => {
      if (enabled) {
        // Cancel the event as stated by the standard.
        event.preventDefault();

        // Chrome requires returnValue to be set.
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', showConfirmation);

    return (): void => {
      window.removeEventListener('beforeunload', showConfirmation);
    };
  }, [enabled]);
};

export const doCombinedStringLengthsExceedMax = (maxLength: number, ...strings: string[]): boolean => {
  return maxLength >= strings.reduce((length, string) => length + string.length, 0);
};
