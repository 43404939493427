import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { Accordion } from 'uswds-react';
import { COLUMN_9_CLASS_NAME } from '../../../../constants';
import { UserInfoResponseData } from '../../../../services/UserApi';
import SelfCheckFormConfirmIdentityMarkupUserFieldGroup from './SelfCheckFormConfirmIdentityMarkupUserFieldGroup';
import { UserInfoFormData } from '../constants';
import LastNameHelpSection from '../../../HelpSections/LastNameHelpSection';

export interface SelfCheckConfirmIdentityProps {
  className?: ClassValue;
  onSubmit: (data: UserInfoFormData) => void;
  userInfo?: UserInfoResponseData;
  identityFormData?: UserInfoFormData;
}

const baseClassName = 'self-check-confirm-identity';
const CLASS_NAMES = {
  base: baseClassName,
  aboutSection: classNames(`${baseClassName}__about-section`, COLUMN_9_CLASS_NAME, 'margin-top-2'),
  helpSection: classNames(`${baseClassName}__help-section`, 'margin-top-6'),
  helpLastName: `${baseClassName}__help-last-name`,
  helpSsn: `${baseClassName}__help-ssn`,
};

const SelfCheckFormConfirmIdentityMarkup = ({
  className = undefined,
  onSubmit,
  userInfo = undefined,
  identityFormData = undefined,
}: SelfCheckConfirmIdentityProps): JSX.Element => {
  if (!userInfo) {
    return <></>;
  }

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <p className={CLASS_NAMES.aboutSection}>
        The information specified below will be compared to Social Security Administration and Department of Homeland
        Security records to determine if you would be successfully authorized by the E‑Verify system.
      </p>
      <SelfCheckFormConfirmIdentityMarkupUserFieldGroup
        hideSsn
        userInfo={userInfo}
        onSubmit={onSubmit}
        isEditable
        identityFormData={identityFormData}
      />
      <Accordion className={CLASS_NAMES.helpSection} bordered>
        <LastNameHelpSection />
      </Accordion>
    </div>
  );
};

export default SelfCheckFormConfirmIdentityMarkup;
