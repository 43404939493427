import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import SelfCheckFormConfirmIdentityMarkupUserFields, {
  SelfCheckFormConfirmIdentityMarkupUserFieldsProps,
} from '../../SelfCheckFormConfirmIdentity/SelfCheckFormConfirmIdentityMarkupUserFieldGroup';

export interface PersonalInfoSectionProps
  extends Pick<SelfCheckFormConfirmIdentityMarkupUserFieldsProps, 'userInfo' | 'hideSsn'> {
  className?: ClassValue;
}

const baseClassName = 'self-check-review-personal-info-section';
const CLASS_NAMES = {
  base: baseClassName,
  userFields: classNames(`${baseClassName}__user-fields`, 'margin-top-2'),
};

const PersonalInfoSection = ({ className = undefined, hideSsn, userInfo }: PersonalInfoSectionProps): JSX.Element => {
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <h2>Personal Information</h2>
      <SelfCheckFormConfirmIdentityMarkupUserFields
        className={CLASS_NAMES.userFields}
        hideSsn={hideSsn}
        userInfo={userInfo}
      />
    </div>
  );
};

export default PersonalInfoSection;
