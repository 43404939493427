import classNames from 'classnames';
import React from 'react';

const baseClassName = 'terms-and-conditions-text';
export const CLASS_NAMES = {
  base: classNames(
    baseClassName,
    'border',
    'radius-md',
    'border-base',
    'padding-1',
    'maxh-tablet',
    'overflow-y-scroll',
    'margin-y-3',
  ),
};

const TermsAndConditionsText = (): JSX.Element => {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div className={CLASS_NAMES.base} tabIndex={0}>
      <h3>myE-Verify Terms of Service</h3>
      <p>
        In these Terms of Service persons that access, use or view myE-Verify are called myE-Verify “participants.”
        Before participating in myE-Verify you must read, agree with and accept all the terms and conditions contained
        in these Terms of Service by checking the box at the bottom of this page. Failure to comply with these Terms of
        Service may lead to account disablement, suspension, or termination, as well as other legal liability.
      </p>
      <h4>About myE-Verify</h4>
      <p>
        myE-Verify is a Department of Homeland Security (DHS) program operated in cooperation with the Social Security
        Administration (SSA) that permits participants to set up a user account, perform a Self Check of their work
        authorization, lock or unlock their Social Security Number (SSN) from or for use in E-Verify, review their Case
        History or Case Tracker, and manage their myE-Verify account. myE-Verify is authorized by Title IV, Subtitle A,
        of the Illegal Immigration Reform and Immigrant Responsibility Act of 1996 (IIRIRA), Pub. L. 104-208, 110 Stat.
        3009, as amended (8 U.S.C. § 1324a note). To learn more about myE-Verify before participating, visit our
        website.
      </p>
      <h4>
        Access to myE-Verify is at DHS discretion and is subject to continuous monitoring in accordance with DHS policy.
      </h4>
      <p>
        All myE-Verify system enhancements, including but not limited to myE-Verify checking additional data sources and
        instituting new verification policies or procedures, are covered under these Terms of Service. It is the
        myE-Verify participant’s responsibility to check periodically for any changes DHS may make to these Terms of
        Service. By continuing to access, use or rely on myE-Verify, participants agree to be bound by any such Terms of
        Service revisions or modifications without executing a supplemental Terms of Service.
      </p>
      <h4>A Word about Liability</h4>
      <p>
        DHS works hard to provide the best services we can and to specify clear guidelines for everyone who uses them.
        Our services, however, are provided “as is,” and we make no guarantees that they always will be safe, secure, or
        error-free, or that they will function without disruptions, delays, or imperfections. To the extent permitted by
        law, we also DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. Accordingly, our liability shall
        be limited to the fullest extent permitted by applicable law, and under no circumstance will we be liable to
        participants or others for any lost profits, revenues, information, or data, or consequential, special,
        indirect, exemplary, punitive, or incidental damages arising out of or related to participation in myE-Verify,
        these Terms of Service or our services.
      </p>
      <h4>Self Check</h4>
      <p>
        myE-Verify Self Check lets you confirm that your employment eligibility information is in order by checking it
        against DHS-accessed databases. If Self Check finds a data-mismatch, it provides you with instructions to
        correct your records with the appropriate federal agency. By using Self Check, you agree that you will not
        submit biographical or employment authorization document data that relates to anyone other than yourself, except
        that a minor’s parent or legal guardian, or a representative from a nonprofit organization, association or
        rehabilitation program placing an individual into a position of employment may create a myE-Verify account and
        use Self Check on behalf of that minor or individual. Additionally, employers, individuals, and other entities
        cannot require you to use Self Check or share your case results with them.
      </p>
      <h4>Identity Assurance Provider</h4>
      <p>
        myE-Verify requires use of an independent third party to provide identity assurance services to DHS in
        accordance with the terms and conditions of a service level agreement. DHS does not control or maintain the
        information the identity assurance provider gathers or uses. DHS receives a response from the identity assurance
        provider containing the result of the verification. When completing myE-Verify identity assurance and other
        processes, you may be required to receive one or more electronic messages. Standard messaging rates or connect
        rates from your mobile phone carrier may apply to the delivery and receipt of information in these process, (for
        example, by SMS text message), and DHS is not responsible for those charges.
      </p>
      <p>
        DHS makes no representations whatsoever about any other website as to content, accuracy, inaccuracy, or
        reliability of the information the identity assurance provider has gathered from its sources. We have no role in
        selecting or culling out the information sources, but do rely on the expertise of the third party sites to
        gather relevant information. We will hold the identity service provider to the standards of security and privacy
        included in the terms of our service level agreement.
      </p>
      <h4>A. myE-Verify Accepted Uses</h4>
      <p>Participants who acknowledge and accept these Terms of Service agree to:</p>
      <ol>
        <li>
          Use myE-Verify and any related service as directed by federal law and regulation, executive order, DHS
          policies, and these Terms of Service; and become familiar with and follow the most recent myE-Verify Terms of
          Service, guidance, and tutorials.
        </li>
        <li>
          Provide true, accurate, current, and complete information about themselves, as prompted by the myE-Verify
          enrollment pages or otherwise requested by DHS.
        </li>
        <li>
          Obtain and commit the necessary equipment, personnel and resources to use myE-Verify as required to fulfill
          the participant’s responsibilities under these Terms of Service.
        </li>
        <li>
          Fully cooperate with DHS and other authorized agencies in their compliance monitoring and evaluation of
          myE-Verify.
        </li>
        <li>
          Use myE-Verify only in the 50 U.S. states, the District of Columbia, Puerto Rico, the U.S. Virgin Islands,
          Guam, and the Commonwealth of the Northern Mariana Islands.
        </li>
      </ol>
      <h4>B. myE-Verify Service and Access</h4>
      <ol>
        <li>
          myE-Verify is an official U.S. government system, and can only be used for authorized purposes. We make every
          effort to ensure the quality, integrity, and utility of the information in the system while maintaining
          privacy and security. Failure to comply with these Terms of Service may be considered a security incident
          under DHS policy. If a myE-Verify participant is involved in a security incident, that participant’s
          myE-Verify accounts, usernames and passwords may be temporarily or permanently disabled, suspended or removed
          with or without prior notice, effectively revoking all access to myE-Verify, any myE-Verify username and
          password, and any or all myE-Verify data.
        </li>
        <li>
          Unauthorized access of myE-Verify and its related systems is a violation of U.S. laws and DHS policies and may
          result in administrative, civil, or criminal penalties. myE-Verify participants, including unauthorized users,
          must not attempt to access information, files or system files not within their account. Absence of security
          access controls is not an authorization to access or a waiver of applicable laws, regulations or DHS policies.
          Only authorized personnel may alter web pages. Unauthorized use of this system may result in criminal, civil
          and/or administrative penalties.
        </li>
        <li>
          The participant acknowledges that the information he or she receives from SSA is governed by the Privacy Act
          (5 U.S.C. § 552a(i)(1) and (3)) and the Social Security Act (42 U.S.C. 1306(a)). Any person who obtains this
          information under false pretenses or uses it for any purpose other than as provided for in these Terms of
          Service may be subject to criminal penalties.
        </li>
        <li>
          DHS does not charge transaction fees to use myE-Verify; however, myE-Verify participants may be subject to
          costs for equipment, internet services, electronic messaging, or other costs associated with accessing the
          internet or for access to myE-Verify. DHS does not reimburse these fees and costs, or any fees and costs
          related to participation in myE-Verify.
        </li>
        <li>
          DHS does not guarantee the availability or operability of myE-Verify or the identity assurance service. DHS is
          not liable to myE-Verify participants for their inability to use myE-Verify or perform any obligation by
          reason of natural disaster; lack of approved funding; a government shutdown; any failure or delay of any
          power, computer, or communications system; or any other cause that makes myE-verify unavailable.
        </li>
        <li>
          DHS assumes no responsibility regarding the accuracy of the information that is provided by myE Verify.
          Participants assume all risks of use and the consequences of using the information from the site and any
          affiliated site, whether accurate or inaccurate.
        </li>
        <li>
          If a myE-Verify participant causes a technical disruption of the site or the systems transmitting the site to
          the myE-Verify participant, or to others by intentional or grossly negligent acts, the myE-Verify participant
          shall be responsible for any and all civil and or criminal penalties, including but not limited to attorney’s
          fees, actual and consequential damages arising from the disruption of the sites, and all effort taken to
          correct and restore the site.
        </li>
        <li>
          In using myE-Verify, and any myE-Verify services later developed, the myE-Verify participant understands and
          agrees that:
          <ol type="a">
            <li>
              Access to myE-Verify is at DHS discretion and myE-Verify use is subject to continuous monitoring in
              accordance with DHS policy.
            </li>
            <li>
              Participants will not circumvent or attempt to circumvent any passwords, security countermeasures or
              safeguards, or alter any myE-Verify or affiliated web pages.
            </li>
            <li>
              Each myE-Verify account username and password will be unique and associated directly with a single “live”
              individual. In addition, a myE-Verify participant will only maintain one user account for which he or she
              is an authorized representative.
            </li>
            <li>
              The myE-Verify participant will take all reasonable measures to protect usernames and passwords, and
              immediately report to DHS the loss or compromise of any user name or password.
            </li>
            <li>
              The myE-Verify participant will not copy any user name or password, nor allow any user name or password to
              be used by any other person unless authorized by DHS.
            </li>
            <li>
              The myE-Verify participant will change or immediately request revocation of usernames or passwords if: (i)
              the myE-Verify access information is insecure in any way; or (ii) any of the information contained in the
              username or password has been changed by someone other than the account owner.
            </li>
            <li>
              The myE-Verify participant will not enter any other individual’s information into myE-Verify, except that
              a minor’s parent or legal guardian, or a representative from a nonprofit organization, association or
              rehabilitation program placing an individual into a position of employment may create a myE-Verify account
              and use Self Check on behalf of that minor or individual.
            </li>
            <li>
              Any device a participant uses to access myE-Verify will have appropriate security controls installed and
              activated, and the latest updates applied.
            </li>
            <li>
              The participant will promptly report to DHS any known or observed violations of or noncompliance with
              these Terms of Service including, but not limited to, observed IT security incidents, suspicions of
              security violations, or posting of inappropriate content.
            </li>
          </ol>
        </li>
      </ol>
      <h4>C. Prohibited Uses</h4>
      <p>Participants who acknowledge and accept these Terms of Service agree that they shall not:</p>
      <ol>
        <li>
          Access or use myE-Verify in violation of any federal law or regulation, executive order, these Terms of
          Service, or the current myE-Verify user manual(s) and agency guidance.
        </li>
        <li>
          Use myE-Verify to screen job applicants prior to hire, to support any unlawful employment practice, or for any
          other use not authorized by these Terms of Service, myE-Verify guidance or other agency guidance.
        </li>
        <li>
          Use myE-Verify in a discriminatory manner against any individual in employment actions (such as hiring,
          firing, or employment eligibility verification) because of his or her national origin or citizenship status,
          or other prohibited basis (such as race, color, disability, age, sex or religion). In particular:
          <ol type="a">
            <li>
              Use of myE-Verify in a manner that violates the anti-discrimination provision of the INA, 8 U.S.C. §1324b,
              may subject the myE-Verify participant to civil penalties, back pay awards to discrimination victims,
              suspension or termination from myE-Verify, and other sanctions.
            </li>
            <li>
              Use of myE-Verify in a manner that violates Title VII of the Civil Rights Act of 1964 may subject the
              participant to back pay awards to discrimination victims, compensatory and punitive damage awards,
              suspension or termination from participation in myE-Verify, and other sanctions.
              <p>
                Note: A participant who has any questions relating to these anti-discrimination provisions can contact
                the Department of Justice, Civil Rights Division’s Immigrant and Employee Rights Section at
                1-800-255-8155 or 1-800-237-2515 (TDD), or the Equal Employment Opportunity Commission at 1-800-669-4000
                or 1-800-669-6820 (TDD).
              </p>
            </li>
          </ol>
        </li>
        <li>
          Make any false or unauthorized claims about myE-Verify participation on its website, in advertising materials,
          or in other media.
        </li>
        <li>
          Use myE-Verify in any other manner that these Terms of Service or other myE-Verify or agency guidance do not
          authorize.
        </li>
      </ol>
      <h4>D. Accessibility</h4>
      <ol>
        <li>
          DHS recognizes the importance of being able to communicate effectively with individuals with limited English
          proficiency and individuals with disabilities. DHS is committed to providing individuals with disabilities
          access to our webpages. To meet this commitment, myE-Verify complies with the requirements of Section 508 of
          the Rehabilitation Act of 1973, as amended (29 U.S.C. § 794d). Read more on the DHS Accessibility policy at
          dhs.gov.
        </li>
      </ol>
      <h4>E. Trademarks and Logos</h4>
      <ol>
        <li>
          DHS is the trademark owner and authorized user of the myE-Verify name and logo. Participants may not use these
          trademarked items without the prior written consent of DHS.
        </li>
        <li>
          Nothing in these Terms of Service shall be interpreted as authorization to use the official seal, insignia or
          other visual identities of DHS, USCIS, E-Verify, myE-Verify, or any myE-Verify service (for example,
          myE-Verify Self Check). Participants shall not use the DHS, USCIS, E-Verify, myE-Verify or Self Check seal,
          insignia, or other visual identities in any manner to imply endorsement of any commercial product or activity
          by DHS, USCIS, E-Verify, myE-Verify or the United States government. Use of these seals, insignia or other
          visual identities without authorization violates federal law (for example, 18 U.S.C. §§ 506, 701, 1017). Use
          of myE-Verify or the receipt of information from it does not grant any licenses to copyrights, patents, any
          other intellectual property rights, or the rights to any of the materials on the site or sites or materials
          accessed by use of the site. You warrant that none of the information you provide to participate in or use myE
          Verify (including your e-mail address) infringes any copyright or the intellectual property rights of any
          third parties.
        </li>
      </ol>
      <h4>F. Compliance and Privacy</h4>
      <ol>
        <li>
          DHS is committed to maintaining the privacy of personally identifiable information in accordance with federal
          government standards. Descriptions of myE-Verify information privacy protections are available in the DHS
          myE-Verify System of Records Notice and Privacy Impact Assessment which can be found at dhs.gov/privacy.
        </li>
        <li>
          DHS reserves the right to conduct and the participant agrees to comply with monitoring, enforcement or
          compliance activity authorized by law, including site visits, to ensure proper participation in myE-Verify.
        </li>
        <li>
          Participation in myE-Verify is not confidential information and may be shared as authorized or required by law
          or policy. A complete list of routine uses for this information is available in the DHS myE-Verify System of
          Records Notice, which can be found at dhs.gov/privacy.
        </li>
      </ol>
      <h4>G. Modification and Assignment</h4>
      <ol>
        <li>
          These Terms of Service are effective upon the participant’s agreement and shall remain in effect for as long
          as DHS operates the myE-Verify program.
        </li>
        <li>
          Any and all myE-Verify system enhancements, including but not limited to myE-Verify checking additional data
          sources and instituting new verification policies or procedures, will be covered under these Terms of Service
          and will not cause the need for execution of a supplemental Terms of Service that outlines these changes. It
          is the myE-Verify participant’s responsibility to check periodically for any changes DHS may make to these
          Terms of Service. By continuing to access, use or rely on myE-Verify, participants agree to be bound by any
          such Terms of Service revisions or modifications without executing a supplemental Terms of Service.
        </li>
        <li>
          A participant may not sublicense, assign, or transfer all or any part of its rights or obligations under these
          Terms of Service. Any attempt to sublicense, assign, or transfer any of the rights, duties, or obligations
          under these Terms of Service without prior written agreement by DHS is void.
        </li>
        <li>
          If any provision of these Terms of Service is held illegal or unenforceable in a judicial proceeding, such
          provision must be severed and will be inoperative, and the remainder of these Terms of Service will remain
          operative and binding.
        </li>
      </ol>
      <h4>H. Termination and Suspension</h4>
      <ol>
        <li>
          We hope that you will continue using myE-Verify, but if you do not wish to participate or agree to our updated
          Terms of Service and no longer want to be a myE-Verify participant, you can delete your account at any time.
          If you delete or we disable your account, these Terms of Service will remain applicable.
        </li>
        <li>
          DHS may temporarily or permanently terminate, modify, suspend or discontinue these Terms of Service (or any
          provision of these Terms of Service) or a myE-Verify participant’s access to myE-Verify (or any part thereof)
          with or without notice at any time if DHS deems it necessary because of the requirements of law or policy, or
          because DHS determines there has been a breach of system integrity or security by the myE-Verify participant
          or any myE-Verify user, including unauthorized users, or a failure on the part of any party to comply with
          established myE-Verify procedures and/or legal requirements.
        </li>
        <li>
          The participant agrees that DHS will not be liable to the participant or to any third party for any
          termination of these Terms of Service or modification, suspension or discontinuance of the participant’s
          access to myE-Verify, and that DHS is not liable for any data or information lost because of discontinuance of
          service. Termination of participation in myE-Verify may include: (a) suspension, revocation or deletion of
          usernames, passwords and all related information, files and content associated with or inside an account (or
          any part thereof), with or without notice to the participant; and (b) barring further use of myE-Verify.
        </li>
      </ol>
      <h4>I. Jurisdiction</h4>
      <ol>
        <li>
          Nothing in these Terms of Service is intended, or should be construed, to create any right or benefit,
          substantive or procedural, enforceable at law by any third party against the United States, its agencies,
          officers, or employees, or against the participant, its agents, officers, or employees.
        </li>
        <li>
          The federal laws of the United States govern the terms, conditions and use of this site, and all myE-Verify
          participants and any other site users irrevocably consent to the exclusive jurisdiction of the federal laws of
          the United States for any action to enforce these terms, conditions, and use of this site. All conduct arising
          out of or relating to access and use shall be subject to the laws of the United States of America regardless
          of the participant’s or user’s citizenship, residency, or location at time of access. All claims arising out
          of or relating to myE-Verify participation and these Terms of Service shall be litigated exclusively in United
          States federal Courts and you consent to personal jurisdiction in those courts
        </li>
        <li>
          Each myE-Verify participant is individually liable and solely responsible for defending any civil, criminal,
          administrative, or contractual claim or action against them arising out of or related to these Terms of
          Service or their use of myE-Verify.
        </li>
        <li>
          myE-Verify is designed to comply with United States federal laws. myE-Verify should not be viewed, accessed or
          used if such activity is contrary to law. Viewers are responsible for knowing, understanding and complying
          with the laws of their specific jurisdiction.
        </li>
      </ol>
      <h4>Acknowledgement</h4>
      <p>
        These Terms of Service constitute the full agreement on this subject between DHS and the participant. This
        agreement does not affect any authority, right, responsibility or obligation DHS or the participant would have
        had, or will have, independent of this agreement, under applicable law.
      </p>
      <p>
        The participant understands that any inaccurate statement, representation, data or other information provided to
        DHS may subject the participant, its subcontractors, its employees, or its representatives to: (1) prosecution
        for false statements pursuant to 18 U.S.C. 1001 and/or; (2) immediate termination of this Terms of Service
        agreement.
      </p>
      <p>
        By accepting these Terms of Service, I represent that I am authorized to accept them and that I have read,
        understand, and agree to abide by them.
      </p>
    </div>
  );
};

export default TermsAndConditionsText;
