import React from 'react';
import { Accordion } from 'uswds-react';
import eadFront from '../../../assets/img/documents/help/employmentAuthorizationDocument/ead-front.png';
import eadBack from '../../../assets/img/documents/help/employmentAuthorizationDocument/ead-back.png';
import ImageWrapper from '../../ImageWrapper';
import HelpSectionContent from '../HelpSectionContent';

const baseClassName = 'i766-help-section';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

const I766HelpSection = (): JSX.Element => (
  <>
    <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
      Help with Employment Authorization Card (Form I-766)
    </Accordion.Heading>
    <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
      <p>
        The Employment Authorization Document (Form I-766) contains the bearer's name, photo, document/card name,
        alien/USCIS number, date of birth, laser-engraved fingerprint, and card expiration date.
      </p>
      <p>
        Document Number: The document number, also called a card number, is printed on the front and back of the card.
      </p>
      <p>
        Enter the document number exactly as it appears on the document. The document number is exactly 13 alphanumeric
        characters (letters and numbers). You must enter three letters followed by 10 numeric characters. Do not enter
        any special characters.
      </p>
      <p>Document Expiration Date: The expiration date is located at the front on the bottom of the card.</p>
      <p>Current EAD with “NOT VALID FOR REENTRY TO U.S.,” front and back</p>
      <ImageWrapper
        images={[
          { src: eadFront, alt: 'Example of the front of an employment authorization card' },
          { src: eadBack, alt: 'Example of the back of an employment authorization card' },
        ]}
      />
    </HelpSectionContent>
  </>
);

export default I766HelpSection;
