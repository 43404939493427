import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import type { ClassValue } from 'classnames/types';
import isEmpty from 'lodash/isEmpty';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Alert, FileInputControl, Form, TextInputControl } from 'uswds-react';
import { fromPairs } from 'lodash';
import { DocumentUploadFormFields, documentUploadFormErrorMessages, DocumentUploadFormFieldName } from './constants';
import { getFieldError, useOnBeforeUnload, ValidateRules } from '../../../utils/forms';
import paths from '../../../routing/paths';
import { getFiles, is4MegabytesOrUnder, isOver0Bytes } from '../../../utils/files';
import { injectURLParams } from '../../../utils/routing';
import ActionFooter from '../../ActionFooter/ActionFooter';
import NavigationPrompt from '../../Form/NavigationPrompt/NavigationPrompt';
import DocumentUploadModal from '../DocumentUploadModal/DocumentUploadModal';
import { UserContext } from '../../../context/User/UserContext';
import { formatPhoneNumber, initialPhoneNumberFormat } from '../../../utils/formatting';

const ACCEPT_FILE_TYPES = '.png,.jpeg,.jpg,.pdf';
export interface DocumentUploadFormMarkupProps {
  caseNumber: string;
  className?: ClassValue;
  requiredFileCount: 1 | 2;
  onSubmit: SubmitHandler<DocumentUploadSubmissionFields>;
}
export interface DocumentUploadSubmissionFields {
  files: File[];
  phoneNumber: string;
  emailAddress: string;
}

const baseClassName = 'document-upload-form';
const CLASS_NAMES = {
  base: classNames(baseClassName, 'maxw-none'),
  fileInput: classNames('maxw-none'),
  actionSection: classNames(`${baseClassName}__action-section`, 'margin-top-3'),
  secondaryButton: classNames(`${baseClassName}__secondary-button}`, 'usa-button', 'usa-button--outline', 'ver-button'),
};

const DocumentUploadFormMarkup = ({
  caseNumber,
  className = undefined,
  requiredFileCount,
  onSubmit,
}: DocumentUploadFormMarkupProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userInfoResponseData } = useContext(UserContext);
  const { control, errors, handleSubmit, register, watch } = useForm<DocumentUploadFormFields>({
    mode: 'onSubmit',
    defaultValues: {
      phoneNumber: userInfoResponseData.phoneNumber
        ? initialPhoneNumberFormat(parseInt(userInfoResponseData.phoneNumber, 10))
        : '',
    },
  });

  const errorMessages = fromPairs(
    Object.keys(documentUploadFormErrorMessages).map((key) => [
      key,
      getFieldError(
        errors[key as DocumentUploadFormFieldName],
        documentUploadFormErrorMessages[key as DocumentUploadFormFieldName],
      ),
    ]),
  );
  const handleButtonClick = handleSubmit((data) => {
    if (hasUserUploadedFiles && numberOfUserUploads === requiredFileCount) {
      setIsModalOpen(true);
    } else {
      // trigger validation because no uploads
      handleModalClose();
      const { firstDocument, secondDocument, phoneNumber, emailAddress } = {
        ...data,
        emailAddress: userInfoResponseData.emailAddress ? userInfoResponseData.emailAddress : '',
      };
      onSubmit({ files: getFiles({ firstDocument, secondDocument }), phoneNumber, emailAddress });
    }
  });

  const handleUploadConfirmationSubmit = handleSubmit((data) => {
    handleModalClose();
    const { firstDocument, secondDocument, phoneNumber, emailAddress } = {
      ...data,
      emailAddress: userInfoResponseData.emailAddress ? userInfoResponseData.emailAddress : '',
    };
    onSubmit({ files: getFiles({ firstDocument, secondDocument }), phoneNumber, emailAddress });
  });

  const fields = watch();

  const handleModalClose = (): void => {
    setIsModalOpen(false);
  };

  const hasUserUploadedFiles =
    fields[DocumentUploadFormFieldName.DOCUMENT_IMAGE1]?.length > 0 ||
    fields[DocumentUploadFormFieldName.DOCUMENT_IMAGE2]?.length > 0;
  const numberOfUserUploads =
    fields[DocumentUploadFormFieldName.DOCUMENT_IMAGE1]?.length +
    fields[DocumentUploadFormFieldName.DOCUMENT_IMAGE2]?.length;
  const isUserDataUnsaved = (): boolean => {
    return hasUserUploadedFiles;
  };

  useOnBeforeUnload(isUserDataUnsaved());

  const documentImage1ErrorMessage = errorMessages[DocumentUploadFormFieldName.DOCUMENT_IMAGE1];
  const documentImage2ErrorMessage = errorMessages[DocumentUploadFormFieldName.DOCUMENT_IMAGE2];
  const phoneErrorMessage = errorMessages[DocumentUploadFormFieldName.PHONE_NUMBER];

  const requiredValidator = (fileList: FileList): boolean => !isEmpty(fileList);
  const getFileInputValidateConfig = (fileNum = 1): ValidateRules => {
    const validate: ValidateRules = {
      maxSize: is4MegabytesOrUnder,
      minSize: isOver0Bytes,
    };
    if (fileNum <= requiredFileCount) {
      validate.required = requiredValidator;
    }
    return validate;
  };

  return (
    <>
      <DocumentUploadModal
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        onConfirm={handleUploadConfirmationSubmit}
      />
      <NavigationPrompt
        enabled={isUserDataUnsaved()}
        header="Exit Document Upload?"
        content="Document(s) selected for upload will be removed."
        proceedText="Remove Files"
      />
      <Form
        className={classNames(CLASS_NAMES.base, className)}
        onSubmit={handleUploadConfirmationSubmit}
        variant="large"
      >
        <FileInputControl
          id="document-upload-file-input-1"
          className={CLASS_NAMES.fileInput}
          name={DocumentUploadFormFieldName.DOCUMENT_IMAGE1}
          label="Select a document image to upload"
          accept={ACCEPT_FILE_TYPES}
          required
          errorMessage={documentImage1ErrorMessage}
          ref={register({ validate: getFileInputValidateConfig(1) })}
        />
        <FileInputControl
          id="document-upload-file-input-2"
          className={CLASS_NAMES.fileInput}
          name={DocumentUploadFormFieldName.DOCUMENT_IMAGE2}
          label="Select a second document image to upload"
          accept={ACCEPT_FILE_TYPES}
          errorMessage={documentImage2ErrorMessage}
          required={requiredFileCount > 1}
          ref={register({ validate: getFileInputValidateConfig(2) })}
        />
        <Alert className="margin-top-6" status="info" slim>
          If there are questions about the document(s) you upload, USCIS will contact you with the phone number and/or
          email address provided below:
        </Alert>
        <Controller
          control={control}
          name={DocumentUploadFormFieldName.PHONE_NUMBER}
          rules={{
            required: true,
            pattern: /^([2-9][0-8][0-9])-([2-9][0-9]{2})-([0-9]{4})$/,
            setValueAs: (value): number => {
              return parseInt(value.split('-').join(''), 10);
            },
          }}
          render={({ onChange, value }): JSX.Element => (
            <TextInputControl
              className="tablet:grid-col-3"
              id="document-upload-phone-input"
              name={DocumentUploadFormFieldName.PHONE_NUMBER}
              label="Phone Number"
              errorMessage={phoneErrorMessage}
              hint="###-###-####"
              required
              value={value}
              onChange={(event): void => onChange(formatPhoneNumber(event.target.value))}
            />
          )}
        />
        <h4>Email Address</h4>
        <p id={DocumentUploadFormFieldName.EMAIL_ADDRESS} className="display-inline-block margin-top-2px">
          {userInfoResponseData.emailAddress}
        </p>
      </Form>
      <ActionFooter
        className={CLASS_NAMES.actionSection}
        cancelButton={
          <Link to={injectURLParams(paths.caseDetail, { caseNumber })} className={CLASS_NAMES.secondaryButton}>
            Exit Document Upload
          </Link>
        }
        submitButtonVariant="submit"
        submitButtonText="Submit Case Files"
        onSubmit={handleButtonClick}
      />
    </>
  );
};

export default DocumentUploadFormMarkup;
