import React from 'react';
import ConfirmIdentity from '../components/ConfirmIdentity/ConfirmIdentity';
import PageContentLayout from '../layouts/PageContentLayout/PageContentLayout';

const ConfirmIdentityPage = (): JSX.Element => (
  <PageContentLayout title="Confirm Identity">
    <ConfirmIdentity />
  </PageContentLayout>
);

export default ConfirmIdentityPage;
