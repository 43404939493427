import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import type { ClassValue } from 'classnames/types';
import get from 'lodash/get';
import styled from 'styled-components';
import { Alert } from 'uswds-react';
import { Button, Tag } from '@ver-uds/react';
import paths from '../../routing/paths';
import { SelfLockState, SelfLockResponseData } from '../../services/SelfLockApi';
import NotificationAlert from '../../utils/NotificationAlert/NotificationAlert';
import { mapSelfLockStateToTagIcon, mapSelfLockStateToTagVariant } from './selfLockUtil';

const AboutSection = styled.div`
  > header {
    font-size: 2rem;
  }
`;
export interface SelfLockMarkupProps {
  className?: ClassValue;
  data?: SelfLockResponseData | null;
  handleUnlockButtonClick: () => void;
  notificationAlert?: NotificationAlert;
}

const baseClassName = 'self-lock';
export const CLASS_NAMES = {
  base: baseClassName,
  aboutSection: classNames(`${baseClassName}__about`, 'grid-col-9', 'margin-top-4'),
  actionSection: classNames(`${baseClassName}__action-section`, 'margin-top-2'),
  divider: classNames(`${baseClassName}__divider`, 'border-top-1px', 'border-base-lighter', 'margin-top-3'),
  header: `${baseClassName}__header`,
  notificationAlert: classNames(`${baseClassName}__notification-alert`, 'margin-bottom-3'),
  statusSection: classNames(`${baseClassName}__status-section`, 'margin-top-3'),
  statusTag: classNames(`${baseClassName}__status-tag`, 'display-inline-block', 'margin-top-1'),
};

const SelfLockMarkup = ({
  className = undefined,
  data = undefined,
  handleUnlockButtonClick,
  notificationAlert = undefined,
}: SelfLockMarkupProps): JSX.Element => {
  const state: SelfLockState = get(data, 'state', SelfLockState.UNKNOWN);

  useEffect(
    () => (): void => {
      if (notificationAlert instanceof NotificationAlert) {
        notificationAlert.markRendered();
      }
    },
    [notificationAlert],
  );

  let alert;
  if (notificationAlert instanceof NotificationAlert && notificationAlert.canRender()) {
    alert = (
      <div role="alert" aria-atomic="true" className={CLASS_NAMES.notificationAlert}>
        <Alert status={notificationAlert.status}>{notificationAlert.message}</Alert>
      </div>
    );
  }

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      {alert}
      <header className={CLASS_NAMES.header}>
        <h1>My SSN</h1>
      </header>
      <div className={CLASS_NAMES.statusSection}>
        <h4>Status</h4>
        <Tag
          className={CLASS_NAMES.statusTag}
          iconLeft={mapSelfLockStateToTagIcon(state)}
          variant={mapSelfLockStateToTagVariant(state)}
        >
          {state}
        </Tag>
      </div>
      <AboutSection className={CLASS_NAMES.aboutSection}>
        <h2>About Self Lock</h2>
        <p>
          Self Lock allows you to lock your Social Security number (SSN) and protect it against unauthorized use in
          E-Verify or Self Check.
        </p>
        <p>
          Your Self Lock remains active as long as your account remains valid and you have not unlocked your SSN. You
          can unlock your SSN anytime through your myE-Verify account.
        </p>
        <p>
          Once you lock your SSN, no one can use your SSN in E-Verify or Self Check—including you. For example, if you
          are currently employed and you start a new job with an E-Verify employer, they will not be able to access your
          information and you will receive a Self Lock DHS Tentative Nonconfirmation (Mismatch). If you receive a DHS
          TNC, you will receive instructions on how to resolve it.
        </p>
        <p>
          To avoid receiving a Self Lock DHS TNC, you can unlock your SSN before starting a new job with an E-Verify
          employer.
        </p>
      </AboutSection>
      <div className={CLASS_NAMES.divider} />
      <div className={CLASS_NAMES.actionSection}>
        {state === SelfLockState.UNLOCKED && (
          <Link className="usa-button ver-button" to={paths.selfLockLock}>
            Lock My SSN
          </Link>
        )}
        {state === SelfLockState.LOCKED && <Button onClick={handleUnlockButtonClick}>Unlock My SSN</Button>}
      </div>
    </div>
  );
};

export default SelfLockMarkup;
