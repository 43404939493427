import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';

export interface I9CentralFooterProps {
  className?: ClassValue;
}

const baseClassName = 'I-9-central-footer';
const CLASS_NAMES = {
  base: baseClassName,
};

const I9CentralFooter = ({ className = undefined }: I9CentralFooterProps): JSX.Element => (
  <p className={classNames(CLASS_NAMES.base, className)}>
    Visit <a href="https://www.uscis.gov/i-9-central">I-9 Central</a> for additional help or questions.
  </p>
);

export default I9CentralFooter;
