import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { ReadableCaseStatus } from '../../../../services';
import { SelfCheckCaseResponseData } from '../../../../services/SelfCheckApi';
import { resolveToCaseDetailHeadingText } from './util';

export interface CaseDetailHeadingProps {
  className?: ClassValue;
  caseStatus: ReadableCaseStatus;
  selfCheckCaseData?: SelfCheckCaseResponseData;
}

const baseClassName = 'case-detail-heading';
const CLASS_NAMES = {
  base: baseClassName,
};

const CaseDetailHeading = ({
  className = undefined,
  caseStatus,
  selfCheckCaseData = undefined,
}: CaseDetailHeadingProps): JSX.Element => (
  <h1 className={classNames(CLASS_NAMES.base, className)}>
    {resolveToCaseDetailHeadingText(caseStatus, selfCheckCaseData)}
  </h1>
);

export default CaseDetailHeading;
