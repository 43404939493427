import { Table } from '@ver-uds/react';
import React from 'react';
import { Accordion } from 'uswds-react';
import HelpSectionContent from '../HelpSectionContent';

const baseClassName = 'last-name-help-section';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

const LastNameHelpSection = (): JSX.Element => (
  <>
    <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
      Help with complex last names
    </Accordion.Heading>
    <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
      <ol>
        <li value="1">
          Hyphenated names and names with apostrophes are permitted. Enter the entire surname, including prefixes or
          name stems (excluding periods).
        </li>
      </ol>
      <Table
        id="entire-name-table"
        columns={[{ Header: 'Correct', accessor: 'col1' }]}
        data={[{ col1: "O'Donoghue" }, { col1: 'Lopez-Garcia' }, { col1: 'De La Cruz' }]}
      />
      <ol>
        <li value="2">Do not include suffixes. This includes Jr., Sr., III, etc.</li>
      </ol>
      <Table
        id="suffix-table"
        columns={[
          { Header: 'Correct', accessor: 'col1' },
          { Header: 'Not Correct', accessor: 'col2' },
        ]}
        data={[{ col1: 'Garcia', col2: 'Garcia, Sr.' }]}
      />
      <ol>
        <li value="3">Do not use periods for abbreviations.</li>
      </ol>
      <Table
        id="abbreviations-table"
        columns={[
          { Header: 'Correct', accessor: 'col1' },
          { Header: 'Not Correct', accessor: 'col2' },
        ]}
        data={[{ col1: 'St John', col2: 'St. John' }]}
      />
      <ol>
        <li value="4">
          Individuals from some cultures may write their surnames first and their given names last. Always enter the
          surname in the “Last Name” field.
        </li>
      </ol>
      <Table
        id="surname-table"
        columns={[
          { Header: 'Full Name', accessor: 'col1' },
          { Header: 'Correct', accessor: 'col2' },
          { Header: 'Not Correct', accessor: 'col3' },
        ]}
        data={[{ col1: 'Nguyen Mai', col2: 'Nguyen', col3: 'Mai' }]}
      />
    </HelpSectionContent>
  </>
);

export default LastNameHelpSection;
