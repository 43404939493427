import { useContext, useMemo } from 'react';
import { QueryObserverResult, UseMutationResult, useQuery } from 'react-query';
import { AuthenticationContext } from '../context/Authentication/AuthenticationContext';
import { normalizeURL } from '../utils/routing';
import BaseApi, { useConfiguration } from './BaseApi';
import paths from './paths.json';
import { apiMutation, doGet, doPatch, doPost } from './util';

export const emailAddressFieldName = 'emailAddress';
export const phoneNumberFieldName = 'phoneNumber';
export const ssnFieldName = 'ssn';
export const dobFieldName = 'dob';
export const firstNameFieldName = 'firstName';
export const middleNameFieldName = 'middleName';
export const lastNameFieldName = 'lastName';
export const hasAcceptedTermsAndConditionsFieldName = 'hasAcceptedTermsAndConditions';
export const idProofingAttempts = 'idProofingAttempts';
export const idProofingLastAttempt = 'idProofingLastAttempt';

export interface UserInfoResponseData {
  [emailAddressFieldName]?: string;
  [phoneNumberFieldName]?: string;
  [ssnFieldName]: string;
  [dobFieldName]: string; // expect ISO date string format: yyyy-MM-dd
  [firstNameFieldName]: string;
  [middleNameFieldName]?: string;
  [lastNameFieldName]: string;
  [hasAcceptedTermsAndConditionsFieldName]: boolean;
  [idProofingAttempts]: number;
  [idProofingLastAttempt]: string;
  hasCompletedIdProofing: boolean;
  hashKeyExists: boolean;
}

export interface UserInfoResponse {
  data: UserInfoResponseData;
}

export interface UserInfoUpdateRequest {
  [ssnFieldName]?: string;
  [dobFieldName]?: string;
  [hasAcceptedTermsAndConditionsFieldName]?: boolean;
}

export enum UserInfoUpdateRequestFieldName {
  SSN = 'ssn',
  DOB = 'dob',
}

export enum CreateUserQuizRequestFieldName {
  ADDRESS_LINE1 = 'addressLine1',
  ADDRESS_LINE2 = 'addressLine2',
  CITY = 'city',
  DOB = 'dob',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  MIDDLE_INITIAL = 'middleInitial',
  PHONE_NUMBER = 'phoneNumber',
  SSN = 'ssn',
  STATE = 'stateCode',
  ZIP_CODE = 'zipCode',
}

export interface CreateUserQuizRequest {
  [CreateUserQuizRequestFieldName.ADDRESS_LINE1]: string;
  [CreateUserQuizRequestFieldName.ADDRESS_LINE2]?: string;
  [CreateUserQuizRequestFieldName.CITY]: string;
  [CreateUserQuizRequestFieldName.DOB]: string;
  [CreateUserQuizRequestFieldName.FIRST_NAME]: string;
  [CreateUserQuizRequestFieldName.LAST_NAME]: string;
  [CreateUserQuizRequestFieldName.MIDDLE_INITIAL]?: string;
  [CreateUserQuizRequestFieldName.PHONE_NUMBER]: string;
  [CreateUserQuizRequestFieldName.SSN]: string;
  [CreateUserQuizRequestFieldName.STATE]: string;
  [CreateUserQuizRequestFieldName.ZIP_CODE]: string;
}

export enum UserQuizAnswersRequestFieldName {
  ANSWER_ID = 'answerId',
  QUESTION_ID = 'questionId',
}

export interface UserQuizAnswersRequest {
  answers: UserQuizAnswersRequestData[];
}

export interface UserQuizAnswersRequestData {
  [UserQuizAnswersRequestFieldName.QUESTION_ID]: string;
  [UserQuizAnswersRequestFieldName.ANSWER_ID]: string;
}

export interface UserQuizAnswerOptions {
  answerId: string;
  answerText: string;
}

export interface UserQuizQuestions {
  answers: UserQuizAnswerOptions[];
  questionId: string;
  questionText: string;
}

export interface UserQuizResponseData {
  questions: UserQuizQuestions[];
}

export interface UserQuizResponse {
  data: UserQuizResponseData;
}

class UserApi extends BaseApi {
  async getUserInfo(): Promise<UserInfoResponseData | undefined> {
    const endpoint = normalizeURL(`${this.configuration.basePath}/${paths.user.getUserInfo}`);

    return doGet<UserInfoResponse>(endpoint);
  }

  async patchUserInfo(data: UserInfoUpdateRequest): Promise<UserInfoResponseData> {
    const endpoint = normalizeURL(`${this.configuration.basePath}/${paths.user.patchUserInfo}`);
    return doPatch<UserInfoUpdateRequest, UserInfoResponse>(endpoint, data);
  }

  async postUserQuiz(data: CreateUserQuizRequest): Promise<UserQuizResponseData> {
    const endpoint = normalizeURL(`${this.configuration.basePath}/${paths.user.postUserQuiz}`);
    return doPost<CreateUserQuizRequest, UserQuizResponse>(endpoint, data);
  }

  async userSubmitQuizAnswers(data: UserQuizAnswersRequest): Promise<UserInfoResponseData> {
    const endpoint = normalizeURL(`${this.configuration.basePath}/${paths.user.userSubmitQuizAnswers}`);
    return doPost<UserQuizAnswersRequest, UserInfoResponse>(endpoint, data);
  }
}

const useUserApi = (options: { authRefresh: boolean } = { authRefresh: true }): UserApi => {
  const configuration = useConfiguration();
  const authContext = useContext(AuthenticationContext);
  if (options.authRefresh) {
    authContext.refreshLastAuthenticatedTime();
  }
  return useMemo(() => new UserApi(configuration), [configuration]);
};

const useUserMutation = apiMutation(useUserApi);

export const getUserInfoQueryID = 'getUserInfo';
export const useGetUserInfo = (options?: {
  disableAuthRefresh?: boolean;
  disabled?: boolean;
}): QueryObserverResult<UserInfoResponseData> => {
  const api = useUserApi({ authRefresh: !options?.disableAuthRefresh });

  return useQuery(getUserInfoQueryID, (_key) => api.getUserInfo(), {
    enabled: api.isConfigurationValid() && !options?.disabled,
  });
};

export const patchUserInfoQueryKey = 'patchUserInfo';
export const usePatchUserInfo = (): UseMutationResult<
  UserInfoResponseData,
  Error,
  UserInfoUpdateRequest,
  UserInfoResponseData
> => {
  const api = useUserApi();
  return useUserMutation<UserInfoUpdateRequest, UserInfoResponseData>(patchUserInfoQueryKey, (payload) =>
    api.patchUserInfo(payload),
  );
};

export const postUserQuizQueryKey = 'postUserQuiz';
export const usePostUserQuiz = (options?: {
  onSuccess: (resp: UserQuizResponseData) => void;
  onError: (error: Error) => void;
}): UseMutationResult<UserQuizResponseData, Error, CreateUserQuizRequest, UserQuizResponseData> => {
  const api = useUserApi();
  return useUserMutation<CreateUserQuizRequest, UserQuizResponseData>(
    postUserQuizQueryKey,
    (payload) => api.postUserQuiz(payload),
    {
      onSuccess: (resp: UserQuizResponseData): void => options?.onSuccess(resp),
      onError: (error: Error): void => options?.onError(error),
    },
  );
};

export const userSubmitQuizAnswersQueryKey = 'userSubmitQuizAnswers';
export const useUserSubmitQuizAnswers = (options?: {
  onError: (error: Error) => void;
}): UseMutationResult<UserInfoResponseData, Error, UserQuizAnswersRequest, UserInfoResponseData> => {
  const api = useUserApi();
  return useUserMutation<UserQuizAnswersRequest, UserInfoResponseData>(
    userSubmitQuizAnswersQueryKey,
    (payload) => api.userSubmitQuizAnswers(payload),
    {
      onError: (error: Error): void => options?.onError(error),
    },
  );
};
