import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';

export interface GenericUploadSuccessModalContentProps {
  className?: ClassValue;
}

const baseClassName = 'upload-success-modal-content--generic';
const CLASS_NAMES = {
  base: baseClassName,
};

const GenericUploadSuccessModalContent = ({
  className = undefined,
}: GenericUploadSuccessModalContentProps): JSX.Element => (
  <p className={classNames(CLASS_NAMES.base, className)}>
    We received your documents. <strong>The U.S. Department of Homeland Security (DHS) will review</strong> your
    submitted case documents and update the case status. Please allow 3 business days for our team to review your case.
    You may use Case Tracker to check the status of your case or follow up with your employer.
  </p>
);

export default GenericUploadSuccessModalContent;
