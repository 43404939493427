import classNames from 'classnames';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage, Fieldset, Form, FormGroup, Legend, RadioButton } from 'uswds-react';
import {
  UserQuizAnswersRequestData,
  UserQuizAnswersRequestFieldName,
  UserQuizQuestions,
  UserQuizResponseData,
} from '../../../services/UserApi';
import ActionFooter from '../../ActionFooter/ActionFooter';
import Divider from '../../Divider/Divider';

export interface VerifyIdentityFormProps {
  quiz?: UserQuizResponseData;
  onSubmit: (data: UserQuizAnswersRequestData[]) => void;
}

const baseClassName = 'verify-identity-form';
export const CLASS_NAMES = {
  base: baseClassName,
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
  divider: classNames(`${baseClassName}__divider`, 'margin-bottom-3', 'margin-top-3'),
  formGroupLabelRequiredText: classNames(
    `${baseClassName}__form-group-label-required-text`,
    'usa-hint',
    'margin-left-05',
  ),
  form: classNames(`${baseClassName}__form`, 'maxw-none'),
  legend: classNames('usa-label', 'usa-legend', `${baseClassName}__legend`, 'text-normal', 'maxw-none'),
};

export const QUESTION_ANSWER_MISSING_ERROR_MSG = 'Please select an answer.';
const initialAnswersArray: UserQuizAnswersRequestData[] = [];

const VerifyIdentityForm = ({ quiz = undefined, onSubmit }: VerifyIdentityFormProps): JSX.Element => {
  const [answers, setAnswers] = useState<UserQuizAnswersRequestData[]>(initialAnswersArray);
  const { errors, handleSubmit, register } = useForm<UserQuizQuestions[]>({
    mode: 'onSubmit',
  });

  if (!quiz) {
    return <></>;
  }

  const handleFormSubmit = handleSubmit(() => {
    onSubmit(answers);
  });

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // Building initial payload array. Answers must be in order
    if (answers.length === 0) {
      quiz.questions.forEach((question) => {
        answers.push({
          questionId: question.questionId,
          answerId: '',
        });
      });
      setAnswers(answers);
    }
    const questionId = event.target.name;
    const answerId = event.target.value;

    answers.forEach((answer, index) => {
      if (answer[UserQuizAnswersRequestFieldName.QUESTION_ID] === questionId) {
        answers[index] = {
          questionId,
          answerId,
        };
        setAnswers(answers);
      }
    });
  };

  return (
    <>
      <div className="grid-col-9">
        <Form className={CLASS_NAMES.form} onSubmit={handleFormSubmit}>
          {quiz.questions.map((question, index) => (
            <React.Fragment key={question.questionId}>
              {index !== 0 && <Divider className={CLASS_NAMES.divider} />}{' '}
              <FormGroup>
                <Fieldset>
                  <Legend className={CLASS_NAMES.legend}>
                    <b>Select an answer</b>
                    <span className={CLASS_NAMES.formGroupLabelRequiredText} aria-hidden="true">
                      (required)
                    </span>
                    <p>{question.questionText}</p>
                  </Legend>
                  {errors[parseInt(question.questionId, 0)] && (
                    <ErrorMessage id="radio-error">{QUESTION_ANSWER_MISSING_ERROR_MSG}</ErrorMessage>
                  )}
                  {question.answers.map((answer) => (
                    <RadioButton
                      key={`${question.questionId}-${answer.answerId}`}
                      value={answer.answerId}
                      id={`${question.questionId}--${answer.answerId}`}
                      name={question.questionId}
                      label={answer.answerText}
                      ref={register({
                        required: true,
                      })}
                      aria-required="true"
                      onChange={handleOnChange}
                    />
                  ))}
                </Fieldset>
              </FormGroup>
            </React.Fragment>
          ))}
        </Form>
      </div>
      <ActionFooter
        className={CLASS_NAMES.actionFooter}
        onSubmit={handleFormSubmit}
        submitButtonText="Submit"
        hideCancelButton
      />
    </>
  );
};

export default VerifyIdentityForm;
