import React, { useMemo } from 'react';
import { Authentication, createAuthentication } from '../../services/Authentication';
import { useEnvironment } from '../Environment/EnvironmentContext';

export const DEFAULT_STATE: Authentication = createAuthentication({});

export const AuthenticationContext = React.createContext<Authentication>(DEFAULT_STATE);

export type AuthenticationProviderProps = {
  children: React.ReactNode;
};

export const AuthenticationProvider = ({ children }: AuthenticationProviderProps): JSX.Element => {
  const { environmentVariables } = useEnvironment();
  const auth = useMemo(
    () =>
      createAuthentication({
        authCookieName: environmentVariables?.MYEVERIFY_WEB_SERVICE_AUTH_COOKIE,
        logoutURL: environmentVariables?.MYEVERIFY_WEB_SERVICE_LOGOUT,
      }),
    [environmentVariables],
  );
  return <AuthenticationContext.Provider value={auth}>{children}</AuthenticationContext.Provider>;
};
