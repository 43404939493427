import React, { ReactElement, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { hasAcceptedTermsAndConditionsFieldName } from '../services/UserApi';
import paths from './paths';
import { CreateAccountFormPage } from '../components/CreateAccount/constants';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import { UserContext } from '../context/User/UserContext';

const LoginRouter = (): JSX.Element => {
  const { userInfoResponseData } = useContext(UserContext);

  if (userInfoResponseData.isLoading) {
    return <LoadingSpinner />;
  }

  const createAccountRedirectHelper = (createAccountFormPage: CreateAccountFormPage): ReactElement => {
    return (
      <Redirect
        to={{
          pathname: paths.createAccount,
          state: { createAccountFormPage },
        }}
      />
    );
  };

  if (!userInfoResponseData.isLoading && !userInfoResponseData?.hasCompletedIdProofing) {
    if (!userInfoResponseData?.[hasAcceptedTermsAndConditionsFieldName]) {
      return createAccountRedirectHelper(CreateAccountFormPage.POLICY_AGREEMENTS);
    }
    if (userInfoResponseData.hasExceededAttempts) {
      return createAccountRedirectHelper(CreateAccountFormPage.ATTEMPTS_EXCEEDED);
    }
    return createAccountRedirectHelper(CreateAccountFormPage.PERSONAL_INFORMATION);
  }
  if (!userInfoResponseData.isLoading && userInfoResponseData.isPivotRecordNull) {
    if (userInfoResponseData?.hashKeyExists) {
      return <Redirect to={paths.confirmIdentity} />;
    }
    return createAccountRedirectHelper(CreateAccountFormPage.POLICY_AGREEMENTS);
  }
  return <></>;
};

export default LoginRouter;
