import React from 'react';
import { SelfCheckForm, SelfCheckFormConsumer } from '../../../context/Form/SelfCheck/SelfCheckFormContext';

const withSelfCheckFormConsumer = (
  Component: (props: SelfCheckForm) => JSX.Element,
): (() => JSX.Element) => (): JSX.Element => (
  <SelfCheckFormConsumer>
    {(props: SelfCheckForm): JSX.Element => (
      <Component {...props} /> /* eslint-disable-line react/jsx-props-no-spreading */
    )}
  </SelfCheckFormConsumer>
);

export default withSelfCheckFormConsumer;
