import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import get from 'lodash/get';
import paths from '../../routing/paths';
import { useCaseTncTypeQuery } from '../../services/CaseApi';
import DocumentUploadMarkup from './DocumentUploadMarkup';

const DocumentUpload = (): JSX.Element => {
  const urlParams = useParams();
  const caseNumber = get(urlParams, 'caseNumber', null);
  const {
    data: caseTncTypeData,
    isError,
    isIdle: isCaseTncTypeIdle,
    isLoading: isCaseTncTypeLoading,
    isSuccess,
  } = useCaseTncTypeQuery(caseNumber);

  if (isError || (isSuccess && !get(caseTncTypeData, 'uploadDocumentType'))) {
    return <Redirect to={paths.error} />;
  }

  return (
    <>
      {!isCaseTncTypeIdle && !isCaseTncTypeLoading && caseTncTypeData?.uploadDocumentType && (
        <DocumentUploadMarkup caseNumber={caseNumber} uploadDocumentType={caseTncTypeData.uploadDocumentType} />
      )}
    </>
  );
};

export default DocumentUpload;
