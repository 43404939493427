import get from 'lodash/get';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import { requestedRouteKey } from '../constants';
import { useEnvironment } from '../context/Environment/EnvironmentContext';
import { redirectTo } from '../utils/routing';
import { setLocalStorageItem } from '../utils/storage';
import paths from './paths';
import { CustomRouteProps } from './routes';

interface PrivateRouteProps extends CustomRouteProps {
  // eslint-disable-next-line react/require-default-props
  hasCompletedIdProofing?: boolean;
  isPublic: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
}

const PrivateRoute = ({
  path,
  exact,
  hasCompletedIdProofing = undefined,
  isPublic,
  isAuthenticated,
  isLoading,
  children,
}: PrivateRouteProps): JSX.Element => {
  const { isLoading: isEnvironmentLoading, environmentVariables } = useEnvironment();

  if (isLoading || isEnvironmentLoading) {
    return <LoadingSpinner />;
  }

  if (path === paths.createAccount && hasCompletedIdProofing) {
    return <Redirect to={paths.home} />;
  }

  const requestedRoute = localStorage.getItem(requestedRouteKey);
  if (requestedRoute) {
    localStorage.removeItem(requestedRouteKey);
    if (isAuthenticated) {
      return <Redirect exact strict from={paths.home} to={requestedRoute} />;
    }
  }

  if (isPublic || isAuthenticated) {
    return (
      <Route path={path} exact={exact}>
        {children}
      </Route>
    );
  }

  const loginRoute = get(environmentVariables, 'MYEVERIFY_WEB_SERVICE_LOGIN', paths.home);
  if (requestedRouteKey && loginRoute !== paths.home) {
    setLocalStorageItem(requestedRouteKey, path);
  }

  redirectTo(loginRoute);

  return <></>;
};

export default PrivateRoute;
