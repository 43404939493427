import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import UploadDocumentInfoSection from '../../../../../../DocumentUpload/UploadDocumentInfoSection';
import { CaseTncTypeData } from '../../../../../../../services';
import CaseUploadActionFooter from './CaseUploadActionFooter';
import CaseUploadDocumentHelpSection from './CaseUploadDocumentHelpSection';

export interface CaseUploadCaseDetailActionSectionComponentProps {
  className?: ClassValue;
  caseNumber: string;
  caseTncTypeData: CaseTncTypeData;
}

const baseClassName = 'case-detail-action-section--self-check';
const CLASS_NAMES = {
  base: baseClassName,
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-3'),
  helpSection: classNames(`${baseClassName}__help-section`, 'margin-top-6'),
};

const CaseUploadCaseDetailActionSection = ({
  className = undefined,
  caseNumber,
  caseTncTypeData,
}: CaseUploadCaseDetailActionSectionComponentProps): JSX.Element => (
  <div className={classNames(CLASS_NAMES.base, className)}>
    <UploadDocumentInfoSection uploadDocumentType={caseTncTypeData.uploadDocumentType} />
    <CaseUploadActionFooter
      className={CLASS_NAMES.actionFooter}
      caseNumber={caseNumber}
      tncType={caseTncTypeData.tncType}
    />
    <CaseUploadDocumentHelpSection
      className={CLASS_NAMES.helpSection}
      uploadDocumentType={caseTncTypeData.uploadDocumentType}
    />
  </div>
);

export default CaseUploadCaseDetailActionSection;
