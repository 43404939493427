import React from 'react';
import PageContentLayout from '../layouts/PageContentLayout/PageContentLayout';
import DocumentUpload from '../components/DocumentUpload/DocumentUpload';

const DocumentUploadPage = (): JSX.Element => (
  <PageContentLayout title="Case Detail | Document Upload">
    <DocumentUpload />
  </PageContentLayout>
);

export default DocumentUploadPage;
