import React from 'react';
import PageContentLayout from '../layouts/PageContentLayout/PageContentLayout';
import SelfCheck from '../components/SelfCheck/SelfCheck';

const SelfCheckPage = (): JSX.Element => (
  <PageContentLayout title="Self Check">
    <SelfCheck />
  </PageContentLayout>
);

export default SelfCheckPage;
