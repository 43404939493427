import { ReadableCaseStatus } from '../../../services/CaseApi';

/* eslint-disable import/prefer-default-export */
export const mapReadableCaseStatusToCaseHistoryTableStatusText = (readableCaseStatus: ReadableCaseStatus): string => {
  switch (readableCaseStatus) {
    case ReadableCaseStatus.CASE_INCOMPLETE:
      return 'Case Incomplete';
    case ReadableCaseStatus.CLOSE_AND_RESUBMIT:
      return 'Close and Resubmit';
    case ReadableCaseStatus.CLOSED:
      return 'Closed';
    case ReadableCaseStatus.EMPLOYMENT_AUTHORIZED:
      return 'Employment Authorized';
    case ReadableCaseStatus.CHECK_BACK_LATER:
      return 'In Review';
    case ReadableCaseStatus.FINAL_NONCONFIRMATION:
      return 'Final Nonconfirmation';
    case ReadableCaseStatus.EMPLOYEE_REFERRED_DHS:
    case ReadableCaseStatus.EMPLOYEE_REFERRED_SSA:
    case ReadableCaseStatus.EMPLOYEE_REFERRED_DHS_AND_SSA:
    case ReadableCaseStatus.EMPLOYEE_REFERRED_DHS_OR_SSA:
    case ReadableCaseStatus.EMPLOYEE_REFERRED_CONTINUANCE:
      return 'Referred';
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS:
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_SSA:
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_AND_SSA:
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_OR_SSA:
      return 'Tentative Nonconfirmation (Mismatch)';
    case ReadableCaseStatus.CONTINUANCE:
      return 'Pending';
    case ReadableCaseStatus.UNKNOWN:
    default:
      return 'Unknown';
  }
};
