import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { SelfCheckCaseDetailVariant } from '../constants';
import { CaseTncTypeData, ReadableCaseStatus } from '../../../../../services/CaseApi';
import CaseUploadActionSection from './variants/CaseUploadCaseDetailActionSection';
import GenericCaseDetailActionSection from './variants/GenericCaseDetailActionSection';
import SelfCheckCaseDetailActionSection from './variants/SelfCheckCaseDetailActionSection';
import { isCaseUploadAccessAllowed } from './util';

export interface CaseDetailActionSectionMarkupProps {
  className?: ClassValue;
  caseNumber: string;
  caseStatus: ReadableCaseStatus;
  caseTncTypeData?: CaseTncTypeData;
  selfCheckCaseDetailVariant?: SelfCheckCaseDetailVariant;
}

const baseClassName = 'case-detail-action-section';
const CLASS_NAMES = {
  base: baseClassName,
};

const CaseDetailActionSectionMarkup = ({
  className = undefined,
  caseNumber,
  caseStatus,
  caseTncTypeData = undefined,
  selfCheckCaseDetailVariant = undefined,
}: CaseDetailActionSectionMarkupProps): JSX.Element => (
  <div className={classNames(CLASS_NAMES.base, className)}>
    {caseTncTypeData && isCaseUploadAccessAllowed(caseStatus, selfCheckCaseDetailVariant) ? (
      <CaseUploadActionSection caseNumber={caseNumber} caseTncTypeData={caseTncTypeData} />
    ) : (
      <>
        {selfCheckCaseDetailVariant ? (
          <SelfCheckCaseDetailActionSection
            caseNumber={caseNumber}
            selfCheckCaseDetailVariant={selfCheckCaseDetailVariant}
          />
        ) : (
          <GenericCaseDetailActionSection />
        )}
      </>
    )}
  </div>
);

export default CaseDetailActionSectionMarkup;
