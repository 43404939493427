import { UploadDocumentType } from '../../../services/CaseApi';

/* eslint-disable import/prefer-default-export */
export const getRequiredFileCount = (uploadDocumentType: UploadDocumentType): 1 | 2 => {
  switch (uploadDocumentType) {
    case UploadDocumentType.DRIVERS_LICENSE:
      return 2;
    case UploadDocumentType.US_PASSPORT:
    case UploadDocumentType.NATZ:
    case UploadDocumentType.UNKNOWN:
    default:
      return 1;
  }
};
