import React, { useContext, useRef } from 'react';
import fromPairs from 'lodash/fromPairs';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { Form, TextInputControl } from 'uswds-react';
import { UserInfoUpdateRequest, UserInfoUpdateRequestFieldName } from '../../../services/UserApi';
import FormattedDateInput from '../../Form/FormattedDateInput/FormattedDateInput';
import { isBefore18YearsAgo, isValidDate } from '../../../utils/dates';
import {
  personalInformationFormErrorMessages,
  personalInformationFormLabels,
} from '../../CreateAccount/PersonalInformation/constants';
import { getFieldError } from '../../../utils/forms';
import ActionFooter from '../../ActionFooter/ActionFooter';
import type { ConfirmIdentityFormData } from '../ConfirmIdentity';
import { AuthenticationContext } from '../../../context/Authentication/AuthenticationContext';
import { formatSsn } from '../../../utils/formatting';

export interface ConfirmIdentityFormProps {
  className?: ClassValue;
  onSubmit: SubmitHandler<UserInfoUpdateRequest>;
  prevEnteredData?: ConfirmIdentityFormData;
}

const baseClassName = 'confirm-information-form';
export const CLASS_NAMES = {
  base: baseClassName,
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
};

const ConfirmIdentityForm = ({
  className = undefined,
  onSubmit,
  prevEnteredData = undefined,
}: ConfirmIdentityFormProps): JSX.Element => {
  const { control, errors, handleSubmit } = useForm<UserInfoUpdateRequest>({
    mode: 'onSubmit',
    defaultValues: {
      dob: prevEnteredData?.[UserInfoUpdateRequestFieldName.DOB],
      ssn: prevEnteredData?.[UserInfoUpdateRequestFieldName.SSN] || '',
    },
  });

  const authContext = useContext(AuthenticationContext);

  const handleFormSubmit = handleSubmit((data) => {
    onSubmit(data);
  });

  const handleFormCancel = (): void => {
    authContext.logout();
  };

  const errorMessages = fromPairs(
    Object.keys(personalInformationFormErrorMessages).map((key) => [
      key,
      getFieldError(
        errors[key as UserInfoUpdateRequestFieldName],
        personalInformationFormErrorMessages[key as UserInfoUpdateRequestFieldName],
      ),
    ]),
  );

  const dateInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Form className={classNames(baseClassName, CLASS_NAMES.base, className)} variant="large">
        <Controller
          control={control}
          name={UserInfoUpdateRequestFieldName.DOB}
          rules={{
            required: true,
            validate: {
              validDate: isValidDate,
              over18: isBefore18YearsAgo,
            },
          }}
          // Focus order is not respected. This is fixed in react-hook-form version 7
          onFocus={(): void => {
            if (dateInputRef?.current) {
              dateInputRef.current.focus();
            }
          }}
          render={({ onChange, value }): JSX.Element => (
            <FormattedDateInput
              className="margin-top-3"
              id={UserInfoUpdateRequestFieldName.DOB}
              name={UserInfoUpdateRequestFieldName.DOB}
              label={personalInformationFormLabels[UserInfoUpdateRequestFieldName.DOB]}
              errorMessage={errorMessages[UserInfoUpdateRequestFieldName.DOB]}
              required
              monthRef={dateInputRef}
              formattedDate={value}
              onUpdate={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name={UserInfoUpdateRequestFieldName.SSN}
          rules={{
            required: true,
            pattern: /^\d{3}-\d{2}-\d{4}$/,
          }}
          render={({ onChange, value }): JSX.Element => (
            <TextInputControl
              className="tablet:grid-col-6"
              id={UserInfoUpdateRequestFieldName.SSN}
              name={UserInfoUpdateRequestFieldName.SSN}
              label={personalInformationFormLabels[UserInfoUpdateRequestFieldName.SSN]}
              errorMessage={errorMessages[UserInfoUpdateRequestFieldName.SSN]}
              hint="###-##-####"
              required
              value={value}
              onChange={(event): void => onChange(formatSsn(event.target.value))}
            />
          )}
        />
      </Form>
      <ActionFooter
        className={CLASS_NAMES.actionFooter}
        submitButtonText="Submit & take me to myE-Verify"
        submitButtonVariant="submit"
        onSubmit={handleFormSubmit}
        onCancel={handleFormCancel}
      />
    </>
  );
};

export default ConfirmIdentityForm;
