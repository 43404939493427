import React, { ComponentPropsWithoutRef, useContext } from 'react';
import classNames from 'classnames';
import { Button } from '@ver-uds/react';
import { AuthenticationContext } from '../../../context/Authentication/AuthenticationContext';

export interface LogOutButtonProps {
  variant?: ComponentPropsWithoutRef<typeof Button>['variant'];
}

const baseClassName = 'log-out-button';
const CLASS_NAMES = {
  base: classNames('usa-button', baseClassName),
};

const LogOutButton = ({ variant = 'primary' }: LogOutButtonProps): JSX.Element => {
  const authentication = useContext(AuthenticationContext);
  return (
    <Button className={CLASS_NAMES.base} onClick={authentication.logout} variant={variant}>
      Sign Out
    </Button>
  );
};

export default LogOutButton;
