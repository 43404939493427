import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import get from 'lodash/get';
import { TextInputControl } from 'uswds-react';
import { DocumentNumberNCAWTextInputControlProps, Errors, SetValueFn } from '../constants';
import { getFieldError, RegisterFn, REQUIRED_LABEL_HINT } from '../../../../../utils/forms';
import CountrySelect from '../../../../CountrySelect/CountrySelect';
import SelfCheckFormDocumentSecondarySelectionMarkup from './SelfCheckFormDocumentSecondarySelection/SelfCheckFormDocumentSecondarySelectionMarkup';
import {
  countryOfIssuanceFieldName,
  fieldDefinitions,
  SelfCheckFormDocumentSelectionFormData,
  supportingDocumentTypeFieldName,
} from '../../constants';

export const DISABLED_DOCUMENT_NUMBER_INPUT_NAME = 'document-number-input--disabled';

export interface SelfCheckFormDocumentSelectionNCAWSectionProps {
  className?: ClassValue;
  data?: Partial<SelfCheckFormDocumentSelectionFormData>;
  documentNumberInputProps?: DocumentNumberNCAWTextInputControlProps;
  documentNumberInputDisabled?: boolean;
  countrySelectDisabled?: boolean;
  errors?: Errors;
  register: RegisterFn;
  setValue: SetValueFn;
}

const baseClassName = 'self-check-document-selection__ncaw-section';
const CLASS_NAMES = {
  base: baseClassName,
  formGroup: classNames(`${baseClassName}__form-group`, 'margin-top-2'),
  documentNumberInputControl: `${baseClassName}__document-number-input-control`,
  documentNumberInputControlError: `${baseClassName}__document-number-input-control-error`,
  countryOfIssuanceSelect: `${baseClassName}__foreign-passport-country-of-issuance`,
};

const SelfCheckFormDocumentSelectionNCAWSection = ({
  className = undefined,
  countrySelectDisabled = undefined,
  data = undefined,
  documentNumberInputProps = undefined,
  documentNumberInputDisabled = undefined,
  errors = undefined,
  register,
  setValue,
}: SelfCheckFormDocumentSelectionNCAWSectionProps): JSX.Element => {
  const supportingDocumentError = getFieldError(
    get(errors, supportingDocumentTypeFieldName),
    fieldDefinitions[supportingDocumentTypeFieldName].errorMessages,
  );

  const documentNumberInputName = get(documentNumberInputProps, 'name', DISABLED_DOCUMENT_NUMBER_INPUT_NAME);

  const documentNumberInputError = getFieldError(
    get(errors, documentNumberInputName),
    get(documentNumberInputProps, 'errorMessages', {}),
  );

  const countryOfIssuanceError = getFieldError(
    get(errors, countryOfIssuanceFieldName),
    fieldDefinitions[countryOfIssuanceFieldName].errorMessages,
  );

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <SelfCheckFormDocumentSecondarySelectionMarkup
        className={CLASS_NAMES.formGroup}
        data={get(data, supportingDocumentTypeFieldName)}
        error={supportingDocumentError}
        register={register}
      />
      <TextInputControl
        id={CLASS_NAMES.documentNumberInputControl}
        name={documentNumberInputName}
        disabled={documentNumberInputDisabled}
        label="Document Number"
        labelHint={REQUIRED_LABEL_HINT}
        hint={!documentNumberInputError ? get(documentNumberInputProps, 'hint') : undefined}
        errorId={documentNumberInputError ? CLASS_NAMES.documentNumberInputControlError : undefined}
        errorMessage={documentNumberInputError}
        defaultValue={get(data, documentNumberInputName)}
        ref={register({
          ...get(documentNumberInputProps, 'validation', {}),
          required: true,
        })}
      />
      <CountrySelect
        className={CLASS_NAMES.countryOfIssuanceSelect}
        name={countryOfIssuanceFieldName}
        disabled={countrySelectDisabled}
        required={!countrySelectDisabled}
        defaultValue={get(data, countryOfIssuanceFieldName)}
        error={countryOfIssuanceError}
        setValue={(name, value): void => setValue(name as keyof SelfCheckFormDocumentSelectionFormData, value)}
        register={register}
      />
    </div>
  );
};

export default SelfCheckFormDocumentSelectionNCAWSection;
