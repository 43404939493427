import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import {
  useSelfLockQuery,
  SelfLockState,
  SelfLockUnlockPayload,
  useSelfLockUpdateMutation,
} from '../../services/SelfLockApi';
import SelfLockMarkup from './SelfLockMarkup';
import NotificationAlert from '../../utils/NotificationAlert/NotificationAlert';

export const SUCCESS_MESSAGE = 'Your Social Security Number has been successfully unlocked.';
export const UNEXPECTED_SERVER_ERROR_MESSAGE = 'An unexpected error occurred. Please try again.';

const SelfLock = (): JSX.Element => {
  const { error, isIdle, isLoading, data } = useSelfLockQuery();
  const {
    mutate,
    error: submissionError,
    isLoading: isSubmissionLoading,
    isSuccess: isSubmissionSuccess,
  } = useSelfLockUpdateMutation();

  const history = useHistory();
  const historyNotificationAlert = get(history, 'location.state.notificationAlert');

  useEffect(() => {
    if (isSubmissionSuccess) {
      setNotificationAlert(new NotificationAlert('success', SUCCESS_MESSAGE));
    } else if (submissionError) {
      setNotificationAlert(new NotificationAlert('error', UNEXPECTED_SERVER_ERROR_MESSAGE));
    } else if (historyNotificationAlert) {
      setNotificationAlert(historyNotificationAlert);
    }
  }, [historyNotificationAlert, isSubmissionSuccess, submissionError]);

  const [notificationAlert, setNotificationAlert] = useState<NotificationAlert>();

  const handleUserUnlockRequest = (): void => {
    const payload: SelfLockUnlockPayload = {
      state: SelfLockState.UNLOCKED,
    };

    mutate(payload);
  };

  if (error) {
    return <Redirect to="/error" />;
  }

  return (
    <>
      {!isLoading && !isIdle && !isSubmissionLoading && (
        <SelfLockMarkup
          data={data}
          handleUnlockButtonClick={handleUserUnlockRequest}
          notificationAlert={notificationAlert}
        />
      )}
    </>
  );
};

export default SelfLock;
