import { CreateUserQuizRequestFieldName } from '../../../services/UserApi';
import { ErrorMessages } from '../../../utils/forms';

export const personalInformationFormErrorMessages: { [field in CreateUserQuizRequestFieldName]: ErrorMessages } = {
  [CreateUserQuizRequestFieldName.ADDRESS_LINE1]: {
    required: 'Address is required.',
    pattern:
      'Address may include letters, numbers, spaces and these special characters; pound symbol (#), period (.), forward slash (/), and dash (-).',
    maxLength: 'Address can not be more than 60 characters in length.',
    maxCombinedLength: 'Address line 1 and address line 2 together must be 60 characters or fewer.',
    isEmpty: 'Address is required.',
  },
  [CreateUserQuizRequestFieldName.ADDRESS_LINE2]: {
    pattern:
      'Address line 2 may include letters, numbers, spaces and these special characters; pound symbol (#), period (.), forward slash (/), and dash (-).',
    maxLength: 'Address line 2 must be 59 characters or fewer.',
  },
  [CreateUserQuizRequestFieldName.CITY]: {
    required: 'City is required.',
    pattern: "City may include letters, spaces and these special characters; dash (-) and single quote (').",
    maxLength: 'City must be 38 characters or fewer.',
    isEmpty: 'City is required.',
  },
  [CreateUserQuizRequestFieldName.DOB]: {
    required: 'Date of birth is required.',
    validDate: 'Date of birth must be a valid date and format MM/DD/YYYY.',
    over18: 'You must be at least 18 years old to use myE-Verify.',
  },
  [CreateUserQuizRequestFieldName.FIRST_NAME]: {
    required: 'First name is required.',
    pattern: 'This field must not contain numbers or special characters.',
    maxLength: 'First name must be 32 characters or fewer',
    isEmpty: 'First name is required.',
  },
  [CreateUserQuizRequestFieldName.LAST_NAME]: {
    required: 'Last name is required.',
    pattern: 'This field must not contain numbers or special characters.',
    maxLength: 'Last name must be 32 characters or fewer.',
    isEmpty: 'Last name is required.',
  },
  [CreateUserQuizRequestFieldName.MIDDLE_INITIAL]: {
    maxLength: 'Middle initial may only be 1 character.',
    pattern: 'This field must not contain numbers or special characters.',
  },
  [CreateUserQuizRequestFieldName.PHONE_NUMBER]: {
    required: 'Phone number is required.',
    pattern: 'Phone number is invalid or formatted incorrectly (###-###-####).',
  },
  [CreateUserQuizRequestFieldName.SSN]: {
    required: 'Social Security number is required.',
    pattern: 'Social Security number is invalid.',
  },
  [CreateUserQuizRequestFieldName.STATE]: {
    required: 'State is required.',
  },
  [CreateUserQuizRequestFieldName.ZIP_CODE]: {
    required: 'Zip code is required.',
    pattern: 'Zip code format must be: #####, #####-####, or #########.',
  },
};

export const personalInformationFormLabels: { [field in CreateUserQuizRequestFieldName]: string } = {
  [CreateUserQuizRequestFieldName.ADDRESS_LINE1]: 'Address',
  [CreateUserQuizRequestFieldName.ADDRESS_LINE2]: 'Address 2',
  [CreateUserQuizRequestFieldName.CITY]: 'City',
  [CreateUserQuizRequestFieldName.DOB]: 'Date of Birth',
  [CreateUserQuizRequestFieldName.FIRST_NAME]: 'First Name',
  [CreateUserQuizRequestFieldName.LAST_NAME]: 'Last Name',
  [CreateUserQuizRequestFieldName.MIDDLE_INITIAL]: 'Middle Initial',
  [CreateUserQuizRequestFieldName.PHONE_NUMBER]: 'Phone Number',
  [CreateUserQuizRequestFieldName.SSN]: 'Social Security Number',
  [CreateUserQuizRequestFieldName.STATE]: 'State',
  [CreateUserQuizRequestFieldName.ZIP_CODE]: 'Zip Code',
};

export const STATES = [
  { code: 'AL', description: 'Alabama' },
  { code: 'AK', description: 'Alaska' },
  { code: 'AS', description: 'American Samoa' },
  { code: 'AZ', description: 'Arizona' },
  { code: 'AR', description: 'Arkansas' },
  { code: 'CA', description: 'California' },
  { code: 'CO', description: 'Colorado' },
  { code: 'CT', description: 'Connecticut' },
  { code: 'DE', description: 'Delaware' },
  { code: 'DC', description: 'District of Columbia' },
  { code: 'FL', description: 'Florida' },
  { code: 'GA', description: 'Georgia' },
  { code: 'HI', description: 'Hawaii' },
  { code: 'ID', description: 'Idaho' },
  { code: 'IL', description: 'Illinois' },
  { code: 'IN', description: 'Indiana' },
  { code: 'IA', description: 'Iowa' },
  { code: 'KS', description: 'Kansas' },
  { code: 'KY', description: 'Kentucky' },
  { code: 'LA', description: 'Louisiana' },
  { code: 'ME', description: 'Maine' },
  { code: 'MD', description: 'Maryland' },
  { code: 'MA', description: 'Massachusetts' },
  { code: 'MI', description: 'Michigan' },
  { code: 'MN', description: 'Minnesota' },
  { code: 'MS', description: 'Mississippi' },
  { code: 'MO', description: 'Missouri' },
  { code: 'MT', description: 'Montana' },
  { code: 'NE', description: 'Nebraska' },
  { code: 'NV', description: 'Nevada' },
  { code: 'NH', description: 'New Hampshire' },
  { code: 'NJ', description: 'New Jersey' },
  { code: 'NM', description: 'New Mexico' },
  { code: 'NY', description: 'New York' },
  { code: 'NC', description: 'North Carolina' },
  { code: 'ND', description: 'North Dakota' },
  { code: 'OH', description: 'Ohio' },
  { code: 'OK', description: 'Oklahoma' },
  { code: 'OR', description: 'Oregon' },
  { code: 'PA', description: 'Pennsylvania' },
  { code: 'RI', description: 'Rhode Island' },
  { code: 'SC', description: 'South Carolina' },
  { code: 'SD', description: 'South Dakota' },
  { code: 'TN', description: 'Tennessee' },
  { code: 'TX', description: 'Texas' },
  { code: 'UT', description: 'Utah' },
  { code: 'VT', description: 'Vermont' },
  { code: 'VA', description: 'Virginia' },
  { code: 'WA', description: 'Washington' },
  { code: 'WV', description: 'West Virginia' },
  { code: 'WI', description: 'Wisconsin' },
  { code: 'WY', description: 'Wyoming' },
  { code: 'MP', description: 'Northern Marianas Islands' },
  { code: 'VI', description: 'Virgin Islands' },
  { code: 'PR', description: 'Puerto Rico' },
  { code: 'GU', description: 'Guam' },
];
