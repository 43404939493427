import React from 'react';
import classNames from 'classnames';
import { Accordion } from 'uswds-react';
import { COLUMN_9_CLASS_NAME } from '../../../constants';

export type HelpSectionContentProps = React.ComponentPropsWithoutRef<typeof Accordion.Content>;

const baseClassName = 'help-section-content';
const CLASS_NAMES = {
  base: baseClassName,
};

const HelpSectionContent = ({ id, className, children = '' }: HelpSectionContentProps): JSX.Element => (
  <Accordion.Content id={id} className={classNames(CLASS_NAMES.base, className)}>
    <div className={COLUMN_9_CLASS_NAME}>{children}</div>
  </Accordion.Content>
);

export default HelpSectionContent;
