import React from 'react';
import { Accordion } from 'uswds-react';
import HelpSectionContent from '../HelpSectionContent';

const baseClassName = 'citizenship-status-help-section';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

const CitizenshipStatusHelpSection = (): JSX.Element => (
  <>
    <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
      Help with Citizenship Status
    </Accordion.Heading>
    <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
      <p>
        <span className="text-bold">U.S. Citizen</span>
        <br />A citizen of the United States according to the Constitution and laws of the United States, either by
        birth or naturalization.
      </p>
      <p>
        <span className="text-bold">Noncitizen Nationals of the United States</span>
        <br />
        An individual born in American Samoa, certain former citizens of the former Trust Territory of the Pacific
        Islands, and certain children of noncitizen nationals born abroad.
      </p>
      <p>
        <span className="text-bold">Lawful Permanent Resident</span>
        <br />
        An individual who is not a U.S citizen and who resides in the United States under legally recognized and
        lawfully recorded permanent residence as an immigrant. Asylees and refugees should not select this status, but
        should instead select "An Noncitizen authorized to work" below.
      </p>
      <p>
        <span className="text-bold">Noncitizen Authorized to Work</span>
        <br />
        An individual who is not a citizen or national of the United States, or a lawful permanent resident, but is
        authorized to work in the United States.
      </p>
    </HelpSectionContent>
  </>
);

export default CitizenshipStatusHelpSection;
