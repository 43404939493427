import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { Button } from '@ver-uds/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import ReturnHomeButton from '../../../../../../../../Actions/ReturnHomeButton';
import ActionFooter from '../../../../../../../../ActionFooter/ActionFooter';

export interface SelfCheckCaseDetailReturnHomeActionSectionProps {
  className?: ClassValue;
}

const baseClassName = 'case-detail-self-check-action-section--return-home';
const CLASS_NAMES = {
  base: baseClassName,
};

const SelfCheckCaseDetailReturnHomeActionSection = ({
  className = undefined,
}: SelfCheckCaseDetailReturnHomeActionSectionProps): JSX.Element => (
  <ActionFooter
    className={classNames(CLASS_NAMES.base, className)}
    cancelButton={<ReturnHomeButton variant="primary" />}
    submitButton={
      <Button variant="secondary" onClick={window.print} iconLeft={<FontAwesomeIcon icon={faPrint} />}>
        Print Result
      </Button>
    }
  />
);

export default SelfCheckCaseDetailReturnHomeActionSection;
