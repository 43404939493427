import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import ActionFooter from '../../../../../../../../../ActionFooter/ActionFooter';
import paths from '../../../../../../../../../../routing/paths';
import {
  SelfCheckCaseClosureReason,
  useCloseSelfCheckCaseMutation,
} from '../../../../../../../../../../services/SelfCheckApi';
import ReturnHomeButton from '../../../../../../../../../Actions/ReturnHomeButton';

export const START_NEW_CASE_BUTTON_TEXT = 'Start New Case';

export interface StartNewCaseActionFooterProps {
  className?: ClassValue;
  caseNumber: string;
  shouldCloseCaseOnSubmit?: boolean;
}

const baseClassName = 'start-new-case-action-footer';
const CLASS_NAMES = {
  base: baseClassName,
};

const StartNewCaseActionFooter = ({
  className = undefined,
  caseNumber,
  shouldCloseCaseOnSubmit = undefined,
}: StartNewCaseActionFooterProps): JSX.Element => {
  const history = useHistory();
  const { mutateAsync } = useCloseSelfCheckCaseMutation(caseNumber);

  const redirectToSelfCheck = (): void => history.push(paths.selfCheck);

  const handleStartNewCaseClick = async (): Promise<void> => {
    if (!shouldCloseCaseOnSubmit) {
      return redirectToSelfCheck();
    }

    try {
      await mutateAsync({
        closureReason: SelfCheckCaseClosureReason.DUPLICATE_CASE_DATA_EXISTS,
      });
      return redirectToSelfCheck();
    } catch (_err) {
      return history.push(paths.error);
    }
  };

  return (
    <ActionFooter
      className={classNames(CLASS_NAMES.base, className)}
      cancelButton={<ReturnHomeButton />}
      submitButtonText={START_NEW_CASE_BUTTON_TEXT}
      onSubmit={handleStartNewCaseClick}
    />
  );
};

export default StartNewCaseActionFooter;
