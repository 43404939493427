import React from 'react';
import PageContentLayout from '../layouts/PageContentLayout/PageContentLayout';
import CreateAccount from '../components/CreateAccount/CreateAccount';

const CreateAccountPage = (): JSX.Element => (
  <PageContentLayout title="Create Account">
    <CreateAccount />
  </PageContentLayout>
);

export default CreateAccountPage;
