import get from 'lodash/get';
import { ReadableCaseStatus } from '../../../services/CaseApi';
import { CaseStatus, SelfCheckCaseResponseData } from '../../../services/SelfCheckApi';
import { CaseEligibilityStatus } from './constants';

/* eslint-disable import/prefer-default-export */
// Allows for overriding case status for certain self check data scenarios
export const resolveCaseStatus = (
  readableCaseStatus: ReadableCaseStatus,
  selfCheckCaseData?: SelfCheckCaseResponseData,
): ReadableCaseStatus => {
  // Self Check EA will result in a case status of "Closed", but we want to treat this as an EA status
  if (get(selfCheckCaseData, 'caseEligibilityStatement') === CaseEligibilityStatus.EMPLOYMENT_AUTHORIZED) {
    return ReadableCaseStatus.EMPLOYMENT_AUTHORIZED;
  }
  if (get(selfCheckCaseData, 'caseStatus') === CaseStatus.QUEUED) {
    return ReadableCaseStatus.CHECK_BACK_LATER;
  }
  return readableCaseStatus;
};
