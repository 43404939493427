import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { ReadableCaseStatus } from '../../../services/CaseApi';
import { SelfCheckCaseResponseData } from '../../../services/SelfCheckApi';
import CaseDetailHeading from './CaseDetailHeading';
import CaseDetailTag from './CaseDetailTag';
import { resolveCaseStatus } from './util';
import CaseDetailContent from './CaseDetailContent';

export interface CaseDetailMarkupProps {
  className?: ClassValue;
  caseNumber: string;
  caseStatus: ReadableCaseStatus;
  selfCheckCaseData?: SelfCheckCaseResponseData;
}

const baseClassName = 'case-detail';
const CLASS_NAMES = {
  base: baseClassName,
  statusSection: `${baseClassName}__status-section`,
  statusTag: classNames(`${baseClassName}__status-tag`, 'display-inline-block', 'margin-top-1'),
  caseNumberSection: classNames(`${baseClassName}__case-number-section`, 'margin-top-3'),
  contentSection: classNames(`${baseClassName}__content-section`, 'margin-top-4'),
};

const CaseDetailMarkup = ({
  className = undefined,
  caseNumber,
  caseStatus: unresolvedCaseStatus,
  selfCheckCaseData = undefined,
}: CaseDetailMarkupProps): JSX.Element => {
  const caseStatus = resolveCaseStatus(unresolvedCaseStatus, selfCheckCaseData);
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <div className={CLASS_NAMES.statusSection}>
        <CaseDetailHeading caseStatus={caseStatus} selfCheckCaseData={selfCheckCaseData} />
        <CaseDetailTag className={CLASS_NAMES.statusTag} caseStatus={caseStatus} />
      </div>
      <div className={CLASS_NAMES.caseNumberSection}>
        <h4>E-Verify Case Number {caseNumber}</h4>
      </div>
      <CaseDetailContent
        className={CLASS_NAMES.contentSection}
        caseNumber={caseNumber}
        caseStatus={caseStatus}
        selfCheckCaseData={selfCheckCaseData}
      />
    </div>
  );
};

export default CaseDetailMarkup;
