import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { SelfCheckCaseDetailVariant } from '../constants';
import SelfCheckCaseDetailFNCContent from './variants/SelfCheckCaseDetailFNCContent';
import SelfCheckCaseDetailPendingRefContent from './variants/SelfCheckCaseDetailPendingRefContent';
import SelfCheckCaseDetailReferredContent from './variants/SelfCheckCaseDetailReferredContent';
import SelfCheckCaseDetailUnconfirmedContent from './variants/SelfCheckCaseDetailUnconfirmedContent';
import SelfCheckCaseDetailAuthorizedContent from './variants/SelfCheckCaseDetailAuthorizedContent';

export interface SelfCheckCaseDetailContentProps {
  className?: ClassValue;
  selfCheckCaseDetailVariant: SelfCheckCaseDetailVariant;
  dhsReferralStatus?: string | null;
  ssaReferralStatus?: string | null;
}

const baseClassName = 'case-detail-content--self-check';
const CLASS_NAMES = {
  base: baseClassName,
};

const SelfCheckCaseDetailContent = ({
  className = undefined,
  selfCheckCaseDetailVariant,
  dhsReferralStatus = undefined,
  ssaReferralStatus = undefined,
}: SelfCheckCaseDetailContentProps): JSX.Element => (
  <div className={classNames(CLASS_NAMES.base, className)}>
    {selfCheckCaseDetailVariant === SelfCheckCaseDetailVariant.REFERRED && (
      <SelfCheckCaseDetailReferredContent dhsReferralStatus={dhsReferralStatus} ssaReferralStatus={ssaReferralStatus} />
    )}
    {selfCheckCaseDetailVariant === SelfCheckCaseDetailVariant.FNC && <SelfCheckCaseDetailFNCContent />}
    {selfCheckCaseDetailVariant === SelfCheckCaseDetailVariant.UNCONFIRMED_DATA && (
      <SelfCheckCaseDetailUnconfirmedContent />
    )}
    {selfCheckCaseDetailVariant === SelfCheckCaseDetailVariant.PENDING_REFERRAL && (
      <SelfCheckCaseDetailPendingRefContent />
    )}
    {selfCheckCaseDetailVariant === SelfCheckCaseDetailVariant.EMPLOYMENT_AUTHORIZED && (
      <SelfCheckCaseDetailAuthorizedContent />
    )}
  </div>
);

export default SelfCheckCaseDetailContent;
