import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import get from 'lodash/get';
import paths from '../../routing/paths';
import { isCaseNumberValid, useCaseTrackerQuery, useGetOpenSelfCheckCaseQuery } from '../../services/CaseApi';
import { mapRawCaseStatusToReadableCaseStatus } from '../../services/CaseApi/util';
import { useGetCaseDetailsByCaseNumberQuery } from '../../services/SelfCheckApi';
import CaseDetailMarkup from './CaseDetailMarkup';

const CaseDetail = (): JSX.Element => {
  const urlParams = useParams();
  const caseNumber = get(urlParams, 'caseNumber', null);
  const {
    data: caseTrackerData,
    error,
    isIdle: isCaseTrackerIdle,
    isLoading: isCaseTrackerLoading,
  } = useCaseTrackerQuery(caseNumber);

  const { data: openSelfCheckCaseData, isLoading: isGetOpenSelfCheckCaseLoading } = useGetOpenSelfCheckCaseQuery();

  const {
    data: selfCheckCaseData,
    isLoading: isGetCaseDetailsByCaseNumberLoading,
  } = useGetCaseDetailsByCaseNumberQuery(caseNumber);

  if (error || !isCaseNumberValid(caseNumber)) {
    return <Redirect to={paths.error} />;
  }

  return (
    <>
      {!isCaseTrackerIdle &&
        !isCaseTrackerLoading &&
        !isGetOpenSelfCheckCaseLoading &&
        !isGetCaseDetailsByCaseNumberLoading && (
          <CaseDetailMarkup
            caseNumber={caseNumber}
            caseStatus={mapRawCaseStatusToReadableCaseStatus(caseTrackerData?.caseStatus)}
            selfCheckCaseData={openSelfCheckCaseData && selfCheckCaseData}
          />
        )}
    </>
  );
};

export default CaseDetail;
