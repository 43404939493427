import React from 'react';
import { Accordion } from 'uswds-react';
import HelpSectionContent from '../HelpSectionContent';

const baseClassName = 'address-help-section';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

const AddressHelpSection = (): JSX.Element => (
  <>
    <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
      Help with address
    </Accordion.Heading>
    <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
      <p>To ensure the best identity assurance quiz, please follow this guidance:</p>
      <ul>
        <li>
          For street addresses, enter the house number, the street name, and the apartment/unit number (if applicable)
        </li>
        <li>A PO BOX can be used</li>
        <li>A dorm room or campus address can be used if it is an actual US Postal Service address</li>
        <li>
          An address in the United States, which includes the 50 states, the District of Columbia, Puerto Rico, the U.S.
          Virgin Islands, Guam, and the Commonwealth of the Northern Mariana Islands, is required for enrollment into
          the myE-Verify program.
        </li>
      </ul>
      <p>
        If you have recently changed your address, you might not receive a quiz. If this happens, try again with your
        previous address.
      </p>
    </HelpSectionContent>
  </>
);

export default AddressHelpSection;
