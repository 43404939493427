import get from 'lodash/get';

export interface ConfigurationParameters {
  basePath?: string;
}

class Configuration {
  private configuration?: ConfigurationParameters;

  constructor(configuration?: ConfigurationParameters) {
    this.configuration = configuration;
  }

  get basePath(): string | undefined {
    return get(this.configuration, 'basePath');
  }
}

export default Configuration;
