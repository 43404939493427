import React from 'react';
import { Accordion } from 'uswds-react';
import ImageWrapper from '../../ImageWrapper';
import HelpSectionContent from '../HelpSectionContent';
import i94Electronic from '../../../assets/img/documents/help/i94/i-94-electronic.png';
import i94Example1 from '../../../assets/img/documents/help/i94/i-94-example-1.png';
import i94Example2 from '../../../assets/img/documents/help/i94/i-94-example-2.png';

const baseClassName = 'uscis-a-number-help-section';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

const I94HelpSection = (): JSX.Element => (
  <>
    <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
      Help with I-94 Number
    </Accordion.Heading>
    <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
      <p>
        The Form I-94 number, also known as the Departure Number or Admission Record Number, is an arrival/departure
        record issued by U.S. Customs and Border Protection (CBP) to foreign visitors to the United States.
      </p>
      <p>
        The Form I-94 number consists of 11 characters. Form I-94 numbers issued before May 2019 consist of 11 digits.
        Form I-94 numbers issued after May 2019 consist of 9 digits + 1 letter + 1 digit.
      </p>
      <p>Examples of the Form I-94 number: 27463829057 or 236409185A5</p>
      <p>
        Unexpired Form I-94s issued in the current numeric-only format will continue to be valid until the Admit Date
        printed on the paper I-94 and/or the date displayed on the public I-94 website at{' '}
        <a href="https://i94.cbp.dhs.gov/I94/#/home">https://i94.cbp.dhs.gov/I94/#/home</a>.
      </p>
      <p>
        Visit CBP's website at{' '}
        <a href="https://www.cbp.gov/travel/international-visitors/i-94">
          https://www.cbp.gov/travel/international-visitors/i-94
        </a>{' '}
        for more information.
      </p>
      <p>Electronic Form I-94</p>
      <ImageWrapper images={[{ src: i94Electronic, alt: 'Example of the electronic version of Form I-94' }]} />
      <p>Form I-94, Arrival/Departure Record</p>
      <ImageWrapper
        images={[
          { src: i94Example1, alt: 'An example of Form I-94' },
          { src: i94Example2, alt: 'A second example of Form I-94' },
        ]}
      />
    </HelpSectionContent>
  </>
);

export default I94HelpSection;
