import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { ReadableCaseStatus } from '../../../../../services/CaseApi';
import { mapReadableCaseStatusToCaseDetailBodyText } from './util';

export interface GenericCaseDetailContentProps {
  className?: ClassValue;
  caseStatus: ReadableCaseStatus;
}

const baseClassName = 'case-detail-content--generic';
const CLASS_NAMES = {
  base: baseClassName,
};

const GenericCaseDetailContent = ({
  className = undefined,
  caseStatus,
}: GenericCaseDetailContentProps): JSX.Element => (
  <div className={(classNames(CLASS_NAMES.base, className), 'grid-col-9')}>
    <p>{mapReadableCaseStatusToCaseDetailBodyText(caseStatus)}</p>
  </div>
);

export default GenericCaseDetailContent;
