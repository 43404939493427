import React from 'react';
import { Modal } from '@ver-uds/react';

export interface PaperworkReductionActModalProps {
  handleClose: () => void;
  isOpen: boolean;
}

export const MODAL_ID = 'paperwork-reduction-act-modal';

const baseClassName = MODAL_ID;
export const CLASS_NAMES = {
  base: baseClassName,
  contentSection: `${baseClassName}__content-section`,
  textSection: `${baseClassName}__text-section`,
};

const PaperworkReductionActModal = ({ handleClose, isOpen }: PaperworkReductionActModalProps): JSX.Element => {
  return (
    <Modal id={MODAL_ID} header="Paperwork Reduction Act" open={isOpen} close={handleClose}>
      <Modal.Content className={CLASS_NAMES.contentSection}>
        <div className={CLASS_NAMES.textSection}>
          An agency may not conduct or sponsor information collection, and a person is not required to respond to a
          collection of information, unless it displays a valid Office of Management and Budget (OMB) control number.
          The public reporting burden for this collection is estimated as follows: the estimated total number of
          respondents for the information collection Form G-1499 is 250,000 and the estimated hour burden per response
          is 5 minutes. Of this 250,000, an estimated 75,000 respondents will need to correct information that may have
          been entered incorrectly to continue using myE-Verify; this estimated burden per response is 5 minutes. Of
          this 250,000, an estimated 10,000 respondents may be required to pursue further action to correct their
          records at the appropriate agency; this estimated burden per response is 70 minutes. Of this 250,000, an
          estimated 25,000 respondents will be required to provide additional information for a second Authentication
          Check; this estimated burden per response is 15 minutes. Send comments regarding this burden estimate or any
          other aspect of this information collection, including suggestions for reducing this burden, to U.S.
          Citizenship and Immigration Services, Regulatory Coordination Division, Office of Policy and Strategy, 20
          Massachusetts Avenue, Washington, DC 20529-202. <b>Do not mail your application to this address.</b>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default PaperworkReductionActModal;
