import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import moment, { Moment } from 'moment';
import { AccessorFn } from './constants';

/* eslint-disable import/prefer-default-export */
const getVisibleDataStartIndex = ({
  tablePageIndex,
  tablePageSize,
}: {
  tablePageIndex: number;
  tablePageSize: number;
}): number => tablePageIndex * tablePageSize;

const getVisibleDataEndIndex = ({
  startIndex,
  tablePageIndex,
  tablePageSize,
}: {
  startIndex: number;
  tablePageIndex: number;
  tablePageSize: number;
}): number => startIndex + Math.min((tablePageIndex + 1) * tablePageSize, tablePageSize);

export const getVisibleDataSlice = <D extends Record<string, unknown>>({
  data,
  tablePageIndex,
  tablePageSize,
}: {
  data: D[];
  tablePageIndex: number;
  tablePageSize: number;
}): D[] => {
  const startIndex = getVisibleDataStartIndex({ tablePageIndex, tablePageSize });
  const endIndex = getVisibleDataEndIndex({ startIndex, tablePageIndex, tablePageSize });
  return data.slice(startIndex, endIndex);
};

type Comparable = number | string | Date;
const basicCompare = <T extends Comparable>(a: T, b: T): number => (a >= b ? 1 : -1);

const getDateForStr = (dateStr: string): Moment | null => {
  const momentDate = moment(dateStr);
  if (momentDate.isValid()) {
    return momentDate;
  }
  return null;
};

const dateStrCompare = (a: string, b: string): number => {
  const aMomentDate = getDateForStr(a);
  if (isNull(aMomentDate)) {
    return 0;
  }
  const bMomentDate = getDateForStr(b);
  if (isNull(bMomentDate)) {
    return 0;
  }
  return basicCompare(aMomentDate.toDate(), bMomentDate.toDate());
};

const compare = (a: unknown, b: unknown): number => {
  if (isNumber(a) && isNumber(b)) {
    return basicCompare(a, b);
  }
  if (isNil(a) && !isNil(b)) {
    return -1;
  }
  if (!isNil(a) && isNil(b)) {
    return 1;
  }

  if (isString(a) && isString(b)) {
    // try to compare as dates
    const dateStrCompareResult = dateStrCompare(a, b);
    if (dateStrCompareResult !== 0) {
      return dateStrCompareResult;
    }

    // resort to comparing as plain strings
    return basicCompare(a, b);
  }
  return 0;
};

const getDataValue = <D extends Record<string, unknown>>(
  rowData: D,
  sortByKey: keyof D,
  accessor?: AccessorFn<D>,
): unknown => {
  if (typeof accessor === 'function') {
    return accessor(rowData);
  }
  return rowData[sortByKey];
};

export const sortByKey = <D extends Record<string, unknown>>(
  data: D[],
  key: keyof D,
  desc = false,
  accessor?: AccessorFn<D>,
): D[] =>
  data.slice().sort((a, b) => {
    const sortDirectionModifier = desc ? -1 : 1;
    const aVal = getDataValue(a, key, accessor);
    const bVal = getDataValue(b, key, accessor);
    return compare(aVal, bVal) * sortDirectionModifier;
  });
