import React, { useContext } from 'react';
import { AuthenticationContext } from '../../../../context/Authentication/AuthenticationContext';
import { UserInfoResponseData } from '../../../../services/UserApi';
import GenerateQuizErrorMarkup from './GenerateQuizErrorMarkup';

export interface GenerateQuizErrorProps {
  onSubmit: () => void;
  userInfo?: UserInfoResponseData;
}

const GenerateQuizError = ({ onSubmit, userInfo = undefined }: GenerateQuizErrorProps): JSX.Element => {
  const authentication = useContext(AuthenticationContext);
  return (
    <>
      <GenerateQuizErrorMarkup onSubmit={onSubmit} onCancel={authentication.logout} _userInfo={userInfo} />
    </>
  );
};

export default GenerateQuizError;
