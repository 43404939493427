import classNames from 'classnames';
import React from 'react';

export interface IdentityProofingAttemptCounterProps {
  idProofingAttempts?: number;
}

const baseClassName = 'identity-proofing-attempt-counter';
export const CLASS_NAMES = {
  base: baseClassName,
  header: classNames(`${baseClassName}__header`, 'margin-0'),
};

export const MAX_ID_PROOFING_ATTEMPTS = 3;

const attemptCountBackgroundStyling = {
  width: '26px',
  height: '24px',
  margin: '16px 4px 22px 8px',
  padding: '4px 8px',
  borderRadius: '4px',
  backgroundColor: '#757575',
  color: '#fff',
};

const IdentityProofingAttemptCounter = ({
  idProofingAttempts = undefined,
}: IdentityProofingAttemptCounterProps): JSX.Element => {
  return (
    <div className={baseClassName}>
      <p>
        Attempt<span style={attemptCountBackgroundStyling}>{idProofingAttempts}</span> of
        <span style={attemptCountBackgroundStyling}>{MAX_ID_PROOFING_ATTEMPTS}</span>
      </p>
    </div>
  );
};

export default IdentityProofingAttemptCounter;
