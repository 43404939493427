import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import TelephoneLink from '../../../../../Actions/TelephoneLink';

export interface NatzUploadSuccessModalContentProps {
  className?: ClassValue;
}

const baseClassName = 'upload-success-modal-content--natz';
const CLASS_NAMES = {
  base: baseClassName,
};

const NatzUploadSuccessModalContent = ({ className = undefined }: NatzUploadSuccessModalContentProps): JSX.Element => (
  <p className={classNames(CLASS_NAMES.base, className)}>
    We received your documents. <strong>You are required to call The U.S. Department of Homeland Security (DHS)</strong>{' '}
    at <TelephoneLink phoneNumber="888-897-7781" /> (TTY: <TelephoneLink phoneNumber="877-875-6028" />) to continue
    resolving this case
  </p>
);

export default NatzUploadSuccessModalContent;
