import React, { ReactElement, useContext } from 'react';
import { Button, Modal } from '@ver-uds/react';
import { AuthenticationContext } from '../../../context/Authentication/AuthenticationContext';

export interface CreateAccountCancellationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const MODAL_ID = 'create-account-cancellation-modal';

const baseClassName = MODAL_ID;
const CLASS_NAMES = {
  base: baseClassName,
  contentSection: `${baseClassName}__content-section`,
  textSection: `${baseClassName}__text-section`,
};

const CreateAccountCancellationModal = ({ isOpen, onClose }: CreateAccountCancellationModalProps): ReactElement => {
  const authContext = useContext(AuthenticationContext);

  const handleButtonClick = (): void => {
    authContext.logout();
  };

  return (
    <Modal id={MODAL_ID} header="Account Creation Canceled" open={isOpen} close={onClose}>
      <Modal.Content className={CLASS_NAMES.contentSection}>
        <div className={CLASS_NAMES.textSection}>
          <p>
            You have selected to cancel the myE‑Verify account setup process. In the future if you decide you would like
            access to all the great features myE‑Verify has to offer, use your username and password to reactivate your
            account and complete the account creation process. We hope to see you soon.
          </p>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Button as="button" onClick={handleButtonClick} className={CLASS_NAMES.base} variant="primary">
          Return to myE-Verify
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateAccountCancellationModal;
