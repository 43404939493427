import difference from 'lodash/difference';
import reduce from 'lodash/reduce';
import { SelfLockChallengeQuestion } from '../../../../services/SelfLockApi';

export const challengeQuestionOptions = [
  'Who is your favorite person from history?',
  'What is your favorite movie?',
  'What was the make of your first car?',
  'Which first grade school have you attended?',
  'In what city were you married?',
  'In which hospital were you born?',
  'What is your favorite sports team?',
  'What was the name of your first pet?',
  'What is your favorite hobby?',
  'What is the name of your first child?',
  "What is your maternal grandmother's first name?",
  'In what town/city were you born?',
  'What was your high school mascot?',
  'What is the name of the first company you worked for?',
  'How old were you at your wedding?',
  'In what town/city were you living at age 16?',
  'Where did you meet your spouse for the first time?',
  'What was the name of the first school you attended?',
  'What was the color of your first car?',
];

/* eslint-disable import/prefer-default-export */
export interface SelfLockLockFormData {
  0: SelfLockChallengeQuestion;
  1: SelfLockChallengeQuestion;
  2: SelfLockChallengeQuestion;
}

export const getAvaibleQuestionOptions = (formData: Record<number, SelfLockChallengeQuestion>): string[] => {
  const selectedQuestions = reduce(
    formData,
    (accumulator: string[], challengeQuestion: SelfLockChallengeQuestion) => {
      const { question } = challengeQuestion;
      if (question) {
        return accumulator.concat(question);
      }
      return accumulator;
    },
    [],
  );
  return difference(challengeQuestionOptions, selectedQuestions);
};
