import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { COLUMN_9_CLASS_NAME } from '../../../constants';
import { UploadDocumentType } from '../../../services/CaseApi';
import { mapUploadDocumentTypeToInfoCopy } from './util';

export interface UploadDocumentInfoSectionProps {
  className?: ClassValue;
  uploadDocumentType: UploadDocumentType | null;
}

const baseClassName = 'upload-document-info-section';
const CLASS_NAMES = {
  base: classNames(baseClassName, COLUMN_9_CLASS_NAME),
};

const UploadDocumentInfoSection = ({
  className = undefined,
  uploadDocumentType,
}: UploadDocumentInfoSectionProps): JSX.Element => {
  const copy = mapUploadDocumentTypeToInfoCopy(uploadDocumentType);
  if (typeof copy === 'undefined') {
    return <></>;
  }
  return <div className={classNames(CLASS_NAMES.base, className)}>{copy}</div>;
};

export default UploadDocumentInfoSection;
