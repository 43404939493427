import React from 'react';
import { FieldElement, Ref, RegisterOptions } from 'react-hook-form';
import { DropdownControl } from 'uswds-react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import isEmpty from 'lodash/isEmpty';
import { Country } from '../../services/ReferenceApi';
import { DEFAULT_LABEL, DEFAULT_NAME } from './constants';

export interface CountrySelectMarkupProps {
  className?: ClassValue;
  label?: string;
  labelHint?: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  defaultValue?: string;
  error?: string;
  options: Country[];
  register: (rules?: RegisterOptions) => (ref: (FieldElement & Ref) | null) => void;
}

const baseClassName = 'country-select';
const CLASS_NAMES = {
  base: baseClassName,
  error: `${baseClassName}__error`,
};

const CountrySelectMarkup = ({
  className = undefined,
  label = DEFAULT_LABEL,
  labelHint = undefined,
  name = DEFAULT_NAME,
  disabled = false,
  required = true,
  defaultValue = undefined,
  error = undefined,
  options,
  register,
}: CountrySelectMarkupProps): JSX.Element => {
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <DropdownControl
        id={CLASS_NAMES.base}
        name={name}
        label={label}
        labelHint={labelHint}
        disabled={disabled || isEmpty(options)}
        errorId={error ? CLASS_NAMES.error : undefined}
        errorMessage={error}
        defaultValue={defaultValue}
        required={required}
        ref={register({
          required,
        })}
      >
        <option value="">- Select -</option>
        {options.map((country) => (
          <option value={country.code} key={country.code}>
            {country.description}
          </option>
        ))}
      </DropdownControl>
    </div>
  );
};

export default CountrySelectMarkup;
