import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { Accordion } from 'uswds-react';
import { UploadDocumentType } from '../../../../../../../../services/CaseApi';
import {
  DriversLicenseHelpSection,
  I551HelpSection,
  I766HelpSection,
  NaturalizationHelpSection,
  UsPassportHelpSection,
} from '../../../../../../../HelpSections';

export interface CaseUploadDocumentHelpSectionProps {
  className?: ClassValue;
  uploadDocumentType: UploadDocumentType | null;
}

const baseClassName = 'case-upload-document-help-section';
const CLASS_NAMES = {
  base: baseClassName,
};

const CaseUploadDocumentHelpSection = ({
  className = undefined,
  uploadDocumentType,
}: CaseUploadDocumentHelpSectionProps): JSX.Element => {
  if (!uploadDocumentType) {
    return <></>;
  }
  return (
    <Accordion className={classNames(CLASS_NAMES.base, className)} bordered>
      {uploadDocumentType === UploadDocumentType.US_PASSPORT && <UsPassportHelpSection />}
      {uploadDocumentType === UploadDocumentType.DRIVERS_LICENSE && <DriversLicenseHelpSection />}
      {uploadDocumentType === UploadDocumentType.NATZ && (
        <>
          <NaturalizationHelpSection />
          <I551HelpSection />
          <UsPassportHelpSection />
        </>
      )}
      {uploadDocumentType === UploadDocumentType.UNKNOWN && (
        <>
          <I766HelpSection />
          <I551HelpSection />
        </>
      )}
    </Accordion>
  );
};

export default CaseUploadDocumentHelpSection;
