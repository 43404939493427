import get from 'lodash/get';
import {
  USCISANumberFieldName,
  countryOfIssuanceFieldName,
  documentExpirationDateFieldName,
  documentTypeFieldName,
  FieldName,
  foreignPassportNumberFieldName,
  i551NumberFieldName,
  i766NumberFieldName,
  i94NumberFieldName,
  SelfCheckFormData,
  SelfCheckFormPage,
  usPassportNumberFieldName,
  visaNumberFieldName,
} from '../constants';
import { CitizenshipStatus, DocumentType, SubmitSelfCheckCaseRequest } from '../../../../services/SelfCheckApi';
import {
  dobFieldName,
  firstNameFieldName,
  lastNameFieldName,
  middleNameFieldName,
  ssnFieldName,
} from '../../../../services/UserApi';

export const getDataByFieldName = (data: SelfCheckFormData = {}, name: FieldName): string | undefined => {
  const selectorPaths = [];
  switch (name) {
    case firstNameFieldName:
    case lastNameFieldName:
    case middleNameFieldName:
    case ssnFieldName:
    case dobFieldName:
      selectorPaths.push(SelfCheckFormPage.CONFIRM_IDENTITY);
      break;
    case USCISANumberFieldName:
    case documentTypeFieldName:
    case i94NumberFieldName:
      selectorPaths.push(SelfCheckFormPage.DOCUMENT_SELECTION);
      break;
    case countryOfIssuanceFieldName:
    case foreignPassportNumberFieldName:
      selectorPaths.push(SelfCheckFormPage.DOCUMENT_SELECTION, SelfCheckFormPage.DOCUMENT_DETAILS);
      break;
    case documentExpirationDateFieldName:
    case i551NumberFieldName:
    case i766NumberFieldName:
    case usPassportNumberFieldName:
    case visaNumberFieldName:
      selectorPaths.push(SelfCheckFormPage.DOCUMENT_DETAILS);
      break;
    default:
      return undefined;
  }

  return selectorPaths.reduce((value, selectorPath) => {
    return get(data, [selectorPath, name], value);
  }, undefined);
};

/* eslint-disable import/prefer-default-export */
export const mapSelfCheckFormDataToSubmitCaseRequest = (data?: SelfCheckFormData): SubmitSelfCheckCaseRequest => {
  const firstNameData = getDataByFieldName(data, firstNameFieldName);
  if (typeof firstNameData !== 'string') {
    throw new Error('firstName is not a string');
  }
  const lastNameData = getDataByFieldName(data, lastNameFieldName);
  if (typeof lastNameData !== 'string') {
    throw new Error('lastName is not a string');
  }

  return {
    firstName: firstNameData,
    lastName: lastNameData,
    middleName: getDataByFieldName(data, middleNameFieldName),
    uscisANumber: getDataByFieldName(data, USCISANumberFieldName),
    citizenshipStatus: get(data, SelfCheckFormPage.CITIZENSHIP_STATUS) as CitizenshipStatus,
    countryOfIssuance: getDataByFieldName(data, countryOfIssuanceFieldName),
    documentExpirationDate: getDataByFieldName(data, documentExpirationDateFieldName),
    documentType: getDataByFieldName(data, documentTypeFieldName) as DocumentType,
    i551Number: getDataByFieldName(data, i551NumberFieldName),
    i766Number: getDataByFieldName(data, i766NumberFieldName),
    i94Number: getDataByFieldName(data, i94NumberFieldName),
    usPassportNumber: getDataByFieldName(data, usPassportNumberFieldName),
    foreignPassportNumber: getDataByFieldName(data, foreignPassportNumberFieldName),
    visaNumber: getDataByFieldName(data, visaNumberFieldName),
  };
};
