import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { Button } from '@ver-uds/react';
import ActionFooter from '../../../../../../../ActionFooter/ActionFooter';
import Link from '../../../../../../../Link';
import paths from '../../../../../../../../routing/paths';
import { ReadableCaseStatus, TncType } from '../../../../../../../../services/CaseApi';
import { injectURLParams } from '../../../../../../../../utils/routing';
import CaseUploadSelfLockAlert from './CaseUploadSelfLockAlert';
import ReturnHomeButton from '../../../../../../../Actions/ReturnHomeButton';

export interface CaseUploadActionFooterMarkupProps {
  className?: ClassValue;
  caseNumber: string;
  isAuthenticated?: boolean;
  loginUrl: string;
  tncType: TncType;
  caseStatus: ReadableCaseStatus;
}

const baseClassName = 'case-upload-action-footer';
const CLASS_NAMES = {
  base: baseClassName,
};

const getActionButtonText = (isAuthenticated?: boolean): string => {
  if (isAuthenticated) {
    return 'Upload Documents';
  }
  return 'Sign in or Create Account to take action';
};

const getActionButtonHref = ({
  caseNumber,
  isAuthenticated,
  loginUrl,
}: Pick<CaseUploadActionFooterMarkupProps, 'caseNumber' | 'isAuthenticated' | 'loginUrl'>): string => {
  if (isAuthenticated) {
    return injectURLParams(paths.documentUpload, { caseNumber });
  }
  return loginUrl;
};

const CaseUploadActionFooterMarkup = ({
  className = undefined,
  caseNumber,
  isAuthenticated = undefined,
  loginUrl,
  tncType,
  caseStatus,
}: CaseUploadActionFooterMarkupProps): JSX.Element => {
  if (tncType === TncType.JBL && isAuthenticated) {
    return <CaseUploadSelfLockAlert />;
  }

  return (
    <ActionFooter
      className={classNames(CLASS_NAMES.base, className)}
      submitButton={
        caseStatus.includes('TENTATIVE_NONCONFIRMATION_SSA') || caseStatus.includes('EMPLOYEE_REFERRED_SSA') ? (
          <ReturnHomeButton variant="primary" />
        ) : (
          <Button as={Link} href={getActionButtonHref({ caseNumber, isAuthenticated, loginUrl })}>
            {getActionButtonText(isAuthenticated)}
          </Button>
        )
      }
      hideCancelButton
    />
  );
};

export default CaseUploadActionFooterMarkup;
