import { ComponentPropsWithoutRef } from 'react';
import { Tag } from '@ver-uds/react';
import { ReadableCaseStatus } from '../../../../services/CaseApi';

/* eslint-disable import/prefer-default-export */
export type TagVariant = ComponentPropsWithoutRef<typeof Tag>['variant'];

enum TagType {
  ACTION_NEEDED = 'ACTION NEEDED',
  IN_PROGRESS = 'CASE IN PROGRESS',
  VERIFICATION_FAILED = 'VERIFICATION FAILED',
  AUTHORIZED = 'EMPLOYMENT AUTHORIZED',
  CLOSED = 'CLOSED',
  UNKNOWN = 'UNKNOWN',
}

const mapReadableCaseStatusToTagType = (readableCaseStatus: ReadableCaseStatus): TagType => {
  switch (readableCaseStatus) {
    case ReadableCaseStatus.CASE_INCOMPLETE:
    case ReadableCaseStatus.FINAL_NONCONFIRMATION:
      return TagType.VERIFICATION_FAILED;
    case ReadableCaseStatus.CLOSE_AND_RESUBMIT:
    case ReadableCaseStatus.EMPLOYEE_REFERRED_DHS:
    case ReadableCaseStatus.EMPLOYEE_REFERRED_SSA:
    case ReadableCaseStatus.EMPLOYEE_REFERRED_DHS_AND_SSA:
    case ReadableCaseStatus.EMPLOYEE_REFERRED_DHS_OR_SSA:
    case ReadableCaseStatus.EMPLOYEE_REFERRED_CONTINUANCE:
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS:
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_SSA:
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_AND_SSA:
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_OR_SSA:
      return TagType.ACTION_NEEDED;
    case ReadableCaseStatus.EMPLOYMENT_AUTHORIZED:
      return TagType.AUTHORIZED;
    case ReadableCaseStatus.CLOSED:
      return TagType.CLOSED;
    case ReadableCaseStatus.CHECK_BACK_LATER:
    case ReadableCaseStatus.CONTINUANCE:
      return TagType.IN_PROGRESS;
    case ReadableCaseStatus.UNKNOWN:
    default:
      return TagType.UNKNOWN;
  }
};

export const mapReadableCaseStatusToTagVariant = (readableCaseStatus: ReadableCaseStatus): TagVariant => {
  const tagType = mapReadableCaseStatusToTagType(readableCaseStatus);
  switch (tagType) {
    case TagType.VERIFICATION_FAILED:
    case TagType.UNKNOWN:
      return 'error';
    case TagType.ACTION_NEEDED:
      return 'warning';
    case TagType.AUTHORIZED:
      return 'success';
    case TagType.IN_PROGRESS:
      return 'primary';
    case TagType.CLOSED:
    default:
      return undefined;
  }
};

export const mapReadableCaseStatusToTagText = (readableCaseStatus: ReadableCaseStatus): string => {
  const tagType = mapReadableCaseStatusToTagType(readableCaseStatus);
  return tagType.toString();
};
