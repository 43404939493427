import React from 'react';
import { Redirect } from 'react-router-dom';
import { ClassValue } from 'classnames/types';
import classNames from 'classnames';
import paths from '../../../../../../../../../routing/paths';
import {
  useNoActionSelfCheckCaseMutation,
  useReferSelfCheckCaseMutation,
} from '../../../../../../../../../services/SelfCheckApi';
import { injectURLParams } from '../../../../../../../../../utils/routing';
import { ActionType } from './constants';
import SelfCheckCaseDetailPendingRefActionSectionForm from './form';

export interface SelfCheckCaseDetailPendingRefActionSectionProps {
  caseNumber: string;
  className?: ClassValue;
}

const baseClassName = 'case-detail-self-check-action-section--pending-referral';
const CLASS_NAMES = {
  base: baseClassName,
};

const SelfCheckCaseDetailPendingRefActionSection = ({
  caseNumber,
  className = undefined,
}: SelfCheckCaseDetailPendingRefActionSectionProps): JSX.Element => {
  const { mutate: referMutate, isSuccess: isReferSuccess, isError: isReferError } = useReferSelfCheckCaseMutation(
    caseNumber,
  );

  const {
    mutate: noActionMutate,
    isSuccess: isNoActionSuccess,
    isError: isNoActionError,
  } = useNoActionSelfCheckCaseMutation(caseNumber);

  if (isReferError || isNoActionError) {
    return <Redirect to={paths.error} />;
  }

  if (isReferSuccess || isNoActionSuccess) {
    return (
      <Redirect
        to={injectURLParams(paths.caseDetail, {
          caseNumber,
        })}
      />
    );
  }

  /* eslint-disable-next-line consistent-return */
  const handleSubmit = (actionType: ActionType): void => {
    switch (actionType) {
      case ActionType.TAKE_ACTION:
        return referMutate(undefined);
      case ActionType.NO_ACTION:
      case ActionType.INCORRECT_DATA: // Both "no action" and "incorrect data" invoke same API action
        return noActionMutate(undefined);
      default:
    }
  };

  return (
    <SelfCheckCaseDetailPendingRefActionSectionForm
      className={classNames(CLASS_NAMES.base, className)}
      onSubmit={handleSubmit}
    />
  );
};

export default SelfCheckCaseDetailPendingRefActionSection;
