/* eslint-disable import/prefer-default-export */

/**
 * This enum serves as a simple reference for our logic,
 * and is by no means an exhaustive list of known eligibility statuses.
 */
export enum CaseEligibilityStatus {
  FNC = 'FNC',
  NO_ACTION_FNC = 'NO_ACTION_FNC',
  EMPLOYMENT_AUTHORIZED = 'EMPLOYMENT_AUTHORIZED',
}
