import React, { useContext } from 'react';
import { get } from 'lodash';
import { GlobalHeader } from '@ver-uds/react';
import { AuthenticationContext } from '../../../context/Authentication/AuthenticationContext';
import SignInButton from '../../Actions/SignInButton';
import LogOutButton from '../../Actions/LogOutButton';
import { useEnvironment } from '../../../context/Environment/EnvironmentContext';
import { UserContext } from '../../../context/User/UserContext';
import { firstNameFieldName, lastNameFieldName } from '../../../services/UserApi';

const baseClassName = 'header-user-controls';
export const CLASS_NAMES = {
  base: baseClassName,
};

const HeaderUserControls = (): JSX.Element => {
  const { environmentVariables } = useEnvironment();
  const { isAuthenticated } = useContext(AuthenticationContext);
  const { userInfoResponseData } = useContext(UserContext);

  const formattedName = (): string => {
    const firstName = userInfoResponseData[firstNameFieldName];
    const lastName = userInfoResponseData[lastNameFieldName];
    if (firstName == null || lastName == null) {
      return '';
    }
    return `${firstName[0]}. ${lastName}`;
  };

  const manageAccountRoute = get(environmentVariables, 'MYEVERIFY_WEB_SERVICE_EDIT_ACCOUNT', '/');

  return (
    <>
      {!isAuthenticated() && <SignInButton />}
      {isAuthenticated() && (
        <GlobalHeader.Menu id="account-menu" title={formattedName()} icon="user">
          <a href={manageAccountRoute}>Manage Account</a>
          <LogOutButton variant="destructive" />
        </GlobalHeader.Menu>
      )}
    </>
  );
};

export default HeaderUserControls;
