import { useContext, useMemo } from 'react';
import { QueryObserverResult, useQuery } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import createError from 'http-errors';
import get from 'lodash/get';
import { AuthenticationContext } from '../context/Authentication/AuthenticationContext';
import { normalizeURL } from '../utils/routing';
import BaseApi, { useConfiguration } from './BaseApi';
import paths from './paths.json';

export interface Country {
  code: string;
  description: string;
}

export interface CountriesResponse {
  data: Country[];
}

class ReferenceApi extends BaseApi {
  private getCountriesEndpoint(): string {
    return normalizeURL(`${this.configuration.basePath}/${paths.reference.getCountries}`);
  }

  async getCountries(): Promise<Country[]> {
    const endpoint = this.getCountriesEndpoint();
    try {
      const resp: AxiosResponse<CountriesResponse> = await axios.get(endpoint, { withCredentials: true });
      return get(resp, 'data.data');
    } catch (err) {
      throw createError(get(err, 'response.status', 500), err.message);
    }
  }
}

const useReferenceApi = (): ReferenceApi => {
  const configuration = useConfiguration();
  return useMemo(() => new ReferenceApi(configuration), [configuration]);
};

export const getCountriesQueryID = 'getCountries';
export const useCountriesQuery = (): QueryObserverResult<Country[]> => {
  const api = useReferenceApi();
  const { isAuthenticated } = useContext(AuthenticationContext);

  return useQuery(getCountriesQueryID, () => api.getCountries(), {
    enabled: isAuthenticated() && api.isConfigurationValid(),
  });
};
