import React from 'react';
import '@ver-uds/styles';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthenticationProvider } from './context/Authentication/AuthenticationContext';
import { EnvironmentProvider } from './context/Environment/EnvironmentContext';
import Router from './routing/Router';
import { LoadingProvider } from './context/Loading/LoadingContext';
import { SelfCheckFormProvider } from './context/Form/SelfCheck/SelfCheckFormContext';
import { UserProvider } from './context/User/UserContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const App: () => JSX.Element = () => {
  return (
    // Provide the environment variables as context to be used elsewhere in the application.
    <EnvironmentProvider>
      <QueryClientProvider client={queryClient}>
        <AuthenticationProvider>
          <UserProvider>
            <LoadingProvider>
              <HelmetProvider>
                <SelfCheckFormProvider>
                  <div className="App">
                    <Router />
                  </div>
                </SelfCheckFormProvider>
              </HelmetProvider>
            </LoadingProvider>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          </UserProvider>
        </AuthenticationProvider>
      </QueryClientProvider>
    </EnvironmentProvider>
  );
};

export default App;
