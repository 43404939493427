import {
  CreateUserQuizRequestFieldName,
  UserQuizAnswerOptions,
  UserQuizQuestions,
  UserQuizResponseData,
} from '../../services/UserApi';

export const STEP_INDICATOR_LABELS = [
  'Policy Agreements',
  'Personal Information',
  'Review Information',
  'Verify Identity',
];

export enum CreateAccountFormPage {
  ATTEMPTS_EXCEEDED = -3,
  FAILED_ATTEMPT = -2,
  GEN_QUIZ_ERROR = -1,
  POLICY_AGREEMENTS = 0,
  PERSONAL_INFORMATION = 1,
  REVIEW_INFORMATION = 2,
  VERIFY_IDENTITY = 3,
}

export const createAccountPageTitles = {
  [CreateAccountFormPage.ATTEMPTS_EXCEEDED]: 'myE-Verify | Maximum Failed Quiz Attempts Reached',
  [CreateAccountFormPage.FAILED_ATTEMPT]: 'myE-Verify | Failed Identity Proofing Quiz',
  [CreateAccountFormPage.GEN_QUIZ_ERROR]: 'myE-Verify | Failed to Generate Quiz',
  [CreateAccountFormPage.POLICY_AGREEMENTS]: 'myE-Verify | Policy Agreements',
  [CreateAccountFormPage.PERSONAL_INFORMATION]: 'myE-Verify | Enter Personal Information',
  [CreateAccountFormPage.REVIEW_INFORMATION]: 'myE-Verify | Review Personal Information',
  [CreateAccountFormPage.VERIFY_IDENTITY]: 'myE-Verify | Identity Proofing Quiz',
};

export const initialCreateUserQuizRequestObject = {
  [CreateUserQuizRequestFieldName.ADDRESS_LINE1]: '',
  [CreateUserQuizRequestFieldName.CITY]: '',
  [CreateUserQuizRequestFieldName.DOB]: '--',
  [CreateUserQuizRequestFieldName.FIRST_NAME]: '',
  [CreateUserQuizRequestFieldName.LAST_NAME]: '',
  [CreateUserQuizRequestFieldName.PHONE_NUMBER]: '',
  [CreateUserQuizRequestFieldName.SSN]: '',
  [CreateUserQuizRequestFieldName.STATE]: '',
  [CreateUserQuizRequestFieldName.ZIP_CODE]: '',
};

const answer1: UserQuizAnswerOptions = {
  answerId: '1',
  answerText: 'answer1',
};
const answer2: UserQuizAnswerOptions = {
  answerId: '2',
  answerText: 'answer2',
};
const answer3: UserQuizAnswerOptions = {
  answerId: '3',
  answerText: 'answer3',
};
const answer4: UserQuizAnswerOptions = {
  answerId: '4',
  answerText: 'answer4',
};

const userQuizQuestion1: UserQuizQuestions = {
  answers: [answer1, answer2, answer3, answer4],
  questionId: '1',
  questionText:
    'According to your credit profile, you may have opened an auto loan in or around January 2020. Please select the lender for this account. If you do not have such an auto loan, select ‘NONE OF THE ABOVE/DOES NOT APPLY’.',
};
const userQuizQuestion2: UserQuizQuestions = {
  answers: [answer1, answer2, answer3, answer4],
  questionId: '2',
  questionText:
    'You may have opened an auto loan or auto lease in or around June 2021. Please select the dollar amount range in which your monthly auto loan or lease payment falls. If you have not had an auto loan or lease with any of these amount ranges now or in the past, please select NONE OF THE ABOVE/DOES NOT APPLY.',
};
const userQuizQuestion3: UserQuizQuestions = {
  answers: [answer1, answer2, answer3, answer4],
  questionId: '3',
  questionText:
    'According to your credit profile, you may have opened a mortgage loan in or around March 2013. Please select the lender to whom you currently make your mortgage payments. If you do not have a mortgage, select NONE OF THE ABOVE/DOES NOT APPLY',
};
const userQuizQuestion4: UserQuizQuestions = {
  answers: [answer1, answer2, answer3, answer4],
  questionId: '4',
  questionText:
    'According to your credit profile, you may have opened an auto loan in or around June 2021. Please select the lender for this account. If you do not have such an auto loan, select NONE OF THE ABOVE/DOES NOT APPLY',
};

const questionArray: Array<UserQuizQuestions> = [
  userQuizQuestion1,
  userQuizQuestion2,
  userQuizQuestion3,
  userQuizQuestion4,
];

export const quizResponseData: UserQuizResponseData = {
  questions: questionArray,
};

export const httpStatusMessages = {
  SERVICE_UNAVAILABLE_MESSAGE: 'ServiceUnavailableError',
  GATEWAY_TIMEOUT_MESSAGE: 'GatewayTimeoutError',
};
