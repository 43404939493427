import React from 'react';
import PageContentLayout from '../layouts/PageContentLayout/PageContentLayout';
import CaseDetail from '../components/CaseDetail/CaseDetail';

const CaseDetailPage = (): JSX.Element => (
  <PageContentLayout title="Case Detail">
    <CaseDetail />
  </PageContentLayout>
);

export default CaseDetailPage;
