import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { Alert } from 'uswds-react';
import { COLUMN_9_CLASS_NAME } from '../../../../../../../constants';

export interface SelfCheckCaseDetailAuthorizedContentProps {
  className?: ClassValue;
}

const baseClassName = 'case-detail-content-self-check--authorized';
const CLASS_NAMES = {
  base: baseClassName,
  textBody: classNames(`${baseClassName}__text-body`, 'margin-top-3', COLUMN_9_CLASS_NAME),
};

const SelfCheckCaseDetailAuthorizedContent = ({
  className = undefined,
}: SelfCheckCaseDetailAuthorizedContentProps): JSX.Element => (
  <div className={classNames(CLASS_NAMES.base, className)}>
    <div role="alert" aria-atomic="true">
      <Alert status="success">
        <Alert.Heading>Congratulations!</Alert.Heading>
        <Alert.Text className={COLUMN_9_CLASS_NAME}>
          Self Check confirmed that you are eligible to work in the United States. For more information on how we were
          able to confirm your work eligibility, read below.
        </Alert.Text>
      </Alert>
    </div>
    <div className={CLASS_NAMES.textBody}>
      <h3>Work Authorization Details</h3>
      <p>
        Self Check compared the information you provided to U.S. government records and can confirm that, based on the
        information you provided, you are eligible to work in the United States.
      </p>
      <p>
        If you are hired today by an E‑Verify participating employer and you use the same documents and information
        provided, you will likely be instantly work authorized when your employer checks your information using
        E‑Verify.
      </p>
      <p>
        In the event that you are not instantly work authorized, please work with your employer to ensure that your
        information was entered correctly and, if necessary, follow the step outlined by E‑Verify to resolve any issues.
      </p>
      <p>
        This doesn't mean that you are guaranteed to pass through E‑Verify without issue. A number of things can happen
        between now and when a future employer checks your information using E‑Verify that may cause you to get a
        mismatch. Those things include name changes, citizenship status changes, expiration of work authorization, or
        simple data entry error when your employer is entering your information into E‑Verify. It is important that you
        keep your records up to date with the government to ensure an accurate employment verification process.
      </p>
    </div>
  </div>
);

export default SelfCheckCaseDetailAuthorizedContent;
