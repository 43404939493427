import {
  USCISANumberFieldName,
  countryOfIssuanceFieldName,
  documentExpirationDateFieldName,
  foreignPassportNumberFieldName,
  i551NumberFieldName,
  i766NumberFieldName,
  i94NumberFieldName,
  SelfCheckFormDocumentDetailsFieldName,
  SelfCheckFormDocumentSelectionFieldName,
  usPassportNumberFieldName,
  visaNumberFieldName,
} from '../constants';

/* eslint-disable import/prefer-default-export */
export const FIELD_ORDER: (SelfCheckFormDocumentSelectionFieldName | SelfCheckFormDocumentDetailsFieldName)[] = [
  USCISANumberFieldName,
  i551NumberFieldName,
  i766NumberFieldName,
  i94NumberFieldName,
  usPassportNumberFieldName,
  foreignPassportNumberFieldName,
  countryOfIssuanceFieldName,
  documentExpirationDateFieldName,
  visaNumberFieldName,
];
