import React from 'react';
import { Button, ButtonGroup, Modal } from '@ver-uds/react';
import { Alert } from 'uswds-react';
import { useHistory } from 'react-router-dom';
import paths from '../../routing/paths';

export interface SelfCheckNoticeModalProps {
  handleClose: () => void;
  isOpen: boolean;
}

export const MODAL_ID = 'self-check-notice-modal';

const baseClassName = MODAL_ID;
export const CLASS_NAMES = {
  base: baseClassName,
  contentSection: `${baseClassName}__content-section`,
  textSection: `${baseClassName}__text-section`,
};

const SelfCheckNoticeModal = ({ handleClose, isOpen }: SelfCheckNoticeModalProps): JSX.Element => {
  const history = useHistory();
  return (
    <Modal id={MODAL_ID} header="Self Check Notice" open={isOpen} close={handleClose}>
      <Modal.Content className={CLASS_NAMES.contentSection}>
        <div className={CLASS_NAMES.textSection}>
          <Alert status="warning">
            <Alert.Text className="df" />
            You cannot perform Self Check when you have Self Lock enabled. Please unlock your Social Security number
            before continuing with a Self Check process.
          </Alert>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <ButtonGroup>
          <Button
            onClick={(): void => {
              history.push(paths.selfLock);
            }}
            className={CLASS_NAMES.base}
            variant="secondary"
          >
            Navigate to Self Lock
          </Button>
          <Button onClick={handleClose} className={CLASS_NAMES.base} variant="primary">
            Close
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default SelfCheckNoticeModal;
