import React from 'react';
import { Modal } from '@ver-uds/react';
import classNames from 'classnames';

export interface PrivacyPolicyProps {
  isOpen: boolean;
  close: () => void;
}

const baseClassName = 'privacy-policy';
export const CLASS_NAMES = {
  base: baseClassName,
  boldText: classNames(`${baseClassName}__bold-text`),
};

const PrivacyPolicyModal = ({ isOpen, close }: PrivacyPolicyProps): JSX.Element => {
  return (
    <Modal id="centered-modal" header="DHS PRIVACY NOTICE" open={isOpen} close={close} hasDismissButton>
      <Modal.Content>
        <h4>myE‑Verify Identity Authentication</h4>

        <p>
          <span className="text-bold">AUTHORITIES: </span>
          Section 404 of the Illegal Immigration Reform and Immigrant Responsibility Act of 1996 (IIRIRA) authorizes us
          to collect your information.
        </p>

        <p>
          <span className="text-bold">PURPOSE: </span>
          The primary purpose for providing the requested information is to authenticate your identity in order to
          provide you with access to the full range of myE‑Verify services. DHS uses a private sector identity assurance
          provider to authenticate your identity. DHS receives a response from the identity assurance provider
          containing the result of the identity authentication. DHS stores your Social Security number and date of birth
          in a non‑readable format as part of the identity authentication process. This is why you may need to supply
          your Social Security number or date of birth again to use some myE‑Verify features.
        </p>
        <p>
          <span className="text-bold">DISCLOSURE: </span>
          The information you provide is voluntary. However, failure to provide information, including your Social
          Security number, may prevent you from successfully authenticating your identity, which limits your ability to
          access myE‑Verify services.
        </p>

        <p>
          <span className="text-bold">ROUTINE USES: </span>
          DHS may share the information you provide on this form with other Federal, state, local, and foreign
          government agencies and authorized organizations. DHS also uses and discloses the information you provide for
          identity authentication with a third‑party private sector identity assurance provider under contract with
          USCIS for the purpose of authenticating your identity. DHS follows approved routine uses described in the
          associated published system of records notice [DHS/USCIS-011 E‑Verify Program and DHS/ALL-037 E‑Authentication
          System of Records] and the published privacy impact assessments [DHS/USCIS/PIA-030(e) myE‑Verify and
          subsequent updates], which you can find at <a href="https://www.dhs.gov/privacy">www.dhs.gov/privacy</a>. DHS
          may also share this information, as appropriate, for law enforcement purposes or in the interest of national
          security.
        </p>
      </Modal.Content>
    </Modal>
  );
};

export default PrivacyPolicyModal;
