import React, { ComponentPropsWithoutRef } from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@ver-uds/react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { useConfiguration } from '../../../../../../../../services/BaseApi';
import paths from '../../../../../../../../services/paths.json';
import { normalizeURL } from '../../../../../../../../utils/routing';

export const FAN_DOWNLOAD_BUTTON_TEXT = 'Download Further Action Notice';

export interface FANDownloadButtonProps {
  className?: ClassValue;
  variant?: ComponentPropsWithoutRef<typeof Button>['variant'];
}

const baseClassName = 'fan-download-button';
const CLASS_NAMES = {
  base: baseClassName,
};

const FANDownloadButton = ({ className = undefined, variant = 'secondary' }: FANDownloadButtonProps): JSX.Element => {
  const configuration = useConfiguration();
  return (
    <Button
      className={classNames(CLASS_NAMES.base, className)}
      as="a"
      href={normalizeURL(`${configuration.basePath}${paths.documents.furtherActionNotice}`)}
      variant={variant}
      iconLeft={<FontAwesomeIcon icon={faDownload} />}
    >
      {FAN_DOWNLOAD_BUTTON_TEXT}
    </Button>
  );
};

export default FANDownloadButton;
