import { useEffect, useState } from 'react';
import { SortingRule } from 'react-table';
import { TableProps } from '@ver-uds/react/dist/Table/Table';
import { AccessorMap } from './constants';
import { getVisibleDataSlice, sortByKey } from './util';

type TablePaginationProp<D extends Record<string, unknown>> = Required<Pick<TableProps<D>, 'pagination'>>['pagination'];

/* eslint-disable import/prefer-default-export */
export interface PaginationState<D extends Record<string, unknown>> {
  data: D[];
  onSortChange: (sortBy: SortingRule<D>[]) => void;
  manual?: TablePaginationProp<D>['manual'];
}

const handleChange = <D extends Record<string, unknown>>({
  data,
  dataTransformers,
  setVisibleData,
  tablePageIndex,
  tablePageSize,
  tableSortBy,
}: {
  data: D[];
  dataTransformers?: AccessorMap<D>;
  setVisibleData: (data: D[]) => void;
  tablePageIndex: number;
  tablePageSize: number;
  tableSortBy: SortingRule<D>[];
}): void => {
  const sortedData = tableSortBy.reduce((sortedDataAcc, { id, desc }) => {
    const { [id]: accessor } = dataTransformers || {};
    return sortByKey(sortedDataAcc, id as keyof D, desc, accessor);
  }, data);
  setVisibleData(getVisibleDataSlice({ data: sortedData, tablePageIndex, tablePageSize }));
};

export const usePaginationState = <D extends Record<string, unknown>>({
  data,
  dataTransformers,
}: {
  data: D[];
  dataTransformers?: AccessorMap<D>;
}): PaginationState<D> => {
  const [tablePageIndex, setTablePageIndex] = useState(0);
  const [tablePageSize, setTablePageSize] = useState(1);
  const [tableSortBy, setTableSortBy] = useState<SortingRule<D>[]>([]);

  const initialVisibleData = getVisibleDataSlice({ data, tablePageIndex, tablePageSize });
  const [visibleData, setVisibleData] = useState<D[]>(initialVisibleData);

  const rowCount = data.length;
  const tablePageCount = Math.ceil(rowCount / tablePageSize);

  useEffect(() => {
    handleChange({ data, dataTransformers, setVisibleData, tablePageIndex, tablePageSize, tableSortBy });
  }, [data, dataTransformers, setVisibleData, tablePageIndex, tablePageSize, tableSortBy]);

  return {
    data: visibleData,
    onSortChange: (sortBy: SortingRule<D>[]): void => setTableSortBy(sortBy),
    manual: {
      onPageChange: ({ pageIndex, pageSize }): void => {
        setTablePageIndex(pageIndex);
        setTablePageSize(pageSize);
      },
      rowCount,
      pageCount: tablePageCount,
    },
  };
};
