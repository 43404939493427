import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import get from 'lodash/get';
import { CitizenshipStatus, DocumentType, SelfCheckCaseResponseData } from '../../../services/SelfCheckApi';
import { padIsoDateString } from '../../../utils/dates';
import {
  documentTypeFieldName,
  documentExpirationDateFieldName,
  SelfCheckFormData,
  SelfCheckFormDocumentDetailsFormData,
  SelfCheckFormDocumentSelectionFormData,
  SelfCheckFormPage,
  DocumentWithExpirationDate,
  UserInfoFormData,
} from './constants';
import SelfCheckFormCitizenshipStatus from './SelfCheckFormCitizenshipStatus/SelfCheckFormCitizenshipStatus';
import SelfCheckFormDocumentSelectionMarkup from './SelfCheckFormDocumentSelection/SelfCheckFormDocumentSelectionMarkup';
import SelfCheckFormDocumentDetailsMarkup from './SelfCheckFormDocumentDetails/SelfCheckFormDocumentDetailsMarkup';
import SelfCheckFormReview from './SelfCheckFormReview/SelfCheckFormReview';
import SelfCheckFormConfirmIdentity from './SelfCheckFormConfirmIdentity/SelfCheckFormConfirmIdentity';
import SelfCheckFormHeader from './SelfCheckFormHeader/SelfCheckFormHeader';

const isDocumentWithExpirationDate = (
  documentDetails: SelfCheckFormDocumentDetailsFormData,
): documentDetails is DocumentWithExpirationDate => {
  return (documentDetails as DocumentWithExpirationDate)[documentExpirationDateFieldName] !== undefined;
};

export interface SelfCheckFormProps {
  caseData?: SelfCheckCaseResponseData;
  className?: ClassValue;
  formData: SelfCheckFormData;
  formPage: SelfCheckFormPage;
  onBack: () => void;
  onSubmit: (data: SelfCheckFormData) => void;
  onCaseSubmitSuccess: (caseData?: SelfCheckCaseResponseData) => void;
}

const baseClassName = 'self-check-form';
const CLASS_NAMES = {
  base: baseClassName,
  header: classNames(`${baseClassName}__header`),
};

const SelfCheckForm = ({
  caseData = undefined,
  className = undefined,
  formData,
  formPage,
  onBack,
  onSubmit,
  onCaseSubmitSuccess,
}: SelfCheckFormProps): JSX.Element => {
  const citizenshipStatus = get(formData, SelfCheckFormPage.CITIZENSHIP_STATUS);
  const selectedDocument: DocumentType = get(formData, [SelfCheckFormPage.DOCUMENT_SELECTION, documentTypeFieldName]);
  const identity = get(formData, [SelfCheckFormPage.CONFIRM_IDENTITY]);

  const isUsCitizenOrUsNonCitizen = (citizenStatus: CitizenshipStatus): boolean => {
    return citizenStatus === CitizenshipStatus.US_CITIZEN || citizenStatus === CitizenshipStatus.US_NON_CITIZEN;
  };

  const isCitizenshipStatusChangeBreaking = (
    prevCitizenshipStatus: CitizenshipStatus,
    newCitizenshipStatus: CitizenshipStatus,
  ): boolean => {
    return !(isUsCitizenOrUsNonCitizen(prevCitizenshipStatus) && isUsCitizenOrUsNonCitizen(newCitizenshipStatus));
  };

  const isOtherCitizenshipStatus = (
    prevCitizenshipStatus: CitizenshipStatus,
    newCitizenshipStatus: CitizenshipStatus,
  ): boolean => {
    return isCitizenshipStatusChangeBreaking(prevCitizenshipStatus, newCitizenshipStatus);
  };

  const handleCitizenshipSubmit = (newCitizenshipStatus: CitizenshipStatus): void => {
    const updatedFormData = {
      [SelfCheckFormPage.CITIZENSHIP_STATUS]: newCitizenshipStatus,
    };

    if (
      citizenshipStatus &&
      citizenshipStatus !== newCitizenshipStatus &&
      isOtherCitizenshipStatus(citizenshipStatus, newCitizenshipStatus)
    ) {
      onSubmit({
        ...updatedFormData,
        [SelfCheckFormPage.DOCUMENT_SELECTION]: undefined,
        [SelfCheckFormPage.DOCUMENT_DETAILS]: undefined,
      });
    } else {
      onSubmit(updatedFormData);
    }
  };

  const handleSelectionSubmit = (data: SelfCheckFormDocumentSelectionFormData): void => {
    const updatedFormData = {
      [SelfCheckFormPage.DOCUMENT_SELECTION]: data,
    };

    if (selectedDocument && data[documentTypeFieldName] !== selectedDocument) {
      onSubmit({
        ...updatedFormData,
        [SelfCheckFormPage.DOCUMENT_DETAILS]: undefined,
      });
    } else {
      onSubmit(updatedFormData);
    }
  };

  const handleDetailsSubmit = (data: SelfCheckFormDocumentDetailsFormData): void => {
    const dataWithSanitizedDate = data;
    if (isDocumentWithExpirationDate(dataWithSanitizedDate)) {
      dataWithSanitizedDate[documentExpirationDateFieldName] = padIsoDateString(
        dataWithSanitizedDate[documentExpirationDateFieldName],
      );
    }

    onSubmit({
      [SelfCheckFormPage.DOCUMENT_DETAILS]: dataWithSanitizedDate,
    });
  };

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <SelfCheckFormHeader
        className={CLASS_NAMES.header}
        formPage={formPage}
        citizenshipStatus={citizenshipStatus}
        documentType={selectedDocument}
      />
      {formPage === SelfCheckFormPage.CONFIRM_IDENTITY && (
        <SelfCheckFormConfirmIdentity
          identityFormData={identity}
          onSubmit={(data: UserInfoFormData): void => {
            onSubmit({
              [SelfCheckFormPage.CONFIRM_IDENTITY]: data,
            });
          }}
        />
      )}
      {formPage === SelfCheckFormPage.CITIZENSHIP_STATUS && (
        <SelfCheckFormCitizenshipStatus
          data={get(formData, SelfCheckFormPage.CITIZENSHIP_STATUS)}
          onBack={onBack}
          onSubmit={handleCitizenshipSubmit}
        />
      )}
      {citizenshipStatus && formPage === SelfCheckFormPage.DOCUMENT_SELECTION && (
        <SelfCheckFormDocumentSelectionMarkup
          citizenshipStatus={citizenshipStatus}
          data={get(formData, SelfCheckFormPage.DOCUMENT_SELECTION)}
          onCancel={onBack}
          onSubmit={handleSelectionSubmit}
        />
      )}
      {formPage === SelfCheckFormPage.DOCUMENT_DETAILS && (
        <SelfCheckFormDocumentDetailsMarkup
          selectedDocument={selectedDocument}
          data={get(formData, SelfCheckFormPage.DOCUMENT_DETAILS)}
          onCancel={onBack}
          identity={identity}
          onSubmit={handleDetailsSubmit}
        />
      )}
      {formPage === SelfCheckFormPage.REVIEW && (
        <SelfCheckFormReview
          caseData={caseData}
          data={formData}
          documentType={selectedDocument}
          onCancel={onBack}
          onCaseSubmitSuccess={onCaseSubmitSuccess}
        />
      )}
    </div>
  );
};

export default SelfCheckForm;
