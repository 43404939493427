import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';

export interface ImageProps {
  alt: string;
  src: string;
}

export interface ImageWrapperProps {
  className?: ClassValue;
  images: ImageProps[];
  minColumnSize?: number;
}

const baseClassName = 'image-wrapper';
const CLASS_NAMES = {
  base: classNames(baseClassName, 'grid-row', 'grid-gap', 'margin-top-0'),
  image: classNames(`${baseClassName}__image`, 'grid-col-9', 'margin-top-2'),
  defaultColumnSize: 'tablet:grid-col-5',
};

const ImageWrapper = ({ className = undefined, images, minColumnSize = undefined }: ImageWrapperProps): JSX.Element => (
  <div className={classNames(CLASS_NAMES.base, className)}>
    {images.map(({ src, alt }) => (
      <img
        className={classNames(
          CLASS_NAMES.image,
          minColumnSize ? `tablet:grid-col-${minColumnSize}` : CLASS_NAMES.defaultColumnSize,
        )}
        src={src}
        alt={alt}
        key={alt}
      />
    ))}
  </div>
);

export default ImageWrapper;
