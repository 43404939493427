import { useContext, useMemo } from 'react';
import { QueryObserverResult, useMutation, UseMutationResult, useQueries, useQuery, UseQueryResult } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import createError, { HttpError } from 'http-errors';
import get from 'lodash/get';
import { injectURLParams, normalizeURL } from '../../utils/routing';
import BaseApi, { useConfiguration } from '../BaseApi';
import paths from '../paths.json';
import { AuthenticationContext } from '../../context/Authentication/AuthenticationContext';
import { createEmptyFile } from '../../utils/files';
import { doGet, doPost, mapErrorToHttpError } from '../util';
import { DocumentUploadSubmissionFields } from '../../components/DocumentUpload/DocumentUploadForm/DocumentUploadFormMarkup';

export enum RawCaseStatus {
  CASE_INCOMPLETE = 'case incomplete',
  CASE_INCOMPLETE_ALTERNATE = 'case_incomplete',
  REVIEW_UPDATE_EMPLOYEE_DATA = 'review and update employee data',
  PHOTO_MATCHING_REQUIRED = 'photo matching required',
  DUPLICATE_CASE = 'duplicate case',
  CLOSE_AND_RESUBMIT = 'close and resubmit',
  CLOSE_AND_RESUBMIT_ALTERNATE = 'close_and_resubmit',
  CLOSED = 'closed',
  EMPLOYMENT_AUTHORIZED = 'employment authorized',
  EMPLOYMENT_AUTHORIZED_ALTERNATE = 'employment_authorized',
  CHECK_BACK_LATER = 'check back later',
  DHS_VERIFICATION_IN_PROCESS = 'dhs verification in process',
  VERIFICATION_IN_PROCESS = 'verification in process',
  FINAL_NONCONFIRMATION = 'final nonconfirmation',
  FINAL_NONCONFIRMATION_ALTERNATE = 'final_nonconfirmation',
  DHS_FINAL_NONCONFIRMATION = 'dhs final nonconfirmation',
  NO_SHOW = 'no_show',
  DHS_NO_SHOW = 'dhs no show',
  NO_ACTION_FNC = 'no_action_fnc',
  NOT_AUTHORIZED_EMPLOYMENT = 'not_authorized_employment',
  SSA_FINAL_NONCONFIRMATION = 'ssa final nonconfirmation',
  EMPLOYEE_REFERRED_DHS = 'employee referred (dhs)',
  EMPLOYEE_REFERRED_DHS_ALTERNATE_ONE = 'employee referred to dhs',
  EMPLOYEE_REFERRED_DHS_ALTERNATE_TWO = 'employee_referred_dhs',
  EMPLOYEE_REFERRED_SSA = 'employee referred (ssa)',
  EMPLOYEE_REFERRED_SSA_ALTERNATE_ONE = 'employee referred to ssa',
  EMPLOYEE_REFERRED_SSA_ALTERNATE_TWO = 'employee_referred_ssa',
  EMPLOYEE_REFERRED_DHS_AND_SSA = 'employee referred (dhs and ssa)',
  EMPLOYEE_REFERRED_DHS_AND_SSA_ALTERNATE = 'employee_referred_dhs_and_ssa',
  EMPLOYEE_REFERRED_DHS_OR_SSA = 'employee referred (dhs or ssa)',
  EMPLOYEE_REFERRED_DHS_OR_SSA_ALTERNATE = 'employee_referred_dhs_or_ssa',
  TENTATIVE_NONCONFIRMATION_DHS = 'tentative nonconfirmation (dhs)',
  TENTATIVE_NONCONFIRMATION_DHS_ALTERNATE_ONE = 'dhs tentative nonconfirmation',
  TENTATIVE_NONCONFIRMATION_DHS_ALTERNATE_TWO = 'dhs tentative nonconfirmation (tnc)',
  TENTATIVE_NONCONFIRMATION_DHS_ALTERNATE_THREE = 'tentative_nonconfirmation_dhs',
  TENTATIVE_NONCONFIRMATION_SSA = 'tentative nonconfirmation (ssa)',
  TENTATIVE_NONCONFIRMATION_SSA_ALTERNATE_ONE = 'ssa tentative nonconfirmation (tnc)',
  TENTATIVE_NONCONFIRMATION_SSA_ALTERNATE_TWO = 'tentative_nonconfirmation_ssa',
  TENTATIVE_NONCONFIRMATION_DHS_AND_SSA = 'tentative nonconfirmation (dhs and ssa)',
  TENTATIVE_NONCONFIRMATION_DHS_AND_SSA_ALTERNATE = 'tentative_nonconfirmation_dhs_and_ssa',
  TENTATIVE_NONCONFIRMATION_DHS_OR_SSA = 'tentative nonconfirmation (dhs or ssa)',
  TENTATIVE_NONCONFIRMATION_DHS_OR_SSA_ALTERNATE = 'tentative_nonconfirmation_dhs_or_ssa',
  CASE_IN_CONTIUNANCE = 'continuance',
  CASE_IN_CONTINUANCE_DHS = 'case in continuance (dhs)',
  CASE_IN_CONTINUANCE_DHS_ALTERNATE = 'dhs case in continuance',
  CASE_IN_CONTINUANCE_SSA = 'case in continuance (ssa)',
  CASE_IN_CONTINUANCE_SSA_ALTERNATE = 'ssa case in continuance',
  CASE_IN_CONTINUANCE_DHS_AND_SSA = 'case in continuance (dhs and ssa)',
  CASE_IN_CONTINUANCE_DHS_OR_SSA = 'case in continuance (dhs or ssa)',
  CASE_IN_CONTINUANCE_DHS_OR_SSA_ALTERNATE = 'case_in_continuance_dhs_or_ssa',
  EMPLOYEE_REFERRED_CONTINUANCE = 'employee_referred_continuance',
  EMPLOYEE_REFERRED_DHS_AND_CASE_IN_CONTINUANCE_SSA = 'employee referred (dhs) and case in continuance (ssa)',
  CASE_IN_CONTINUANCE_DHS_AND_EMPLOYEE_REFERRED_SSA = 'case in continuance (dhs) and employee referred (ssa)',
  UNABLE_TO_PROCESS = 'unable to process',
  UNKNOWN = 'unknown',
}

export enum ReadableCaseStatus {
  CASE_INCOMPLETE = 'CASE_INCOMPLETE',
  CLOSE_AND_RESUBMIT = 'CLOSE_AND_RESUBMIT',
  CLOSED = 'CLOSED',
  EMPLOYMENT_AUTHORIZED = 'EMPLOYMENT_AUTHORIZED',
  CHECK_BACK_LATER = 'CHECK_BACK_LATER',
  FINAL_NONCONFIRMATION = 'FINAL_NONCONFIRMATION',
  EMPLOYEE_REFERRED_DHS = 'EMPLOYEE_REFERRED_DHS',
  EMPLOYEE_REFERRED_SSA = 'EMPLOYEE_REFERRED_SSA',
  EMPLOYEE_REFERRED_DHS_AND_SSA = 'EMPLOYEE_REFERRED_DHS_AND_SSA',
  EMPLOYEE_REFERRED_DHS_OR_SSA = 'EMPLOYEE_REFERRED_DHS_OR_SSA',
  EMPLOYEE_REFERRED_CONTINUANCE = 'EMPLOYEE_REFERRED_CONTINUANCE',
  TENTATIVE_NONCONFIRMATION_DHS = 'TENTATIVE_NONCONFIRMATION_DHS',
  TENTATIVE_NONCONFIRMATION_SSA = 'TENTATIVE_NONCONFIRMATION_SSA',
  TENTATIVE_NONCONFIRMATION_DHS_AND_SSA = 'TENTATIVE_NONCONFIRMATION_DHS_AND_SSA',
  TENTATIVE_NONCONFIRMATION_DHS_OR_SSA = 'TENTATIVE_NONCONFIRMATION_DHS_OR_SSA',
  CONTINUANCE = 'CONTINUANCE',
  UNKNOWN = 'UNKNOWN',
}

export interface CaseStatusData {
  caseStatus: RawCaseStatus;
}

export enum TncType {
  US_PASSPORT = 'US_PASSPORT',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  JBL = 'JBL',
  NATZ = 'NATZ',
  OTHER = 'OTHER',
}

export enum UploadDocumentType {
  US_PASSPORT = 'US_PASSPORT',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  NATZ = 'NATZ',
  UNKNOWN = 'UNKNOWN',
}

export interface CaseTncTypeData {
  tncType: TncType;
  uploadDocumentType: UploadDocumentType | null;
}

export interface Case {
  date: string;
  caseNumber: string;
  companyName: string;
  state: string | null;
  caseStatus: RawCaseStatus | null;
  type: string;
}

export interface CaseUploads {
  count: number;
}

export interface CaseStatusResponse {
  data: CaseStatusData;
}

export interface CaseTncTypeResponse {
  data: CaseTncTypeData;
}

export interface CasesResponse {
  data: Case[];
}

export interface CaseUploadsResponse {
  data: CaseUploads;
}

class CaseApi extends BaseApi {
  private getCaseTrackerEndpoint(caseNumber: string): string {
    const pathWithParams = injectURLParams(paths.cases.getCaseStatus, { caseNumber });
    return normalizeURL(`${this.configuration.basePath}${pathWithParams}`);
  }

  private getCaseTncTypeEndpoint(caseNumber: string): string {
    const pathWithParams = injectURLParams(paths.cases.getCaseTncType, { caseNumber });
    return normalizeURL(`${this.configuration.basePath}${pathWithParams}`);
  }

  private getCasesEndpoint(): string {
    return this.getEndpoint(paths.cases.getCases);
  }

  private getOpenSelfCheckCaseEndpoint(): string {
    return normalizeURL(`${this.configuration.basePath}/${paths.cases.getOpenSelfCheckCases}`);
  }

  private getUploadCaseDocumentsEndpoint(caseNumber: string): string {
    const pathWithParams = injectURLParams(paths.cases.uploadCaseDocuments, { caseNumber });
    return normalizeURL(`${this.configuration.basePath}${pathWithParams}`);
  }

  private getCaseUploadsEndpoint(caseNumber: string): string {
    const pathWithParams = injectURLParams(paths.cases.getCaseUploads, { caseNumber });
    return normalizeURL(`${this.configuration.basePath}/${pathWithParams}`);
  }

  async getCaseStatus(caseNumber: string): Promise<CaseStatusData> {
    const endpoint: string = this.getCaseTrackerEndpoint(caseNumber);
    try {
      const resp: AxiosResponse<CaseStatusResponse> = await axios.get(endpoint);
      return get(resp, 'data.data');
    } catch (err) {
      throw createError(get(err, 'response.status', 500), (err as HttpError).message);
    }
  }

  async getCaseTncType(caseNumber: string): Promise<CaseTncTypeData> {
    const endpoint: string = this.getCaseTncTypeEndpoint(caseNumber);
    try {
      const resp: AxiosResponse<CaseTncTypeResponse> = await axios.get(endpoint, {
        withCredentials: true,
      });
      return get(resp, 'data.data');
    } catch (err) {
      throw createError(get(err, 'response.status', 500), (err as HttpError).message);
    }
  }

  async getCases(): Promise<Case[]> {
    const endpoint = this.getCasesEndpoint();
    try {
      return (await doGet<CasesResponse>(endpoint)) || [];
    } catch (err) {
      throw mapErrorToHttpError(err);
    }
  }

  async getOpenSelfCheckCase(): Promise<Case | undefined> {
    const endpoint = this.getOpenSelfCheckCaseEndpoint();
    try {
      const resp: AxiosResponse<CasesResponse> = await axios.get(endpoint, {
        withCredentials: true,
      });
      return get(resp, 'data.data[0]');
    } catch (err) {
      throw createError(get(err, 'response.status', 500), (err as HttpError).message);
    }
  }

  async uploadCaseDocuments(
    caseNumber: string,
    files: File[],
    phoneNumber: string,
    emailAddress: string,
  ): Promise<void> {
    const url = this.getUploadCaseDocumentsEndpoint(caseNumber);
    try {
      if (files.length < 1 || files.length > 2) {
        throw createError(400);
      }
      const formData = new FormData();
      /**
       * Need to send two files even if "second" is not provided by user.
       * API will treat empty files as not provided.
       */
      formData.append('secondDocument', createEmptyFile());

      const payload: FormData = files.reduce((formDataAccumulator, file, i) => {
        if (i === 0) {
          formDataAccumulator.append('firstDocument', file);
        }
        if (i === 1) {
          formDataAccumulator.set('secondDocument', file);
        }
        return formDataAccumulator;
      }, formData);
      payload.append('phoneNumber', phoneNumber);
      payload.append('emailAddress', emailAddress);
      await doPost(url, payload);
    } catch (err) {
      throw createError(get(err, 'status', get(err, 'response.status', 500)));
    }
  }

  async getCaseUploads(caseNumber: string): Promise<CaseUploads | undefined> {
    const endpoint = this.getCaseUploadsEndpoint(caseNumber);
    try {
      const resp: AxiosResponse<CasesResponse> = await axios.get(endpoint, {
        withCredentials: true,
      });
      return get(resp, 'data.data');
    } catch (err) {
      throw createError(get(err, 'response.status', 500), (err as HttpError).message);
    }
  }
}

const CASE_NUMBER_VALID_REGEX = /^\d{13}[A-Za-z]{2}$/;
export const isCaseNumberValid = (caseNumber: string | null): boolean =>
  typeof caseNumber === 'string' && CASE_NUMBER_VALID_REGEX.test(caseNumber);

const useCaseApi = (): CaseApi => {
  const configuration = useConfiguration();
  const authContext = useContext(AuthenticationContext);
  authContext.refreshLastAuthenticatedTime();
  return useMemo(() => new CaseApi(configuration), [configuration]);
};

export const getCaseStatusQueryID = 'getCaseStatus';
export const useCaseTrackerQuery = (caseNumber: string | null): QueryObserverResult<CaseStatusData> => {
  const api = useCaseApi();

  return useQuery([getCaseStatusQueryID, caseNumber], () => api.getCaseStatus(caseNumber || ''), {
    enabled: isCaseNumberValid(caseNumber) && api.isConfigurationValid(),
  });
};

export const getCaseTncTypeQueryId = 'getCaseTncType';
export const useCaseTncTypeQuery = (caseNumber: string | null): QueryObserverResult<CaseTncTypeData> => {
  const api = useCaseApi();

  return useQuery([getCaseTncTypeQueryId, caseNumber], () => api.getCaseTncType(caseNumber || ''), {
    enabled: api.isConfigurationValid(),
  });
};

export const getCasesQueryId = 'getCases';
export const useGetCasesQuery = (): QueryObserverResult<Case[]> => {
  const api = useCaseApi();
  const { isAuthenticated } = useContext(AuthenticationContext);

  return useQuery(getCasesQueryId, () => api.getCases(), {
    enabled: isAuthenticated() && api.isConfigurationValid(),
  });
};

export const getOpenSelfCheckCaseQueryID = 'getOpenSelfCheckCase';
export const useGetOpenSelfCheckCaseQuery = (): QueryObserverResult<Case | undefined> => {
  const api = useCaseApi();
  const { isAuthenticated } = useContext(AuthenticationContext);

  return useQuery(getOpenSelfCheckCaseQueryID, () => api.getOpenSelfCheckCase(), {
    enabled: isAuthenticated() && api.isConfigurationValid(),
    // This allows the cached value to be used while the current value refetches in the background.
    // When performance for this request is improved, this option could be removed.
    keepPreviousData: true,
  });
};

export const useUploadCaseDocumentsMutationId = 'uploadCaseDocuments';
export const useUploadCaseDocumentsMutation = (
  caseNumber: string,
): UseMutationResult<void, Error, DocumentUploadSubmissionFields, void> => {
  const api = useCaseApi();
  const { isAuthenticated } = useContext(AuthenticationContext);

  return useMutation([useUploadCaseDocumentsMutationId, caseNumber], ({ files, phoneNumber, emailAddress }) => {
    if (!api.isConfigurationValid()) {
      throw createError(404);
    }
    if (!isAuthenticated) {
      throw createError(403);
    }
    return api.uploadCaseDocuments(caseNumber, files, phoneNumber, emailAddress);
  });
};

export const getCaseUploadsQueryId = 'getCaseUploads';
export const useGetCaseUploadsQuery = (caseNumber: string): QueryObserverResult<CaseUploads> => {
  const api = useCaseApi();
  const { isAuthenticated } = useContext(AuthenticationContext);
  return useQuery([getCaseUploadsQueryId, caseNumber], () => api.getCaseUploads(caseNumber), {
    enabled: isAuthenticated() && api.isConfigurationValid(),
    /**
     * Case upload counts are required for the open cases table.
     * In order to avoid waiting for fetches on every pagination change, the old value may be used during loading.
     */
    keepPreviousData: true,
  });
};

export const useGetCaseUploadsQueries = (caseNumbers: string[]): QueryObserverResult<CaseUploads>[] => {
  const api = useCaseApi();
  const { isAuthenticated } = useContext(AuthenticationContext);
  return useQueries(
    caseNumbers.map((caseNumber) => {
      return {
        enabled: isAuthenticated() && api.isConfigurationValid(),
        queryKey: [getCaseUploadsQueryId, caseNumber],
        queryFn: (): Promise<CaseUploads | undefined> => api.getCaseUploads(caseNumber),
        keepPreviousData: true,
      };
    }),
  ) as UseQueryResult<CaseUploads>[];
};
