import React from 'react';
import { Redirect } from 'react-router-dom';
import paths from '../../../../routing/paths';
import { useGetUserInfo } from '../../../../services/UserApi';
import { UserInfoFormData } from '../constants';
import SelfCheckFormConfirmIdentityMarkup from './SelfCheckFormConfirmIdentityMarkup';

export interface SelfCheckFormConfirmIdentityProps {
  onSubmit: (data: UserInfoFormData) => void;
  identityFormData?: UserInfoFormData;
}

const SelfCheckFormConfirmIdentity = ({
  onSubmit,
  identityFormData = undefined,
}: SelfCheckFormConfirmIdentityProps): JSX.Element => {
  const { error, isIdle, isLoading, data } = useGetUserInfo();

  if (error) {
    return <Redirect to={paths.error} />;
  }

  return (
    <>
      {!isLoading && !isIdle && (
        <SelfCheckFormConfirmIdentityMarkup onSubmit={onSubmit} userInfo={data} identityFormData={identityFormData} />
      )}
    </>
  );
};

export default SelfCheckFormConfirmIdentity;
