import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { Tag } from '@ver-uds/react';
import { ReadableCaseStatus } from '../../../../services/CaseApi';
import { mapReadableCaseStatusToTagText, mapReadableCaseStatusToTagVariant } from './util';

export interface CaseDetailTagProps {
  className?: ClassValue;
  caseStatus: ReadableCaseStatus;
}

const baseClassName = 'case-detail-tag';
const CLASS_NAMES = {
  base: baseClassName,
};

const CaseDetailTag = ({ className = undefined, caseStatus }: CaseDetailTagProps): JSX.Element => (
  <Tag className={classNames(CLASS_NAMES.base, className)} variant={mapReadableCaseStatusToTagVariant(caseStatus)}>
    {mapReadableCaseStatusToTagText(caseStatus)}
  </Tag>
);

export default CaseDetailTag;
