import React from 'react';
import { Redirect } from 'react-router-dom';
import get from 'lodash/get';
import paths from '../../../routing/paths';
import {
  SelfLockChallengeQuestion,
  SelfLockState,
  useSelfLockQuery,
  useSelfLockUpdateMutation,
  SelfLockLockPayload,
} from '../../../services/SelfLockApi';
import NotificationAlert from '../../../utils/NotificationAlert/NotificationAlert';
import SelfLockLockMarkup from './SelfLockLockMarkup';

export const SUCCESS_MESSAGE = 'Your Social Security Number has been successfully locked.';
export const UNEXPECTED_SERVER_ERROR_MESSAGE = 'An unexpected error occurred. Please try again.';

const SelfLockLock = (): JSX.Element => {
  const { error, isIdle, isLoading, data } = useSelfLockQuery();
  const {
    mutate,
    error: submissionError,
    isLoading: isSubmissionLoading,
    isSuccess: isSubmissionSuccess,
  } = useSelfLockUpdateMutation();

  if (submissionError) {
    return (
      <Redirect
        to={{
          pathname: paths.selfLock,
          state: { notificationAlert: new NotificationAlert('error', UNEXPECTED_SERVER_ERROR_MESSAGE) },
        }}
      />
    );
  }

  if (isSubmissionSuccess) {
    return (
      <Redirect
        to={{
          pathname: paths.selfLock,
          state: {
            notificationAlert: new NotificationAlert('success', SUCCESS_MESSAGE),
          },
        }}
      />
    );
  }

  if (error || (!isSubmissionLoading && get(data, 'state', SelfLockState.UNLOCKED) !== SelfLockState.UNLOCKED)) {
    return <Redirect to={paths.error} />;
  }

  const handleSubmit = (challengeQuestions: SelfLockChallengeQuestion[]): void => {
    const payload: SelfLockLockPayload = {
      state: SelfLockState.LOCKED,
      challengeQuestions,
    };

    mutate(payload);
  };

  return <>{!isLoading && !isIdle && <SelfLockLockMarkup onSubmit={handleSubmit} />}</>;
};

export default SelfLockLock;
