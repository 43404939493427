import React from 'react';
import classNames from 'classnames';
import { Accordion } from 'uswds-react';
import unrestrictedSsnCard from '../../../../../assets/img/documents/help/ssn/ssn-unrestricted.png';
import restrictedSsnCard from '../../../../../assets/img/documents/help/ssn/ssn-restricted.png';
import ImageWrapper from '../../../../ImageWrapper';
import HelpSectionContent from '../../../HelpSectionContent';
import { I9CentralFooter } from '../../../shared';

const baseClassName = 'ssn-help-section--self-check';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
  i9CentralFooter: classNames(`${baseClassName}__I-9-central-footer`, 'margin-top-3'),
};

const SelfCheckSsnHelpSection = (): JSX.Element => (
  <>
    <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
      Help with Social Security number (SSN)
    </Accordion.Heading>
    <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
      <p>
        <span className="text-bold">Social Security card (Unrestricted)</span>
        <br />
        Only Unrestricted Social Security cards are acceptable. Unrestricted Social Security cards are issued to U.S.
        citizens and individuals lawfully admitted to the United States on a permanent basis. This card shows a name and
        Social Security number and allows the individual to work for any employer.
      </p>
      <ImageWrapper images={[{ src: unrestrictedSsnCard, alt: 'Unrestricted Social Security card' }]} />
      <p>
        <span className="text-bold">Social Security card (Restricted)</span>
        <br />
        Restricted Social Security cards are not acceptable. Restricted Social Security cards are issued to individuals
        lawfully admitted to the U.S. on a temporary basis. This card shows a name and Social Security number and is
        stamped with one of the following restrictions:
      </p>
      <ul>
        <li>"VALID FOR WORK ONLY WITH DHS AUTHORIZATION"</li>
        <li>"VALID FOR WORK ONLY WITH INS AUTHORIZATION"</li>
        <li>"NOT VALID FOR EMPLOYMENT"</li>
      </ul>
      <ImageWrapper images={[{ src: restrictedSsnCard, alt: 'Restricted Social Security card' }]} />
      <I9CentralFooter className={CLASS_NAMES.i9CentralFooter} />
    </HelpSectionContent>
  </>
);

export default SelfCheckSsnHelpSection;
