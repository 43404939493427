import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { TagProps } from '@ver-uds/react/dist/Tag/Tag';
import { SelfLockState } from '../../services/SelfLockApi';

// eslint-disable-next-line import/prefer-default-export
export const mapSelfLockStateToTagVariant = (state: SelfLockState): TagProps['variant'] | undefined => {
  switch (state) {
    case SelfLockState.LOCKED:
      return 'success';
    case SelfLockState.UNLOCKED:
      return undefined;
    case SelfLockState.UNKNOWN:
    default:
      return 'error';
  }
};

export const mapSelfLockStateToTagIcon = (state: SelfLockState): TagProps['iconLeft'] | undefined => {
  let icon = null;
  if (state === SelfLockState.LOCKED) {
    icon = faLock;
  } else if (state === SelfLockState.UNLOCKED) {
    icon = faUnlock;
  }

  return icon ? <FontAwesomeIcon icon={icon} /> : undefined;
};
