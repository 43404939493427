import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@ver-uds/react';
import { useConfiguration } from '../../../../../../../../services/BaseApi';
import paths from '../../../../../../../../services/paths.json';
import { isConfigurationValid } from '../../../../../../../../services/util/configuration';
import { normalizeURL } from '../../../../../../../../utils/routing';

export interface SelfCheckReferredDownloadRDCButtonProps {
  className?: ClassValue;
}

const baseClassName = 'self-check-referred-download-rdc-button';
const CLASS_NAMES = {
  base: baseClassName,
};

const SelfCheckReferredDownloadRDCButton = ({
  className = undefined,
}: SelfCheckReferredDownloadRDCButtonProps): JSX.Element => {
  const configuration = useConfiguration();

  if (!isConfigurationValid(configuration)) {
    return <></>;
  }

  return (
    <Button
      className={classNames(CLASS_NAMES.base, className)}
      as="a"
      href={normalizeURL(`${configuration.basePath}${paths.documents.referralDateConfirmation}`)}
      iconLeft={<FontAwesomeIcon icon={faDownload} />}
      variant="secondary"
    >
      Referral Date Confirmation
    </Button>
  );
};

export default SelfCheckReferredDownloadRDCButton;
