import React from 'react';
import { Redirect } from 'react-router-dom';
import { ClassValue } from 'classnames/types';
import zipWith from 'lodash/zipWith';
import reject from 'lodash/reject';
import paths from '../../routing/paths';
import { useGetCasesQuery, useGetCaseUploadsQueries } from '../../services';
import CaseHistoryMarkup from './CaseHistoryMarkup';
import { isCaseOpen } from './util';

export interface CaseHistoryProps {
  className?: ClassValue;
}

const CaseHistory = ({ className = undefined }: CaseHistoryProps): JSX.Element => {
  const { data: cases, isError, isIdle, isLoading } = useGetCasesQuery();

  const caseUploadQueriesResults = useGetCaseUploadsQueries(
    cases?.filter(isCaseOpen).map((caseData) => caseData.caseNumber) || [],
  );
  const uploadCounts = caseUploadQueriesResults.map((result) => result.data?.count);

  const closedCases = reject(cases, isCaseOpen);
  const openCases = zipWith(uploadCounts, cases?.filter(isCaseOpen) || [], (uploadCount, caseData) => {
    return {
      ...caseData,
      openCaseStatus: {
        uploadCount,
        caseStatus: caseData.caseStatus,
      },
    };
  });

  if (isError) {
    return <Redirect to={paths.error} />;
  }

  return (
    <>
      {!isIdle &&
        !isLoading &&
        caseUploadQueriesResults.every((caseUploadQuery) => !caseUploadQuery.isIdle && !caseUploadQuery.isLoading) && (
          <CaseHistoryMarkup className={className} cases={closedCases.concat(openCases)} />
        )}
    </>
  );
};

export default CaseHistory;
