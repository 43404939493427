import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { SelfCheckForm } from '../../context/Form/SelfCheck/SelfCheckFormContext';
import paths from '../../routing/paths';
import { useGetOpenSelfCheckCaseQuery } from '../../services/CaseApi';
import { CaseStatus, SelfCheckCaseResponseData, useGetCaseDetailsByCaseNumberQuery } from '../../services/SelfCheckApi';
import { SelfLockState, useSelfLockQuery } from '../../services/SelfLockApi';
import { injectURLParams } from '../../utils/routing';
import withSelfCheckFormConsumer from './hoc/withSelfCheckFormConsumer';
import SelfCheckFormContainer, { hasFormBeenStarted } from './SelfCheckForm/SelfCheckFormContainer';
import { REVERIFY_CASE_STATUS } from './SelfCheckForm/SelfCheckFormReview/SelfCheckFormReviewMarkup';
import SelfCheckNoticeModal from './SelfCheckNoticeModal';

export interface SelfCheckOpenCaseGateProps extends SelfCheckForm {
  caseNumber?: string;
}

const isCaseStatusMatch = (selfCheckCase?: SelfCheckCaseResponseData, caseStatus?: CaseStatus): boolean =>
  selfCheckCase?.caseStatus === caseStatus;

const isCaseClosed = (selfCheckCase?: SelfCheckCaseResponseData): boolean =>
  isCaseStatusMatch(selfCheckCase, CaseStatus.CLOSED);

const isCaseReverify = (selfCheckCase?: SelfCheckCaseResponseData): boolean =>
  isCaseStatusMatch(selfCheckCase, REVERIFY_CASE_STATUS);

const isCaseFNC = (selfCheckCase?: SelfCheckCaseResponseData): boolean =>
  isCaseStatusMatch(selfCheckCase, CaseStatus.FINAL_NONCONFIRMATION);

const isCaseCloseAndResubmit = (selfCheckCase?: SelfCheckCaseResponseData): boolean =>
  isCaseStatusMatch(selfCheckCase, CaseStatus.CLOSE_CASE_AND_RESUBMIT);

const SelfCheckOpenCaseGate = ({
  caseNumber = undefined,
  formData,
  ...selfCheckFormProps
}: SelfCheckOpenCaseGateProps): JSX.Element => {
  const { data: selfCheckCase, isError, isIdle, isLoading } = useGetCaseDetailsByCaseNumberQuery(caseNumber);

  if (isError) {
    return <Redirect to={paths.error} />;
  }

  const showReverifyView = isCaseReverify(selfCheckCase) && hasFormBeenStarted(formData);
  if (
    !caseNumber ||
    isCaseClosed(selfCheckCase) ||
    isCaseFNC(selfCheckCase) ||
    isCaseCloseAndResubmit(selfCheckCase) ||
    showReverifyView
  ) {
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    return <SelfCheckFormContainer {...selfCheckFormProps} formData={formData} />;
  }

  return <>{!isIdle && !isLoading && <Redirect to={injectURLParams(paths.caseDetail, { caseNumber })} />}</>;
};

const withOpenSelfCheckCase = (
  Component: (
    props: {
      caseNumber?: string;
    } & SelfCheckForm,
  ) => JSX.Element,
) => (selfCheckFormProps: SelfCheckForm): JSX.Element => {
  const { data: openCase, isError, isIdle, isLoading } = useGetOpenSelfCheckCaseQuery();
  const history = useHistory();
  const { data } = useSelfLockQuery();
  const [isSelfCheckNoticeModal, setIsSelfCheckNoticeModalsetIsHelpModalOpen] = useState(false);

  useEffect(() => {
    setIsSelfCheckNoticeModalsetIsHelpModalOpen(data?.state === SelfLockState.LOCKED);
  }, [data]);

  if (isError) {
    return <Redirect to={paths.error} />;
  }
  const handleSelfCheckModalClose = (): void => {
    history.push(paths.home);
    setIsSelfCheckNoticeModalsetIsHelpModalOpen(false);
  };
  return (
    <>
      {isSelfCheckNoticeModal ? (
        <SelfCheckNoticeModal isOpen={isSelfCheckNoticeModal} handleClose={handleSelfCheckModalClose} />
      ) : (
        <>
          {!isIdle && !isLoading && (
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            <Component {...selfCheckFormProps} caseNumber={openCase?.caseNumber} />
          )}
        </>
      )}
    </>
  );
};

export const UnwrappedSelfCheck = withOpenSelfCheckCase(SelfCheckOpenCaseGate);
export default withSelfCheckFormConsumer(UnwrappedSelfCheck);
