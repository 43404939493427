import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import partition from 'lodash/partition';
import { Case } from '../../services/CaseApi';
import CaseHistoryTable from './CaseHistoryTable';
import { isCaseOpen } from './util';

export interface CaseHistoryMarkupProps {
  className?: ClassValue;
  cases: Case[];
}

const baseClassName = 'case-history';
const CLASS_NAMES = {
  base: baseClassName,
  openCasesSection: `${baseClassName}__open-cases-table`,
  closedCasesSection: classNames(`${baseClassName}__closed-cases-table`, 'margin-top-6'),
};

const CaseHistoryMarkup = ({ className = undefined, cases }: CaseHistoryMarkupProps): JSX.Element => {
  const [openCases, closedCases] = partition(cases, isCaseOpen);
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <div className={CLASS_NAMES.openCasesSection}>
        <h2>Open Cases</h2>
        <CaseHistoryTable id="open-cases-table" cases={openCases} type="open" />
      </div>
      <div className={CLASS_NAMES.closedCasesSection}>
        <h2>Case History</h2>
        <CaseHistoryTable id="closed-cases-table" cases={closedCases} type="closed" />
      </div>
    </div>
  );
};

export default CaseHistoryMarkup;
