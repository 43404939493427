/* eslint-disable import/prefer-default-export */
export const redirectTo = (url: string): void => {
  window.location.assign(url);
};

export const normalizeURL = (url: string): string => {
  let protocol = '';
  let segment = url;
  // Match string starting with http:// or https://
  if (/^https?:\/\//.exec(url)) {
    // Isolate http:// or https:/ to avoid breaking the protocol
    protocol = url.substr(0, 7);
    segment = url.substr(7);
  }
  // Match all strings of repeated slashes
  const slashRegex = /\/+/g;
  return protocol + segment.replace(slashRegex, '/');
};

export const injectURLParams = (url: string, replaceMap: Record<string, string>): string =>
  Object.entries(replaceMap).reduce(
    (accumulator, [template, value]) => accumulator.replace(`:${template}`, value),
    url,
  );
