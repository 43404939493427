import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';

export interface SelfCheckCaseDetailFNCContentProps {
  className?: ClassValue;
}

const baseClassName = 'case-detail-content-self-check--fnc';
const CLASS_NAMES = {
  base: baseClassName,
};

const SelfCheckCaseDetailFNCContent = ({ className = undefined }: SelfCheckCaseDetailFNCContentProps): JSX.Element => (
  <div className={(classNames(CLASS_NAMES.base, className), 'grid-col-9')}>
    <p>
      This Self Check case has been closed without a resolution. If you want to take action to resolve the issue, create
      another Self Check case.
    </p>
    <p>
      Keep in mind, if you do not resolve the Final Nonconfirmation and you start work for an employer that uses
      E-Verify, your employer may get the same case result and you may need to resolve the issue.
    </p>
    <p>Remember, an employer may not require you to use Self Check for employment purposes.</p>
  </div>
);

export default SelfCheckCaseDetailFNCContent;
