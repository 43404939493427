import React from 'react';
import { Redirect } from 'react-router-dom';
import { ClassValue } from 'classnames/types';
import { HttpError } from 'http-errors';
import get from 'lodash/get';
import { SelfCheckCaseDetailVariant } from '../constants';
import paths from '../../../../../routing/paths';
import { ReadableCaseStatus, useCaseTncTypeQuery } from '../../../../../services/CaseApi';
import CaseDetailActionSectionMarkup from './CaseDetailActionSectionMarkup';

export interface CaseDetailActionSectionProps {
  className?: ClassValue;
  caseNumber: string;
  caseStatus: ReadableCaseStatus;
  selfCheckCaseDetailVariant?: SelfCheckCaseDetailVariant;
}

const is500Error = (error: HttpError): boolean => `${get(error, 'status')}`.startsWith('5');

const CaseDetailActionSection = ({
  className = undefined,
  caseNumber,
  caseStatus,
  selfCheckCaseDetailVariant = undefined,
}: CaseDetailActionSectionProps): JSX.Element => {
  const { data: caseTncTypeData, error } = useCaseTncTypeQuery(caseNumber);

  if (is500Error(error as HttpError)) {
    return <Redirect to={paths.error} />;
  }

  return (
    <CaseDetailActionSectionMarkup
      className={className}
      caseNumber={caseNumber}
      caseStatus={caseStatus}
      caseTncTypeData={caseTncTypeData}
      selfCheckCaseDetailVariant={selfCheckCaseDetailVariant}
    />
  );
};

export default CaseDetailActionSection;
