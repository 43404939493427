import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { Pagination, Table } from '@ver-uds/react';
import isEmpty from 'lodash/isEmpty';
import { usePaginationState } from '../../../context/Table/PaginationState';
import { Case } from '../../../services/CaseApi';
import CaseHistoryEmptySection from './CaseHistoryEmptySection';
import { closedCasesColumns, dataTransformers, openCasesColumns, TableData } from './CaseHistoryTableColumns';

export interface CaseHistoryTableProps {
  id: string;
  className?: ClassValue;
  cases: Case[];
  type: 'open' | 'closed';
}

const baseClassName = 'case-history-table';
const CLASS_NAMES = {
  base: baseClassName,
  emptySection: classNames(`${baseClassName}__empty`, 'margin-top-4'),
};

const CaseHistoryTable = ({ className = undefined, cases, type, id }: CaseHistoryTableProps): JSX.Element => {
  const { data: visibleCases, onSortChange, manual } = usePaginationState({
    data: cases as TableData[],
    dataTransformers,
  });

  if (isEmpty(visibleCases)) {
    return <CaseHistoryEmptySection className={CLASS_NAMES.emptySection} caseDescriptor={type} />;
  }

  const initialPageSize = 5;
  const isPaginationVisible = cases.length > initialPageSize;

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <Table
        id={id}
        columns={type === 'open' ? openCasesColumns : closedCasesColumns}
        data={visibleCases}
        sortable
        manualSort
        onSortChange={onSortChange}
        autoResetPage={false}
        initialState={{
          pageSize: initialPageSize,
          sortBy: [
            {
              id: 'date',
              desc: true,
            },
          ],
        }}
        pagination={{
          render: (
            dataSize,
            {
              pageCount,
              pageOptions,
              gotoPage,
              previousPage,
              nextPage,
              setPageSize,
              canPreviousPage,
              canNextPage,
              pageIndex,
              pageSize,
            },
          ): JSX.Element => (
            <>
              {isPaginationVisible && (
                <Pagination
                  dataSize={dataSize}
                  pageCount={pageCount}
                  pageOptions={pageOptions}
                  gotoPage={gotoPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  setPageSize={setPageSize}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  pageSizeOptions={[initialPageSize, 10, 20, 50]}
                />
              )}
            </>
          ),
          manual,
          isLoading: false,
        }}
      />
    </div>
  );
};

export default CaseHistoryTable;
