import classNames from 'classnames';
import get from 'lodash/get';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form, TextInputControl } from 'uswds-react';
import { getFieldError } from '../../../../../utils/forms';
import ActionFooter from '../../../../ActionFooter/ActionFooter';
import FormattedDateInput from '../../../../Form/FormattedDateInput/FormattedDateInput';
import { documentExpirationDateFieldName, fieldDefinitions, I766FormData, i766NumberFieldName } from '../../constants';
import { DocumentMarkupComponentProps } from '../DocumentDetailsFormTypes';

const baseClassName = 'self-check-document-details';
const CLASS_NAMES = {
  base: baseClassName,
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
  form: `${baseClassName}__form`,
  dateInputSpacing: 'margin-top-3',
};

const I766DocumentMarkup = ({ data, onSubmit, onCancel }: DocumentMarkupComponentProps<I766FormData>): JSX.Element => {
  const { control, errors, handleSubmit, register } = useForm<I766FormData>({
    mode: 'onSubmit',
    defaultValues: { ...(data || {}) },
  });

  const handleFormSubmit = handleSubmit((formData) => {
    onSubmit(formData);
  });

  const documentNumberErrorMessage = getFieldError(
    errors.i766Number,
    fieldDefinitions[i766NumberFieldName].errorMessages,
  );

  const dateInputErrorMessage = getFieldError(
    errors.documentExpirationDate,
    fieldDefinitions[documentExpirationDateFieldName].errorMessages,
  );

  return (
    <>
      <Form className={CLASS_NAMES.form} onSubmit={handleFormSubmit} variant="large">
        <TextInputControl
          id={i766NumberFieldName}
          name={i766NumberFieldName}
          label={fieldDefinitions[i766NumberFieldName].label}
          hint={!documentNumberErrorMessage ? fieldDefinitions[i766NumberFieldName].hint : undefined}
          errorMessage={documentNumberErrorMessage}
          required
          defaultValue={get(data, i766NumberFieldName)}
          ref={register({
            required: true,
            ...get(fieldDefinitions, [i766NumberFieldName, 'validation'], {}),
          })}
        />
        <Controller
          control={control}
          name={documentExpirationDateFieldName}
          rules={{
            required: true,
            validate: get(fieldDefinitions[documentExpirationDateFieldName], 'validate', {}),
          }}
          render={({ onChange, value }): JSX.Element => (
            <FormattedDateInput
              className={CLASS_NAMES.dateInputSpacing}
              id={documentExpirationDateFieldName}
              name={documentExpirationDateFieldName}
              label={fieldDefinitions[documentExpirationDateFieldName].label}
              hint={!dateInputErrorMessage ? fieldDefinitions[documentExpirationDateFieldName].hint : undefined}
              errorMessage={dateInputErrorMessage}
              required
              defaultValue={get(data, documentExpirationDateFieldName)}
              formattedDate={value}
              onUpdate={onChange}
            />
          )}
        />
      </Form>
      <ActionFooter
        className={CLASS_NAMES.actionFooter}
        onCancel={onCancel}
        cancelButtonText="Back"
        onSubmit={handleFormSubmit}
        submitButtonText="Next"
      />
    </>
  );
};

export default I766DocumentMarkup;
