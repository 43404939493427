import React from 'react';
import { Modal } from '@ver-uds/react';

export interface HelpWithSignInModalProps {
  handleClose: () => void;
  isOpen: boolean;
}

export const MODAL_ID = 'help-with-sign-in-modal';

const baseClassName = MODAL_ID;
export const CLASS_NAMES = {
  base: baseClassName,
  contentSection: `${baseClassName}__content-section`,
  textSection: `${baseClassName}__text-section`,
};

const HelpWithSignInModal = ({ handleClose, isOpen }: HelpWithSignInModalProps): JSX.Element => {
  return (
    <Modal id={MODAL_ID} header="Help with Sign In" open={isOpen} close={handleClose}>
      <Modal.Content className={CLASS_NAMES.contentSection}>
        <div className={CLASS_NAMES.textSection}>
          If you are signing in into myE-Verify for the first time since April 28, 2019, you must access myE-Verify with
          your USCIS online account, a safe and secure dashboard that connects you to a variety of USCIS services. To
          create your USCIS online account, click on the "Create Account" button and provide your email address and a
          password. If you are an existing myE-Verify user and have already created your USCIS account, select “Sign
          In”.
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default HelpWithSignInModal;
