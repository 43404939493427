import classNames from 'classnames';
import get from 'lodash/get';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form, TextInputControl } from 'uswds-react';
import { getFieldError } from '../../../../../utils/forms';
import ActionFooter from '../../../../ActionFooter/ActionFooter';
import CountrySelect from '../../../../CountrySelect/CountrySelect';
import FormattedDateInput from '../../../../Form/FormattedDateInput/FormattedDateInput';
import {
  countryOfIssuanceFieldName,
  documentExpirationDateFieldName,
  fieldDefinitions,
  ForeignPassportFormData,
  foreignPassportNumberFieldName,
  visaNumberFieldName,
} from '../../constants';
import { DocumentMarkupComponentProps } from '../DocumentDetailsFormTypes';

const baseClassName = 'self-check-document-details';
const CLASS_NAMES = {
  base: baseClassName,
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
  form: `${baseClassName}__form`,
  dateInputSpacing: 'margin-top-3',
};

const ForeignPassportDocumentMarkup = ({
  data,
  onSubmit,
  onCancel,
}: DocumentMarkupComponentProps<ForeignPassportFormData>): JSX.Element => {
  const { errors, handleSubmit, register, control, setValue } = useForm<ForeignPassportFormData>({
    mode: 'onSubmit',
    defaultValues: { ...(data || {}) },
  });

  const handleFormSubmit = handleSubmit((formData) => {
    onSubmit(formData);
  });

  const documentNumberErrorMessage = getFieldError(
    errors.foreignPassportNumber,
    fieldDefinitions[foreignPassportNumberFieldName].errorMessages,
  );

  const dateInputErrorMessage = getFieldError(
    errors.documentExpirationDate,
    fieldDefinitions[documentExpirationDateFieldName].errorMessages,
  );

  const countryOfIssuanceErrorMessage = getFieldError(
    errors.countryOfIssuance,
    fieldDefinitions[countryOfIssuanceFieldName].errorMessages,
  );

  const visaNumberErrorMessage = getFieldError(errors.visaNumber, fieldDefinitions[visaNumberFieldName]?.errorMessages);

  return (
    <>
      <Form className={CLASS_NAMES.form} onSubmit={handleFormSubmit} variant="large">
        <TextInputControl
          id={foreignPassportNumberFieldName}
          name={foreignPassportNumberFieldName}
          label={fieldDefinitions[foreignPassportNumberFieldName].label}
          hint={!documentNumberErrorMessage ? fieldDefinitions[foreignPassportNumberFieldName].hint : undefined}
          errorMessage={documentNumberErrorMessage}
          required
          defaultValue={get(data, foreignPassportNumberFieldName)}
          ref={register({
            required: true,
            ...get(fieldDefinitions, [foreignPassportNumberFieldName, 'validation'], {}),
          })}
        />
        <CountrySelect
          name={countryOfIssuanceFieldName}
          label={fieldDefinitions[countryOfIssuanceFieldName].label}
          defaultValue={get(data, countryOfIssuanceFieldName)}
          error={countryOfIssuanceErrorMessage}
          required
          setValue={(name, value): void => setValue(name as keyof ForeignPassportFormData, value)}
          register={register}
        />
        <Controller
          control={control}
          name={documentExpirationDateFieldName}
          rules={{
            required: true,
            validate: get(fieldDefinitions[documentExpirationDateFieldName], 'validate'),
          }}
          render={({ onChange, value }): JSX.Element => (
            <FormattedDateInput
              className={CLASS_NAMES.dateInputSpacing}
              id={documentExpirationDateFieldName}
              name={documentExpirationDateFieldName}
              label={fieldDefinitions[documentExpirationDateFieldName].label}
              hint={!dateInputErrorMessage ? fieldDefinitions[documentExpirationDateFieldName].hint : undefined}
              errorMessage={dateInputErrorMessage}
              required
              defaultValue={get(data, documentExpirationDateFieldName)}
              formattedDate={value}
              onUpdate={onChange}
            />
          )}
        />
        <TextInputControl
          id={visaNumberFieldName}
          name={visaNumberFieldName}
          label={fieldDefinitions[visaNumberFieldName].label}
          defaultValue={get(data, visaNumberFieldName)}
          errorMessage={visaNumberErrorMessage}
          ref={register({
            // not required
            ...get(fieldDefinitions[visaNumberFieldName], 'validation', {}),
          })}
        />
      </Form>
      <ActionFooter
        className={CLASS_NAMES.actionFooter}
        onCancel={onCancel}
        cancelButtonText="Back"
        onSubmit={handleFormSubmit}
        submitButtonText="Next"
      />
    </>
  );
};

export default ForeignPassportDocumentMarkup;
