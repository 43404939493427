import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert, Form } from 'uswds-react';
import { COLUMN_9_CLASS_NAME } from '../../../../../constants';
import { SelfCheckFormData } from '../../constants';
import { FIELD_ORDER } from '../constants';
import FormFields, { FormFieldData } from '../../../../Form/FormFields/FormFields';
import {
  SelfCheckCaseReverificationRequest,
  SelfCheckReverificationFieldNameUserInputRequired,
} from '../../../../../services/SelfCheckApi';
import { getDataByFieldName } from '../util';
import { mapReverifySectionFieldDataToFormFieldData, ReactHookFormProps, ReverifySectionFieldData } from './util';
import ActionFooter from '../../../../ActionFooter/ActionFooter';

type SelfCheckReverificationFieldNameUserVisible = SelfCheckReverificationFieldNameUserInputRequired | 'dob';

export const REVERIFY_SUBMIT_BUTTON_TEXT = 'Resubmit';

export interface ReverifySectionMarkupProps {
  className?: ClassValue;
  reverificationFields?: SelfCheckReverificationFieldNameUserVisible[];
  data?: SelfCheckFormData;
  onSubmit: SubmitHandler<SelfCheckCaseReverificationRequest>;
}

const baseClassName = 'self-check-review-reverify-section';
const CLASS_NAMES = {
  base: baseClassName,
  warningAlert: classNames(`${baseClassName}__info-alert`, 'margin-bottom-3', 'margin-top-3'),
  warningAlertText: classNames(`${baseClassName}__info-alert-text`, COLUMN_9_CLASS_NAME),
  form: `${baseClassName}__form`,
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
};

const createFormFields = (
  fieldData: ReverifySectionFieldData[],
  reactHookFormProps: ReactHookFormProps,
): FormFieldData[] => {
  return mapReverifySectionFieldDataToFormFieldData(fieldData, reactHookFormProps);
};

const ReverifySectionMarkup = ({
  className = undefined,
  data = undefined,
  reverificationFields = [],
  onSubmit,
}: ReverifySectionMarkupProps): JSX.Element => {
  const fieldData: ReverifySectionFieldData[] = [];
  const defaultValues: SelfCheckCaseReverificationRequest = {};
  reverificationFields.forEach((name) => {
    const value = getDataByFieldName(data, name);

    fieldData.push({
      name,
      value,
    });

    defaultValues[name] = value;
  });

  const { control, errors, handleSubmit, register, setValue, watch } = useForm<SelfCheckCaseReverificationRequest>({
    mode: 'onSubmit',
    defaultValues,
  });

  const formFields = createFormFields(fieldData, {
    control,
    register,
    setValue,
    errors,
  });

  const handleFormSubmit = handleSubmit((formData: SelfCheckCaseReverificationRequest) => {
    onSubmit(formData);
  });

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <h2>Document Details</h2>
      <Alert className={CLASS_NAMES.warningAlert} status="warning">
        <Alert.Heading>Please Confirm Information</Alert.Heading>
        <Alert.Text className={CLASS_NAMES.warningAlertText}>
          An initial check of our records indicates that you may have mistyped some of your information. Please review
          the information you provided and correct any errors.
        </Alert.Text>
      </Alert>
      {watch() && (
        <Form className={classNames(CLASS_NAMES.form, className)} onSubmit={handleFormSubmit} variant="large">
          <FormFields data={formFields} sortOrder={FIELD_ORDER} />
        </Form>
      )}
      <ActionFooter
        className={CLASS_NAMES.actionFooter}
        hideCancelButton
        onSubmit={handleFormSubmit}
        submitButtonText={REVERIFY_SUBMIT_BUTTON_TEXT}
        submitButtonVariant="submit"
      />
    </div>
  );
};

export default ReverifySectionMarkup;
