import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ClassValue } from 'classnames/types';
import classNames from 'classnames';
import get from 'lodash/get';
import { Accordion, ErrorMessage, Fieldset, Form, FormGroup, Legend, RadioButton } from 'uswds-react';
import { Helmet } from 'react-helmet-async';
import { CitizenshipStatus } from '../../../../services/SelfCheckApi';
import ActionFooter from '../../../ActionFooter/ActionFooter';
import CitizenshipStatusHelpSection from '../../../HelpSections/CitizenshipStatusHelpSection';
import { selfCheckPageTitles } from '../constants';

export const CITIZENSHIP_STATUS_REQUIRED_ERR_MSG = 'Please select a citizenship status.';

const RADIO_LABELS_MAP: Record<CitizenshipStatus, string> = {
  [CitizenshipStatus.US_CITIZEN]: 'I am a citizen of the United States',
  [CitizenshipStatus.US_NON_CITIZEN]: 'I am a noncitizen national of the United States',
  [CitizenshipStatus.PERMANENT_RESIDENT]: 'I am a lawful permanent resident',
  [CitizenshipStatus.NONCITIZEN_AUTHORIZED_TO_WORK]: 'I am a noncitizen authorized to work',
};

export interface SelfCheckFormCitizenshipStatusFormData {
  citizenshipStatus: CitizenshipStatus;
}

export interface SelfCheckFormCitizenshipStatusMarkupProps {
  className?: ClassValue;
  data?: CitizenshipStatus;
  onSubmit: SubmitHandler<SelfCheckFormCitizenshipStatusFormData>;
  onCancel: () => void;
}

const baseClassName = 'self-check-citizenship-status';
const CLASS_NAMES = {
  base: baseClassName,
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
  form: classNames(`${baseClassName}__form`, 'margin-top-2'),
  formGroup: `${baseClassName}__form-group`,
  fieldSet: `${baseClassName}__field-set`,
  formGroupLabel: classNames(`${baseClassName}__form-group-label`, 'float-left', 'margin-top-0'),
  formGroupLabelWrapper: classNames(`${baseClassName}__form-group-label-wrapper`, 'usa-legend', 'margin-top-0'),
  formGroupLabelRequiredText: classNames(
    `${baseClassName}__form-group-label-required-text`,
    'usa-hint',
    'margin-left-05',
  ),
  helpSection: classNames(`${baseClassName}__help-section`, 'margin-top-6'),
};

const SelfCheckFormCitizenshipStatusMarkup = ({
  className = undefined,
  data = undefined,
  onCancel,
  onSubmit,
}: SelfCheckFormCitizenshipStatusMarkupProps): JSX.Element => {
  const { errors, register, handleSubmit, watch } = useForm<SelfCheckFormCitizenshipStatusFormData>({
    mode: 'all',
    defaultValues: {
      citizenshipStatus: data,
    },
  });

  const handleFormSubmit = handleSubmit((formData) => {
    onSubmit(formData);
  });

  const citizenshipStatusErrorMessage =
    get(errors, ['citizenshipStatus', 'type']) === 'required' ? CITIZENSHIP_STATUS_REQUIRED_ERR_MSG : undefined;

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <Helmet>
        <title>{selfCheckPageTitles.SELECT_CITIZENSHIP_STATUS}</title>
      </Helmet>
      {watch() && (
        <Form className={classNames(CLASS_NAMES.form, className)} onSubmit={handleFormSubmit} variant="large">
          <FormGroup className={CLASS_NAMES.formGroup} error={citizenshipStatusErrorMessage}>
            <Fieldset className={CLASS_NAMES.fieldSet}>
              <div className={CLASS_NAMES.formGroupLabelWrapper}>
                <Legend className={CLASS_NAMES.formGroupLabel}>What is your citizenship status?</Legend>
                <span className={CLASS_NAMES.formGroupLabelRequiredText} aria-hidden="true">
                  (required)
                </span>
              </div>
              {citizenshipStatusErrorMessage && (
                <ErrorMessage id="citizenshipStatus-error">{citizenshipStatusErrorMessage}</ErrorMessage>
              )}
              {Object.keys(CitizenshipStatus).map((key: string) => {
                const citizenshipStatus = CitizenshipStatus[key as keyof typeof CitizenshipStatus];
                return (
                  <RadioButton
                    key={citizenshipStatus}
                    id={citizenshipStatus}
                    name="citizenshipStatus"
                    label={RADIO_LABELS_MAP[citizenshipStatus]}
                    value={citizenshipStatus}
                    defaultChecked={data === citizenshipStatus}
                    ref={register({
                      required: true,
                    })}
                    aria-required="true"
                  />
                );
              })}
            </Fieldset>
          </FormGroup>
        </Form>
      )}
      <ActionFooter
        className={CLASS_NAMES.actionFooter}
        onCancel={onCancel}
        cancelButtonText="Back"
        onSubmit={handleFormSubmit}
        submitButtonText="Next"
      />
      <div className={CLASS_NAMES.helpSection}>
        <Accordion bordered>
          <CitizenshipStatusHelpSection />
        </Accordion>
      </div>
    </div>
  );
};

export default SelfCheckFormCitizenshipStatusMarkup;
