import { useContext, useMemo } from 'react';
import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import createError from 'http-errors';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { AuthenticationContext } from '../context/Authentication/AuthenticationContext';
import BaseApi, { useConfiguration } from './BaseApi';

export enum SelfLockState {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
  UNKNOWN = 'UNKNOWN',
}

export interface SelfLockChallengeQuestion {
  question: string;
  answer: string;
}

export interface SelfLockUnlockPayload {
  state: SelfLockState.UNLOCKED;
}

export interface SelfLockLockPayload {
  state: SelfLockState.LOCKED;
  challengeQuestions: SelfLockChallengeQuestion[];
}

export interface SelfLockResponseData {
  state: SelfLockState;
}

export interface SelfLockResponse {
  data: SelfLockResponseData;
}

class SelfLockApi extends BaseApi {
  private getSelfLockEndpoint(): string {
    return `${this.configuration.basePath}/locks/ssn`;
  }

  async getSelfLock(): Promise<SelfLockResponseData> {
    const endpoint = this.getSelfLockEndpoint();
    try {
      const resp: AxiosResponse<SelfLockResponse> = await axios.get(endpoint, { withCredentials: true });
      return get(resp, 'data.data');
    } catch (err) {
      throw createError(get(err, 'response.status', 500), err.message);
    }
  }

  async updateSelfLock(data: SelfLockUnlockPayload | SelfLockLockPayload): Promise<SelfLockResponseData> {
    const endpoint = this.getSelfLockEndpoint();
    try {
      const resp: AxiosResponse<SelfLockResponse> = await axios.post(endpoint, data, { withCredentials: true });
      return get(resp, 'data.data');
    } catch (err) {
      throw createError(get(err, 'response.status', 500), err.message);
    }
  }
}

const useSelfLockApi = (): SelfLockApi => {
  const configuration = useConfiguration();
  const api = useMemo(() => new SelfLockApi(configuration), [configuration]);
  return api;
};

export const getSelfLockQueryID = 'getSelfLock';
export const useSelfLockQuery = (): QueryObserverResult<SelfLockUnlockPayload | SelfLockLockPayload> => {
  const api = useSelfLockApi();
  const { isAuthenticated } = useContext(AuthenticationContext);

  return useQuery(getSelfLockQueryID, (_key) => api.getSelfLock(), {
    enabled: isAuthenticated() && api.isConfigurationValid(),
  });
};

export const useSelfLockUpdateMutation = (): UseMutationResult<
  SelfLockResponseData,
  Error,
  SelfLockUnlockPayload | SelfLockLockPayload,
  SelfLockResponseData
> => {
  const api = useSelfLockApi();
  const queryClient = useQueryClient();
  const { isAuthenticated } = useContext(AuthenticationContext);

  return useMutation(
    async (payload: SelfLockUnlockPayload | SelfLockLockPayload) => {
      return api.updateSelfLock(payload);
    },
    {
      // Optimistically update the cache value on mutate, but store
      // the old value and return it so that it's accessible in case of
      // an error
      onMutate: (payload: SelfLockUnlockPayload | SelfLockLockPayload): SelfLockResponseData => {
        if (!isAuthenticated) {
          throw createError(403);
        }
        if (!api.isConfigurationValid()) {
          throw createError(400, 'Configuration invalid');
        }

        queryClient.cancelQueries(getSelfLockQueryID);

        const previousValue = queryClient.getQueryData<SelfLockResponseData>(getSelfLockQueryID);

        queryClient.setQueryData<SelfLockResponseData>(getSelfLockQueryID, pick(payload, 'state'));

        return (
          previousValue || {
            state: SelfLockState.UNKNOWN,
          }
        );
      },
      // On failure, roll back to the previous value
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData(getSelfLockQueryID, previousValue);
      },
      // After success or failure, refetch the query
      onSettled: () => {
        queryClient.invalidateQueries(getSelfLockQueryID);
      },
    },
  );
};
