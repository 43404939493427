import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { ReadableCaseStatus } from '../../../../services/CaseApi';
import { SelfCheckCaseResponseData } from '../../../../services/SelfCheckApi';
import CaseDetailActionSection from './CaseDetailActionSection';
import SelfCheckCaseDetailContent from './SelfCheckCaseDetailContent';
import GenericCaseDetailContent from './GenericCaseDetailContent';
import { getSelfCheckCaseDetailVariant } from './util';

export interface CaseDetailContentProps {
  className?: ClassValue;
  caseNumber: string;
  caseStatus: ReadableCaseStatus;
  selfCheckCaseData?: SelfCheckCaseResponseData;
}

const baseClassName = 'case-detail-content';
const CLASS_NAMES = {
  base: baseClassName,
  contentSection: classNames(`${baseClassName}__content-section`),
  actionSection: classNames(`${baseClassName}__action-section`, 'margin-top-3'),
};

const CaseDetailContent = ({
  className = undefined,
  caseNumber,
  caseStatus,
  selfCheckCaseData = undefined,
}: CaseDetailContentProps): JSX.Element => {
  const selfCheckCaseDetailVariant = getSelfCheckCaseDetailVariant(selfCheckCaseData);
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      {selfCheckCaseDetailVariant ? (
        <SelfCheckCaseDetailContent
          className={CLASS_NAMES.contentSection}
          selfCheckCaseDetailVariant={selfCheckCaseDetailVariant}
          dhsReferralStatus={selfCheckCaseData?.dhsReferralStatus}
          ssaReferralStatus={selfCheckCaseData?.ssaReferralStatus}
        />
      ) : (
        <GenericCaseDetailContent className={CLASS_NAMES.contentSection} caseStatus={caseStatus} />
      )}
      <CaseDetailActionSection
        className={CLASS_NAMES.actionSection}
        caseNumber={caseNumber}
        caseStatus={caseStatus}
        selfCheckCaseDetailVariant={selfCheckCaseDetailVariant}
      />
    </div>
  );
};

export default CaseDetailContent;
