import noop from 'lodash/noop';
import React, { useContext, useState } from 'react';
import { SelfCheckFormData, SelfCheckFormPage } from '../../../components/SelfCheck/SelfCheckForm/constants';
import { SelfCheckCaseResponseData } from '../../../services/SelfCheckApi';

const INITIAL_FORM_STATE: SelfCheckFormData = {};
const SELF_CHECK_FORM_LANDING_PAGE = SelfCheckFormPage.CONFIRM_IDENTITY;

export interface SelfCheckForm {
  formData: SelfCheckFormData;
  updateFormData: (updatedValue: SelfCheckFormData) => void;
  formPage: SelfCheckFormPage;
  updateFormPage: (updatedValue: SelfCheckFormPage) => void;
  caseData?: SelfCheckCaseResponseData;
  updateCaseData: (updatedValue?: SelfCheckCaseResponseData) => void;
}

const SelfCheckFormContext = React.createContext<SelfCheckForm>({
  formData: {},
  updateFormData: noop,
  formPage: SELF_CHECK_FORM_LANDING_PAGE,
  updateFormPage: noop,
  caseData: undefined,
  updateCaseData: noop,
});

export const useSelfCheckForm = (): SelfCheckForm => {
  return useContext(SelfCheckFormContext);
};

export const SelfCheckFormConsumer = SelfCheckFormContext.Consumer;

export type SelfCheckFormProviderProps = {
  children: React.ReactNode;
};

export const SelfCheckFormProvider = ({ children }: SelfCheckFormProviderProps): JSX.Element => {
  const [formPageState, setFormPageState] = useState<SelfCheckFormPage>(SELF_CHECK_FORM_LANDING_PAGE);
  const [formDataState, setFormDataState] = useState<SelfCheckFormData>(INITIAL_FORM_STATE);
  const [caseDataState, setCaseDataState] = useState<SelfCheckCaseResponseData>();
  return (
    <SelfCheckFormContext.Provider
      value={{
        formData: formDataState,
        updateFormData: setFormDataState,
        formPage: formPageState,
        updateFormPage: setFormPageState,
        caseData: caseDataState,
        updateCaseData: setCaseDataState,
      }}
    >
      {children}
    </SelfCheckFormContext.Provider>
  );
};
