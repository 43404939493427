import moment, { Moment } from 'moment';

/* eslint-disable import/prefer-default-export */
export const PRESENTATION_DATE_FORMAT = 'MMM D, YYYY';
const ISO_DATE_FORMAT = 'YYYY-MM-DD';

export const formatDate = (dateStr: string | Date): string | undefined => {
  return formatMomentDate(moment(dateStr));
};

export const formatMomentDate = (momentDate: Moment): string | undefined => {
  if (!momentDate.isValid()) {
    return undefined;
  }
  return momentDate.format(PRESENTATION_DATE_FORMAT);
};

const getMomentFromDateInput = (value: string): Moment => {
  const mom = moment(value, ISO_DATE_FORMAT, undefined, true);
  return mom;
};

export const padIsoDateString = (isoDateString: string): string => {
  // Uses loose string matching
  return moment(isoDateString, ISO_DATE_FORMAT).format(ISO_DATE_FORMAT);
};

export const isFutureDate = (isoDateString: string): boolean => {
  const input = getMomentFromDateInput(isoDateString);
  const current = moment();
  const isFuture = input.isSameOrAfter(current, 'date');
  return isFuture;
};

export const isValidDate = (isoDateString: string): boolean => {
  return getMomentFromDateInput(isoDateString).isValid();
};

export const isBefore1989Sep1 = (isoDateString: string): boolean => {
  return getMomentFromDateInput(isoDateString).isBefore(getMomentFromDateInput('1989-09-01'));
};

export const isBefore18YearsAgo = (isoDateString: string): boolean => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return getMomentFromDateInput(isoDateString).isBefore(date);
};
