import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import ConfirmIdentityMarkup from './ConfirmIdentityMarkup';
import { usePatchUserInfo, UserInfoUpdateRequest, UserInfoUpdateRequestFieldName } from '../../services/UserApi';
import paths from '../../routing/paths';

export type ConfirmIdentityFormData = {
  [UserInfoUpdateRequestFieldName.DOB]?: string;
  [UserInfoUpdateRequestFieldName.SSN]?: string;
};

const ConfirmIdentity = (): JSX.Element => {
  const [prevEnteredData, setPrevEnteredData] = useState<ConfirmIdentityFormData>({});

  const {
    error: patchUserInfoError,
    mutate: patchUserInfoMutate,
    isLoading: isSubmissionLoading,
    isSuccess: isSubmissionSuccess,
  } = usePatchUserInfo();

  const submitUpdateUserInformation = (data: UserInfoUpdateRequest): void => {
    setPrevEnteredData(data);
    patchUserInfoMutate(data);
  };

  const getError = (): Error | undefined => {
    if (patchUserInfoError) {
      return patchUserInfoError;
    }
    return undefined;
  };

  const getPrevEnteredData = (): ConfirmIdentityFormData | undefined => {
    if (Object.keys(prevEnteredData).length > 0) {
      return prevEnteredData;
    }
    return undefined;
  };

  if (isSubmissionSuccess) {
    return (
      <Redirect
        to={{
          pathname: paths.home,
          state: { bypassLoginRouting: true },
        }}
      />
    );
  }

  return (
    <>
      {!isSubmissionLoading && (
        <ConfirmIdentityMarkup
          error={getError()}
          onSubmit={submitUpdateUserInformation}
          prevEnteredData={getPrevEnteredData()}
        />
      )}
    </>
  );
};

export default ConfirmIdentity;
