import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';

export interface AuthenticationConfig {
  authCookieName?: string;
  logoutURL?: string;
}

export const LAST_AUTHENTICATED_TIME_STORAGE_KEY = 'last_authenticated_time';

export interface Authentication {
  isConfigValid: () => boolean;
  isAuthenticated: () => boolean;
  getLastAuthenticatedTime: () => string | null;
  refreshLastAuthenticatedTime: () => void;
  logout: () => void;
  getSessionToken: () => string | undefined;
}

export const createAuthentication = ({ authCookieName, logoutURL }: AuthenticationConfig): Authentication => {
  const isConfigValid = (): boolean => !isEmpty(authCookieName);
  const getSessionToken = (): string | undefined =>
    authCookieName !== undefined ? Cookies.get(authCookieName) : undefined;
  return {
    isConfigValid,
    isAuthenticated: (): boolean => !!getSessionToken(),
    getLastAuthenticatedTime: (): string | null => {
      return localStorage.getItem(LAST_AUTHENTICATED_TIME_STORAGE_KEY);
    },
    refreshLastAuthenticatedTime: (): void => {
      localStorage.setItem(LAST_AUTHENTICATED_TIME_STORAGE_KEY, Date.now().toString());
    },
    logout: (): void => {
      if (authCookieName !== undefined) {
        Cookies.remove(authCookieName);
      }
      if (logoutURL !== undefined) {
        window.location.href = logoutURL;
      }
    },
    getSessionToken,
  };
};
