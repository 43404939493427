import { ReadableCaseStatus } from '../../../../../services/CaseApi';

/* eslint-disable import/prefer-default-export */
export const mapReadableCaseStatusToCaseDetailBodyText = (readableCaseStatus: ReadableCaseStatus): string => {
  switch (readableCaseStatus) {
    case ReadableCaseStatus.CASE_INCOMPLETE:
      return 'Case Incomplete. If you would like to verify your work authorization, contact your employer for more information or create a new Self Check case.';
    case ReadableCaseStatus.CLOSE_AND_RESUBMIT:
      return 'Case Closed. If you would like to verify your work authorization, contact your employer for more information or create a new Self Check case.';
    case ReadableCaseStatus.CLOSED:
      return 'Case Closed. No further action is necessary.';
    case ReadableCaseStatus.EMPLOYMENT_AUTHORIZED:
      return 'E‑Verify confirmed you are authorized to work in the United States.';
    case ReadableCaseStatus.CHECK_BACK_LATER:
    case ReadableCaseStatus.EMPLOYEE_REFERRED_CONTINUANCE:
    case ReadableCaseStatus.CONTINUANCE:
      return 'E‑Verify needs more time to confirm that you are authorized to work in the United States. Your employer should not take any action against you while E‑Verify is reviewing this case. Check back periodically for an update on this case status.';
    case ReadableCaseStatus.FINAL_NONCONFIRMATION:
      return 'E‑Verify was not able to confirm your authorization for employment. Check with your employer for more information.';
    case ReadableCaseStatus.EMPLOYEE_REFERRED_DHS:
    case ReadableCaseStatus.EMPLOYEE_REFERRED_DHS_OR_SSA:
      return 'You were referred to DHS because you chose to take action to resolve the DHS Mismatch. You must contact DHS within the Referral Date Confirmation letter timeframe. Check with your employer for more information or create a new Self Check case if you did not keep these instructions.';
    case ReadableCaseStatus.EMPLOYEE_REFERRED_SSA:
      return 'You were referred to SSA because you chose to take action to resolve the SSA Mismatch. You must visit your local SSA office within the timeframe given on your Referral Date Confirmation letter. Check with your employer for more information or create a new Self Check case if you did not keep these instructions.';
    case ReadableCaseStatus.EMPLOYEE_REFERRED_DHS_AND_SSA:
      return 'You were referred to SSA and DHS because you chose to take action to resolve the SSA and DHS Mismatch. You must contact SSA and DHS within your Referral Date Confirmation letter timeframe. Check with your employer for more information or create a new Self Check case if you did not keep these instructions.';
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS:
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_SSA:
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_AND_SSA:
      return 'E-Verify needs more information from you before confirming employment authorization. Please reference your Further Action Notice (FAN) for next steps towards your case resolution. If you did not keep your FAN instructions, please contact your employer, or create a new Self Check case.';
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_OR_SSA:
      return 'It’s okay! It appears that some of your information does not match the U.S. Department of Homeland Security (DHS) records. To take action to resolve this temporary Tentative Nonconfirmation (Mismatch) case status, please upload documents that show your employment eligibility.';
    case ReadableCaseStatus.UNKNOWN:
    default:
      return 'Case status result unknown.';
  }
};
