import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';

export interface DividerProps {
  className?: ClassValue;
}

const Divider = ({ className = undefined }: DividerProps): JSX.Element => {
  return (
    <div
      className={classNames(`myeverify-divider`, 'border-top-1px', 'border-base-lighter', className)}
      role="separator"
    />
  );
};

export default Divider;
