import React from 'react';
import { PageHeader } from '@ver-uds/react';
import { PageHeaderProps } from '@ver-uds/react/dist/PageHeader/PageHeader';
import BasicLayout from '../BasicLayout/BasicLayout';

export type PageContentLayoutProps = {
  children?: React.ReactNode;
  title: string;
  variant?: PageHeaderProps['variant'];
};

const PageContentLayout = ({
  children = undefined,
  title,
  variant = 'default',
}: PageContentLayoutProps): JSX.Element => (
  <>
    <BasicLayout title={title}>
      <PageHeader variant={variant}>{title}</PageHeader>
      <div className="grid-container">
        <div className="usa-section padding-top-3 bg-dhs-seal bg-dhs-seal--page">{children}</div>
      </div>
    </BasicLayout>
  </>
);

export default PageContentLayout;
