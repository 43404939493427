import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { ErrorMessage, Fieldset, FormGroup, Legend, RadioButton } from 'uswds-react';
import { fieldDefinitions, SupportingDocumentType, supportingDocumentTypeFieldName } from '../../../constants';
import { RegisterFn } from '../../../../../../utils/forms';

const SUPPORTING_DOCUMENT_TYPE_RADIO_LABELS_MAP: Record<SupportingDocumentType, string> = {
  [SupportingDocumentType.USCIS_A_NUMBER]: 'USCIS A-Number',
  [SupportingDocumentType.I_94]: 'Form I-94 Admission Number',
  [SupportingDocumentType.FOREIGN_PASSPORT]: 'Foreign Passport Number',
};

export interface SelfCheckFormDocumentSecondarySelectionMarkupProps {
  className?: ClassValue;
  data?: SupportingDocumentType;
  error?: string;
  register: RegisterFn;
}

const baseClassName = 'self-check-document-selection__secondary-document-selection';
const CLASS_NAMES = {
  base: baseClassName,
  fieldSet: `${baseClassName}__field-set`,
  fieldSetLabel: classNames(`${baseClassName}__field-set-group-label`, 'float-left', 'margin-top-0'),
  fieldSetLabelWrapper: classNames(`${baseClassName}__field-set-label-wrapper`, 'usa-legend', 'margin-top-0'),
  fieldSetLabelRequiredText: classNames(
    `${baseClassName}__field-set-label-required-text`,
    'usa-hint',
    'margin-left-05',
  ),
};

const SelfCheckFormDocumentSecondarySelectionMarkup = ({
  className = undefined,
  data = undefined,
  error = undefined,
  register,
}: SelfCheckFormDocumentSecondarySelectionMarkupProps): JSX.Element => {
  return (
    <FormGroup className={classNames(CLASS_NAMES.base, className)} error={error}>
      <Fieldset className={CLASS_NAMES.fieldSet}>
        <div className={CLASS_NAMES.fieldSetLabelWrapper}>
          <Legend className={CLASS_NAMES.fieldSetLabel}>
            {fieldDefinitions[supportingDocumentTypeFieldName].label}
          </Legend>
          <span className={CLASS_NAMES.fieldSetLabelRequiredText} aria-hidden="true">
            (required)
          </span>
        </div>
        {error && <ErrorMessage id="secondary-document-selection-error">{error}</ErrorMessage>}
        {Object.keys(SupportingDocumentType).map((key: string) => {
          const supportingDocumentType = SupportingDocumentType[key as keyof typeof SupportingDocumentType];
          return (
            <RadioButton
              key={supportingDocumentType}
              id={supportingDocumentType}
              name={supportingDocumentTypeFieldName}
              label={SUPPORTING_DOCUMENT_TYPE_RADIO_LABELS_MAP[supportingDocumentType]}
              value={supportingDocumentType}
              defaultChecked={data === supportingDocumentType}
              ref={register({
                required: true,
              })}
              aria-required="true"
            />
          );
        })}
      </Fieldset>
    </FormGroup>
  );
};

export default SelfCheckFormDocumentSecondarySelectionMarkup;
