import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import pick from 'lodash/pick';
import styled from 'styled-components';
import { Accordion, DropdownControl, Form, TextInputControl } from 'uswds-react';
import { Helmet } from 'react-helmet-async';
import { CitizenshipStatus, DocumentType } from '../../../../services/SelfCheckApi';
import { getFieldError } from '../../../../utils/forms';
import ActionFooter from '../../../ActionFooter/ActionFooter';
import {
  USCISANumberFieldName,
  countryOfIssuanceFieldName,
  documentTypeFieldName,
  foreignPassportNumberFieldName,
  i94NumberFieldName,
  SelfCheckFormDocumentSelectionFormData,
  SupportingDocumentType,
  supportingDocumentTypeFieldName,
  fieldDefinitions,
  SelfCheckFormDocumentSelectionFieldDefinition,
  documentSelectionHelpContent,
  selfCheckPageTitles,
} from '../constants';
import SelfCheckFormDocumentSelectionNCAWSection from './SelfCheckFormDocumentSelectionNCAWSection/SelfCheckFormDocumentSelectionNCAWSection';

export const NCAW_SUB_HEADER_TEXT = 'Select Document to Verify';

export const DOCUMENT_TYPE_OPTIONS_LABELS_MAP: Record<DocumentType, string> = {
  [DocumentType.LIST_B_C_DOCUMENTS]: 'Social Security number',
  [DocumentType.US_PASSPORT]: 'U.S. Passport or Passport Card',
  [DocumentType.FOREIGN_PASSPORT_I_551]: 'Foreign Passport with temporary I-551 stamp or printed notation on a MRIV',
  [DocumentType.FOREIGN_PASSPORT_I_94]: 'Foreign Passport with Arrival/Departure Record (Form I-94)',
  [DocumentType.I_551]: 'Permanent Resident Card or Alien Registration Receipt Card',
  [DocumentType.I_766]: 'Employment Authorization Document (Form I-766)',
  [DocumentType.I_94]:
    'Arrival/Departure Record (Form I-94) with temporary I-551 stamp or refugee admission stamp (receipt)',
};

export interface SelfCheckFormDocumentSelectionMarkupProps {
  className?: ClassValue;
  citizenshipStatus: CitizenshipStatus;
  data?: SelfCheckFormDocumentSelectionFormData;
  onCancel: () => void;
  onSubmit: SubmitHandler<SelfCheckFormDocumentSelectionFormData>;
}

const baseClassName = 'self-check-document-selection';
const CLASS_NAMES = {
  base: baseClassName,
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
  form: `${baseClassName}__form`,
  documentTypeDropdownControl: `${baseClassName}__document-type-dropdown-control`,
  documentTypeDropdownControlError: `${baseClassName}__document-type-dropdown-control-error`,
  ncawSection: `${baseClassName}__ncaw-section`,
  documentNumberNCAWTextInputControlError: `${baseClassName}__document-number-ncaw-text-input-control-error`,
  countryOfIssuanceSelect: `${baseClassName}__foreign-passport-country-of-issuance`,
  uscisANumberTextInputControl: `${baseClassName}__uscis-a-number-text-input-control`,
  uscisANumberTextInputControlError: `${baseClassName}__uscis-a-number-text-input-control-error`,
  formGroup: `${baseClassName}__form-group`,
  helpSection: classNames(`${baseClassName}__help-section`, 'margin-top-6'),
  helpWithDocuments: `${baseClassName}__help-with-documents`,
  subHeader: classNames(`${baseClassName}__sub-header`, 'margin-top-5'),
};

const StyledForm = styled(Form)`
  > .usa-form-group {
    > .usa-label {
      &[for='${CLASS_NAMES.documentTypeDropdownControl}'] {
        margin-top: 1rem;
      }
    }
  }
`;

const getNCAWOptions = (supportingDocumentType?: SupportingDocumentType): DocumentType[] => {
  switch (supportingDocumentType) {
    case SupportingDocumentType.USCIS_A_NUMBER:
      return [DocumentType.LIST_B_C_DOCUMENTS, DocumentType.I_766, DocumentType.FOREIGN_PASSPORT_I_94];
    case SupportingDocumentType.I_94:
      return [DocumentType.LIST_B_C_DOCUMENTS, DocumentType.FOREIGN_PASSPORT_I_94];
    case SupportingDocumentType.FOREIGN_PASSPORT:
      return [DocumentType.LIST_B_C_DOCUMENTS];
    default:
      return [];
  }
};

let pageTitle: string;

const getAvailableDocumentTypes = (
  citizenshipStatus: CitizenshipStatus,
  supportingDocumentType?: SupportingDocumentType,
): DocumentType[] => {
  switch (citizenshipStatus) {
    case CitizenshipStatus.US_CITIZEN: // US_CITIZEN has same options as US_NON_CITIZEN
    case CitizenshipStatus.US_NON_CITIZEN:
      pageTitle = selfCheckPageTitles.US_CITIZEN_AND_NATIONAL_DOCUMENT_SELECTIION;
      return [DocumentType.LIST_B_C_DOCUMENTS, DocumentType.US_PASSPORT];
    case CitizenshipStatus.PERMANENT_RESIDENT:
      pageTitle = selfCheckPageTitles.LAWFUL_PERMANENT_RESIDENT_DOCUMENT_SELECTION;
      return [
        DocumentType.LIST_B_C_DOCUMENTS,
        DocumentType.I_551,
        DocumentType.FOREIGN_PASSPORT_I_551,
        DocumentType.I_94,
      ];
    case CitizenshipStatus.NONCITIZEN_AUTHORIZED_TO_WORK:
      pageTitle = selfCheckPageTitles.NONCITIZEN_AUTHORIZED_TO_WORK_DOCUMENT_SELECTION;
      return getNCAWOptions(supportingDocumentType);
    default:
      // Cannot actually happen – all CitizenshipStatus enum values are handled
      return [];
  }
};

export type DocumentNumberNCAWTextInputControlProps = Omit<SelfCheckFormDocumentSelectionFieldDefinition, 'label'>;

const getDocumentNumberNCAWTextInputControlProps = (
  supportingDocumentType?: SupportingDocumentType,
): DocumentNumberNCAWTextInputControlProps => {
  switch (supportingDocumentType) {
    case SupportingDocumentType.USCIS_A_NUMBER:
      return fieldDefinitions[USCISANumberFieldName];
    case SupportingDocumentType.I_94:
      return fieldDefinitions[i94NumberFieldName];
    case SupportingDocumentType.FOREIGN_PASSPORT:
      return fieldDefinitions[foreignPassportNumberFieldName] as SelfCheckFormDocumentSelectionFieldDefinition;
    default:
      // Cannot actually happen – all CitizenshipStatus enum values are handled
      return {} as DocumentNumberNCAWTextInputControlProps;
  }
};

const SelfCheckFormDocumentSelectionMarkup = ({
  className = undefined,
  citizenshipStatus,
  data = undefined,
  onCancel,
  onSubmit,
}: SelfCheckFormDocumentSelectionMarkupProps): JSX.Element => {
  const {
    handleSubmit,
    errors,
    getValues,
    setValue,
    register,
    unregister,
    formState,
    watch,
  } = useForm<SelfCheckFormDocumentSelectionFormData>({
    mode: 'onSubmit',
    defaultValues: { ...(data || {}) },
  });

  const handleFormSubmit = handleSubmit((formData) => {
    onSubmit(
      pick(formData, [
        documentTypeFieldName,
        USCISANumberFieldName,
        supportingDocumentTypeFieldName,
        i94NumberFieldName,
        foreignPassportNumberFieldName,
        countryOfIssuanceFieldName,
      ]),
    );
  });

  const documentTypeErrorMessage = getFieldError(
    get(errors, documentTypeFieldName),
    fieldDefinitions[documentTypeFieldName].errorMessages,
  );

  const uscisANumberErrorMessage = getFieldError(
    get(errors, USCISANumberFieldName),
    fieldDefinitions[USCISANumberFieldName].errorMessages,
  );

  const [supportingDocumentType, setSupportingTypeDocument] = useState<SupportingDocumentType>();
  const [
    documentNumberNCAWTextInputControlProps,
    setDocumentNumberNCAWTextInputControlProps,
  ] = useState<DocumentNumberNCAWTextInputControlProps>();

  /**
   * Subscribe to formState changes
   * 1. If document number input exists already, unregister and clear value
   * 2. Set new supporting document type
   * 3. Set new document number input props (and register new document number input)
   */
  useEffect(() => {
    const newSupportingDocumentType = getValues(supportingDocumentTypeFieldName) || undefined;
    if (newSupportingDocumentType !== supportingDocumentType) {
      // get previous document number input name (if exists)
      const documentNumberInputName = documentNumberNCAWTextInputControlProps?.name;
      if (documentNumberInputName) {
        // clear existing document number input value and unregister from react-hook-form
        setValue(documentNumberInputName, '');
        unregister(documentNumberInputName);
      }
      // update state with new supporting document type
      setSupportingTypeDocument(newSupportingDocumentType);

      // generate and set props for document number input
      setDocumentNumberNCAWTextInputControlProps(getDocumentNumberNCAWTextInputControlProps(newSupportingDocumentType));
    }
  }, [supportingDocumentType, documentNumberNCAWTextInputControlProps, getValues, setValue, unregister, formState]);

  const [documentNumberNCAWTextInputControlError, setDocumentNumberNCAWTextInputControlError] = useState<string>();

  /**
   * Subscribe to formState and document number input props changes
   * 1. Check for field error and set document number input error accordingly
   */
  useEffect(() => {
    const newError = getFieldError(
      get(errors, documentNumberNCAWTextInputControlProps?.name || ''),
      documentNumberNCAWTextInputControlProps?.errorMessages,
    );
    if (newError !== documentNumberNCAWTextInputControlError) {
      setDocumentNumberNCAWTextInputControlError(newError);
    }
  }, [errors, documentNumberNCAWTextInputControlError, documentNumberNCAWTextInputControlProps, formState]);

  const [availableOptions, setAvailableOptions] = useState<DocumentType[]>([]);

  /**
   * Subscribe to supporting document type changes
   * 1. Set available document type options accordingly
   */
  useEffect(() => {
    setAvailableOptions(getAvailableDocumentTypes(citizenshipStatus, supportingDocumentType));
  }, [citizenshipStatus, supportingDocumentType]);

  const [hasSetDefaultValueDocType, setHasSetDefaultValueDocType] = useState<boolean>(false);

  /**
   * Subscribe to available document type options changes
   * 1. If not yet set default document type value (on load), then set when available options is loaded
   * 2. If already set default document type value previously (on load), then do nothing
   */
  useEffect(() => {
    if (availableOptions.length > 0 && !hasSetDefaultValueDocType) {
      setValue(documentTypeFieldName, get(data, documentTypeFieldName));
      setHasSetDefaultValueDocType(true);
    }
  }, [availableOptions, hasSetDefaultValueDocType, data, setValue]);

  const isCountrySelectEnabled = supportingDocumentType === SupportingDocumentType.FOREIGN_PASSPORT;
  const isNCAW = citizenshipStatus === CitizenshipStatus.NONCITIZEN_AUTHORIZED_TO_WORK;

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {watch() && (
        <StyledForm
          className={CLASS_NAMES.form}
          onSubmit={handleFormSubmit}
          variant="large"
          aria-live={isNCAW ? 'polite' : undefined}
        >
          {isNCAW && (
            <SelfCheckFormDocumentSelectionNCAWSection
              className={CLASS_NAMES.ncawSection}
              data={data}
              documentNumberInputProps={documentNumberNCAWTextInputControlProps}
              documentNumberInputDisabled={isUndefined(supportingDocumentType)}
              countrySelectDisabled={!isCountrySelectEnabled}
              errors={errors}
              setValue={setValue}
              register={register}
            />
          )}
          {isNCAW && <h2 className={CLASS_NAMES.subHeader}>{NCAW_SUB_HEADER_TEXT}</h2>}
          <DropdownControl
            id={CLASS_NAMES.documentTypeDropdownControl}
            name={documentTypeFieldName}
            label={fieldDefinitions[documentTypeFieldName].label}
            disabled={isEmpty(availableOptions)}
            errorId={documentTypeErrorMessage ? CLASS_NAMES.documentTypeDropdownControlError : undefined}
            errorMessage={documentTypeErrorMessage}
            defaultValue={get(data, documentTypeFieldName)}
            required
            ref={register({
              required: true,
              ...get(fieldDefinitions, [documentTypeFieldName, 'validation'], {}),
            })}
          >
            <option value="">- Select -</option>
            {availableOptions.map((documentType) => (
              <option value={documentType} key={documentType}>
                {DOCUMENT_TYPE_OPTIONS_LABELS_MAP[documentType]}
              </option>
            ))}
          </DropdownControl>
          {citizenshipStatus === CitizenshipStatus.PERMANENT_RESIDENT && (
            <TextInputControl
              id={CLASS_NAMES.uscisANumberTextInputControl}
              name={USCISANumberFieldName}
              label={fieldDefinitions[USCISANumberFieldName].label}
              hint={!uscisANumberErrorMessage ? fieldDefinitions[USCISANumberFieldName].hint : undefined}
              errorId={uscisANumberErrorMessage ? CLASS_NAMES.uscisANumberTextInputControlError : undefined}
              errorMessage={uscisANumberErrorMessage}
              defaultValue={get(data, USCISANumberFieldName)}
              required
              ref={register({
                required: true,
                ...get(fieldDefinitions[USCISANumberFieldName], 'validation', {}),
              })}
            />
          )}
        </StyledForm>
      )}
      <ActionFooter
        className={CLASS_NAMES.actionFooter}
        onCancel={onCancel}
        cancelButtonText="Back"
        onSubmit={handleFormSubmit}
        submitButtonText="Next"
      />
      <div className={CLASS_NAMES.helpSection}>
        <Accordion bordered>{documentSelectionHelpContent[citizenshipStatus]}</Accordion>
      </div>
    </div>
  );
};

export default SelfCheckFormDocumentSelectionMarkup;
