import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { SelfCheckCaseDetailVariant } from '../../../constants';
import SelfCheckCaseDetailFNCActionSection from './variants/SelfCheckCaseDetailFNCActionSection';
import SelfCheckCaseDetailPendingRefActionSection from './variants/SelfCheckCaseDetailPendingRefActionSection';
import SelfCheckCaseDetailReturnHomeActionSection from './variants/SelfCheckCaseDetailReturnHomeActionSection';
import SelfCheckCaseDetailUnconfirmedActionSection from './variants/SelfCheckCaseDetailUnconfirmedActionSection';

export interface SelfCheckCaseDetailActionSectionProps {
  className?: ClassValue;
  caseNumber: string;
  selfCheckCaseDetailVariant: SelfCheckCaseDetailVariant;
}

const baseClassName = 'case-detail-action-section--self-check';
const CLASS_NAMES = {
  base: baseClassName,
};

const SelfCheckCaseDetailActionSection = ({
  className = undefined,
  caseNumber,
  selfCheckCaseDetailVariant,
}: SelfCheckCaseDetailActionSectionProps): JSX.Element => (
  <div className={classNames(CLASS_NAMES.base, className)}>
    {(selfCheckCaseDetailVariant === SelfCheckCaseDetailVariant.REFERRED ||
      selfCheckCaseDetailVariant === SelfCheckCaseDetailVariant.EMPLOYMENT_AUTHORIZED) && (
      <SelfCheckCaseDetailReturnHomeActionSection />
    )}
    {selfCheckCaseDetailVariant === SelfCheckCaseDetailVariant.FNC && (
      <SelfCheckCaseDetailFNCActionSection caseNumber={caseNumber} />
    )}
    {selfCheckCaseDetailVariant === SelfCheckCaseDetailVariant.UNCONFIRMED_DATA && (
      <SelfCheckCaseDetailUnconfirmedActionSection caseNumber={caseNumber} />
    )}
    {selfCheckCaseDetailVariant === SelfCheckCaseDetailVariant.PENDING_REFERRAL && (
      <SelfCheckCaseDetailPendingRefActionSection caseNumber={caseNumber} />
    )}
  </div>
);

export default SelfCheckCaseDetailActionSection;
