import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import type { ClassValue } from 'classnames/types';
import toArray from 'lodash/toArray';
import omit from 'lodash/omit';
import { Form } from 'uswds-react';
import paths from '../../../../routing/paths';
import { SelfLockChallengeQuestion } from '../../../../services/SelfLockApi';
import ActionFooter from '../../../ActionFooter/ActionFooter';
import SelfLockLockFormSection from './SelfLockLockFormSection';
import { getAvaibleQuestionOptions, SelfLockLockFormData } from './selfLockLockFormUtil';

export interface SelfLockLockFormProps {
  className?: ClassValue;
  onSubmit: SubmitHandler<SelfLockChallengeQuestion[]>;
}

const baseClassName = 'self-lock-lock-form';
const CLASS_NAMES = {
  base: baseClassName,
  divider: classNames(`${baseClassName}__divider`, 'border-top-1px', 'border-base-lighter', 'margin-top-3'),
  formSection: `${baseClassName}__form-section`,
  actionSection: classNames(`${baseClassName}__action-section`, 'margin-top-3'),
};

const SelfLockLockForm = ({ className = undefined, onSubmit }: SelfLockLockFormProps): JSX.Element => {
  const { register, errors, handleSubmit, getValues, watch } = useForm<SelfLockLockFormData>({
    mode: 'all',
  });

  const handleFormSubmit = handleSubmit((data) => {
    onSubmit(toArray(data));
  });

  const formData = getValues();
  const watchQuestionSelects = watch(['[0][question]', '[1][question]', '[2][question]']);

  return (
    <>
      <Form
        className={classNames(CLASS_NAMES.base, className)}
        autoComplete="off"
        onSubmit={handleFormSubmit}
        variant="large"
      >
        {watchQuestionSelects && (
          <SelfLockLockFormSection
            className={CLASS_NAMES.formSection}
            index={0}
            register={register}
            errors={errors}
            availableQuestionOptions={getAvaibleQuestionOptions(omit(formData, 0))}
          />
        )}
        <div className={CLASS_NAMES.divider} />
        {watchQuestionSelects && (
          <SelfLockLockFormSection
            className={classNames(CLASS_NAMES.formSection, 'margin-top-4')}
            index={1}
            register={register}
            errors={errors}
            availableQuestionOptions={getAvaibleQuestionOptions(omit(formData, 1))}
          />
        )}
        <div className={CLASS_NAMES.divider} />
        {watchQuestionSelects && (
          <SelfLockLockFormSection
            className={classNames(CLASS_NAMES.formSection, 'margin-top-4')}
            index={2}
            register={register}
            errors={errors}
            availableQuestionOptions={getAvaibleQuestionOptions(omit(formData, 2))}
          />
        )}
      </Form>
      <ActionFooter
        className={CLASS_NAMES.actionSection}
        cancelButton={
          <Link className="usa-button usa-button--outline ver-button" to={paths.selfLock}>
            Cancel
          </Link>
        }
        submitButtonText="Lock My SSN"
        onSubmit={handleFormSubmit}
      />
    </>
  );
};

export default SelfLockLockForm;
