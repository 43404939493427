import React from 'react';
import { Accordion } from 'uswds-react';
import visaImg from '../../../assets/img/documents/help/visa/visa.jpg';
import ImageWrapper from '../../ImageWrapper';
import HelpSectionContent from '../HelpSectionContent';

const baseClassName = 'visa-help-section';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

const VisaHelpSection = (): JSX.Element => (
  <>
    <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
      Help with Visa Number
    </Accordion.Heading>
    <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
      <p>
        The Visa Foil Number, also referred to as the Visa Number, is a RED, 8-digit number printed on the bottom right
        of the visa. An employee may have several U.S. visas in his or her passport. Use the most recent visa, evidenced
        by the issue date printed in the center of the visa. NOTE: If the employee is a lawful permanent resident, you
        do not need to enter his or her Visa Foil Number.
      </p>
      <ImageWrapper images={[{ src: visaImg, alt: 'Example of an immigrant visa' }]} />
    </HelpSectionContent>
  </>
);

export default VisaHelpSection;
