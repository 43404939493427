import React from 'react';
import { Accordion } from 'uswds-react';
import HelpSectionContent from '../HelpSectionContent';

const baseClassName = 'confirm-government-records-help-section';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

const ConfirmGovernmentRecordsHelpSection = (): JSX.Element => (
  <>
    <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
      Confirm your government records
    </Accordion.Heading>
    <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
      <p>
        Even though an identity assurance quiz could not be generated, you may still be authorized to work in the United
        States. While we are unable to let you confirm your work eligibility through myE-Verify, alternative options are
        available and explained below. These methods may be used to determine whether government records relating to
        your employment eligibility are accurate.
      </p>
      <p>
        Contacting the numbers below will not provide you with any information relating to the identity proofing process
        or why a quiz was not generated. For that information, please see the guidance above.
      </p>
      <p>
        <span className="text-bold">Wait until an employer checks your employment eligibility through E‑Verify: </span>
        Some companies use the E‑Verify Program to check the work eligibility of their new hires. If there is an issue
        with your records, your employer will inform you of the next steps at that time. In most instances, DHS and SSA
        records are accurate; E‑Verify finds about 97% of the work force to be employment authorized.
      </p>
      <p>
        <span className="text-bold">Contact the Social Security Administration:</span> If you would like to check your
        records with the Social Security Administration, please contact their toll-free number at (800) 772-1213 or call
        or visit your local Social Security office. Social Security can handle most inquiries over the telephone.
      </p>
      <p>
        <span className="text-bold">Contact the USCIS National Customer Service Center (NCSC):</span> Call the NCSC to
        check the accuracy of your immigration records and to determine next steps with an immigration specialist.
        Noncitizens may contact the NCSC at (800) 375-5283, TTY (800) 767-1833.
      </p>
    </HelpSectionContent>
  </>
);

export default ConfirmGovernmentRecordsHelpSection;
