import React, { ComponentPropsWithoutRef, useState } from 'react';
import classNames from 'classnames';
import { Button } from '@ver-uds/react';
import UserAgreementModal from '../../UseAgreementModal/UserAgreementModal';

export interface SignInButtonProps {
  variant?: ComponentPropsWithoutRef<typeof Button>['variant'];
}

const baseClassName = 'sign-in-button';
const CLASS_NAMES = {
  base: classNames('usa-button', baseClassName, 'height-5'),
};

const SignInButton = ({ variant = 'primary' }: SignInButtonProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = (): void => {
    setIsModalOpen(true);
  };

  const handleModalClose = (): void => {
    setIsModalOpen(false);
  };

  return (
    <>
      <UserAgreementModal isOpen={isModalOpen} handleClose={handleModalClose} />
      <Button as="button" onClick={handleButtonClick} className={CLASS_NAMES.base} variant={variant}>
        Sign In
      </Button>
    </>
  );
};

export default SignInButton;
