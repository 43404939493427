import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { Accordion } from 'uswds-react';
import { Helmet } from 'react-helmet-async';
import {
  ForeignPassportFormData,
  SelfCheckFormDocumentDetailsFormData,
  USPassportFormData,
  I551FormData,
  I766FormData,
  I94FormData,
  SSNFormData,
  UserInfoFormData,
  selfCheckPageTitles,
} from '../constants';
import USPassportDocumentMarkup from './DocumentForms/USPassportDocumentMarkup';
import I94DocumentMarkup from './DocumentForms/I94DocumentMarkup';
import I766DocumentMarkup from './DocumentForms/I766DocumentMarkup';
import I551DocumentMarkup from './DocumentForms/I551DocumentMarkup';
import SSNDocumentMarkup from './DocumentForms/SSNDocumentMarkup';
import { DocumentType } from '../../../../services/SelfCheckApi';
import ForeignPassportDocumentMarkup from './DocumentForms/ForeignPassportDocumentMarkup';
import { dobFieldName, ssnFieldName } from '../../../../services/UserApi';
import {
  I551HelpSection,
  I766HelpSection,
  SelfCheckSsnHelpSection,
  UsPassportHelpSection,
  VisaHelpSection,
} from '../../../HelpSections';
import I94HelpSection from '../../../HelpSections/I94HelpSection';

export interface SelfCheckFormDocumentDetailsMarkupProps {
  className?: ClassValue;
  data?: SelfCheckFormDocumentDetailsFormData;
  identity?: UserInfoFormData;
  selectedDocument: DocumentType;
  onCancel: () => void;
  onSubmit: SubmitHandler<SelfCheckFormDocumentDetailsFormData>;
}

const baseClassName = 'self-check-document-details';
const CLASS_NAMES = {
  base: baseClassName,
  helpSection: classNames(`${baseClassName}__help-section`, 'margin-top-6'),
  helpWithDocuments: `${baseClassName}__help-with-documents`,
};

const SelfCheckFormDocumentDetailsMarkup = ({
  className = undefined,
  data = undefined,
  selectedDocument,
  onCancel,
  onSubmit,
  identity = undefined,
}: SelfCheckFormDocumentDetailsMarkupProps): JSX.Element => {
  let pageTitle;
  let documentForm;
  let helpSection;
  switch (selectedDocument) {
    case DocumentType.US_PASSPORT:
      pageTitle = selfCheckPageTitles.CONFIRM_DOCUMENT_DETAILS_US_PASSPORT;
      documentForm = (
        <USPassportDocumentMarkup data={data as USPassportFormData} onSubmit={onSubmit} onCancel={onCancel} />
      );
      helpSection = <UsPassportHelpSection />;
      break;
    case DocumentType.FOREIGN_PASSPORT_I_551:
    case DocumentType.FOREIGN_PASSPORT_I_94:
      pageTitle = selfCheckPageTitles.CONFIRM_DOCUMENT_DETAILS_FOREIGN_PASSPORT;
      documentForm = (
        <ForeignPassportDocumentMarkup data={data as ForeignPassportFormData} onSubmit={onSubmit} onCancel={onCancel} />
      );
      helpSection = <VisaHelpSection />;
      break;
    case DocumentType.LIST_B_C_DOCUMENTS:
      pageTitle = selfCheckPageTitles.CONFIRM_DOCUMENT_DETAILS_SSN;
      documentForm = (
        <SSNDocumentMarkup
          data={data as SSNFormData}
          onSubmit={onSubmit}
          onCancel={onCancel}
          ssn={identity?.[ssnFieldName]}
        />
      );
      helpSection = <SelfCheckSsnHelpSection />;
      break;
    case DocumentType.I_94:
      pageTitle = selfCheckPageTitles.CONFIRM_DOCUMENT_DETAILS_FORM_I94;
      documentForm = <I94DocumentMarkup data={data as I94FormData} onSubmit={onSubmit} onCancel={onCancel} />;
      helpSection = <I94HelpSection />;
      break;
    case DocumentType.I_766:
      pageTitle = selfCheckPageTitles.CONFIRM_DOCUMENT_DETAILS_FORM_I766;
      documentForm = <I766DocumentMarkup data={data as I766FormData} onSubmit={onSubmit} onCancel={onCancel} />;
      helpSection = <I766HelpSection />;
      break;
    case DocumentType.I_551:
      pageTitle = selfCheckPageTitles.CONFIRM_DOCUMENT_DETAILS_PERMANENT_RESIDENT_CARD;
      documentForm = (
        <I551DocumentMarkup
          data={data as I551FormData}
          onSubmit={onSubmit}
          onCancel={onCancel}
          dob={identity?.[dobFieldName]}
        />
      );
      helpSection = <I551HelpSection />;
      break;
    default:
    // Do nothing
  }

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {documentForm}
      <div className={CLASS_NAMES.helpSection}>
        <Accordion bordered>{helpSection}</Accordion>
      </div>
    </div>
  );
};

export default SelfCheckFormDocumentDetailsMarkup;
